<!--Header-->
<nav id="navbar" class="navbar navbar-expand-lg navbar-default navbar-fixed navbar-light"
  (window:scroll)="windowScroll()">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo" href="index.html">
      <span class="dot"></span>Kiosk
    </a>
    <button type="button" class="navbar-toggler" [ngClass]="{'collapsed': isCollapsed}" (click)="toggle()"
      [attr.aria-expanded]="!isCollapsed" aria-controls="navbarCollapse" aria-label="Toggle navigation">
      <span></span>
      <span></span>
      <span></span>
    </button>


    <div class="navbar-collapse" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" id="navbarCollapse">
      <ul class="nav navbar-nav ms-auto navbar-center" id="mySidenav">
        <li class="nav-item" *ngFor="let nav of navigations; let i = index">
          <a href="javascript: void(0);" class="nav-link">{{nav}}</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!--End Header-->