<div class="auth-pages py-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6">
        <img src="assets/images/404.svg" alt="Lettstart Admin" class="img-fluid">
      </div>
      <div class="col-lg-12">
        <div class="text-center mt-5">
          <h3 class="font-weight-600 mb-3">We couldn’t connect the dots</h3>
          <p>This page was not found. <br> You may have mistyped the address or the page may have
            moved.</p>
          <div class="mt-30 text-center">
            <a class="btn btn-primary" routerLink="/">
              <i class="bx bxs-home align-middle me-1"></i>
              <span class="align-middle">Back to Home</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>