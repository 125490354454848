<div class="blog-content">
  <div class="blog-share">
    <h5 class="mb-2">Share <span class="d-xl-none">:</span></h5>
    <ul class="list-unstyled text-center ">
      <li><a href="javascript:void(0)"><i class="ri-facebook-fill" aria-hidden="true"></i></a></li>
      <li><a href="javascript:void(0)"><i class="ri-twitter-line" aria-hidden="true"></i></a></li>
      <li><a href="javascript:void(0)"><i class="ri-instagram-line" aria-hidden="true"></i></a></li>
      <li><a href="javascript:void(0)"><i class="ri-linkedin-line" aria-hidden="true"></i></a></li>
    </ul>
    <!--Share list end-->
  </div>
  <!--Share end-->
  <div class="content">
    <p class="mb-15">Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
      laborum.
      Lorem
      ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
      dolore
      magna
      aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
      aliqui.paragraphex ea
      commodo
      consequat. Duis aute irure dolor in reprehenderit in voluptate velit.</p>
    <p>Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
      laborum.
      Lorem
      ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
      dolore
      magna
      aliqua.</p>
    <blockquote class="blockquote mt-5 mb-30 text-center">
      <div class="quote-icon">
        <i class="ri-message-3-line"></i>
      </div>
      <p class="text-dark font-weight-medium mb-3">Very little is needed to make a happy life; it is
        all within yourself, in your way of thinking.</p>
      <h4 class="text-uppercase">LettstartDesign</h4>
    </blockquote>
    <p class="mb-15">Occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      Lorem
      ipsum
      dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
      dolore magna
      aliqua.
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
      consequat.
      Duis
      aute irure dolor in reprehenderit in voluptate.</p>
    <p class="mb-15">Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
      laborum.
      Lorem
      ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
      dolore
      magna
      aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui.</p>
    <p class="mb-15">Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
      laborum.
      Lorem
      ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
      dolore
      magna
      aliqua.</p>
    <p class="mb-15">Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
      laborum.
      Lorem
      ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
      dolore
      magna
      aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
      aliqui.paragraphex ea
      commodo
      consequat. Duis aute irure dolor in reprehenderit in voluptate velit.</p>
    <p class="mb-30">Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
      anim id
      est
      laborum.
      Lorem
      ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
      dolore
      magna
      aliqua.</p>
  </div>
  <!--Content end-->
  <div class="blog-footer">
    <div class="tags-group mb-5 pb-3">
      <a class="tag-btn" href="javascript:void(0);">Gutenews</a>
      <a class="tag-btn" href="javascript:void(0);">Lifestyle</a>
      <a class="tag-btn" href="javascript:void(0);">Fashion</a>
      <a class="tag-btn" href="javascript:void(0);">Technology</a>
      <a class="tag-btn" href="javascript:void(0);">Food</a>
    </div>
    <div class="blog-footer-author mb-5">
      <div class="author-avatar">
        <img src="assets/images/blogs/150.jpg" alt="Author avatar">
      </div>
      <div class="author-info">
        <h5 class="mb-3">Lena Mollein</h5>
        <p class="text-dark mb-15">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor
          incididunt ut labore
          et
          dolore magna aliqua. Quis ipsum suspendisse vel facilisis. </p>
        <div class="social-icons social-white justify-content-center">
          <ul class="list-unstyled">
            <li><a href="javascript:void(0)" data-hover=""><i class="ri-facebook-line"
                  aria-hidden="true"></i></a></li>
            <li><a href="javascript:void(0)" data-hover=""><i class="ri-twitter-line"
                  aria-hidden="true"></i></a></li>
            <li><a href="javascript:void(0)" data-hover=""><i class="ri-instagram-line"
                  aria-hidden="true"></i></a></li>
            <li><a href="javascript:void(0)" data-hover=""><i class="ri-linkedin-line"
                  aria-hidden="true"></i></a></li>
          </ul>
        </div>
        <!--Share list end-->
      </div>
      <!--author info end-->
    </div>
    <!--Blog footer author end-->
    <div class="blog-footer-related">
      <div class="related-item prev me-md-3">
        <div class="mb-2">
          <a href="javascript:void(0)" class="text-uppercase font-weight-semibold">
            <i class="ri-arrow-left-s-line align-middle"></i>Previous posts
          </a>
        </div>
        <div class="media align-items-center">
          <img src="assets/images/blogs/02.jpg" class="rounded me-3" alt="Relate news image">
          <div class="media-body">
            <a href="javascript:void(0)" class="font-weight-bold">How to optimize your Instagram
              profile for success</a>
          </div>
        </div>
      </div>
      <div class="related-item next ms-md-3 text-end">
        <div class="mb-2">
          <a href="javascript:void(0)" class="font-weight-semibold"> Next posts<i
              class="ri-arrow-right-s-line align-middle"></i></a>
        </div>
        <div class="media align-items-center">
          <div class="media-body text-end">
            <a href="javascript:void(0)" class="font-weight-bold">Research & development advisor
              success innovator</a>
          </div>
          <img src="assets/images/blogs/03.jpg" class="rounded ms-3" alt="Relate news image">
        </div>
      </div>
    </div>
    <hr class="my-5">
    <div class="blog-footer-comment">
      <h4>3 comment</h4>
      <div class="blog-footer-comment-detail mb-5">
        <div class="media mt-4">
          <img src="assets/images/thumb/thumb-100-3.jpg" alt="Author avatar" class="me-3 rounded-circle">
          <div class="media-body">
            <div class="comment-header">
              <h5 class="mb-2 mb-md-0">Brandon Kelley</h5>
              <ul class="list-inline text-uppercase font-size-15 font-weight-medium mb-0">
                <li class="list-inline-item me-3">
                  <i class="ri-timer-2-line text-primary align-middle"></i>
                  <span class="align-middle">May 15, 2020</span>
                </li>
                <li class="list-inline-item me-3">
                  <i class="ri-heart-2-fill text-primary align-middle"></i>
                  <span class="align-middle">12</span>
                </li>
                <li class="list-inline-item">
                  <i class="ri-share-line text-primary align-middle"></i>
                  <span class="align-middle">1</span>
                </li>
              </ul>
            </div>
            <p class="mb-30">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque
              ante
              sollicitudin.
              Cras
              purus odio, vestibulum in vulputate at, tempus viverra turpis.</p>
            <div class="media mt-4">
              <img src="assets/images/thumb/thumb-100-2.jpg" alt="Author avatar"
                class="me-3 rounded-circle">
              <div class="media-body">
                <div class="comment-header">
                  <h5 class="mb-2 mb-md-0">Brandon Kelley</h5>
                  <ul class="list-inline text-uppercase font-size-15 font-weight-medium mb-0">
                    <li class="list-inline-item me-3">
                      <i class="ri-timer-2-line text-primary align-middle"></i>
                      <span class="align-middle">May 16, 2020</span>
                    </li>
                    <li class="list-inline-item me-3">
                      <i class="ri-heart-2-fill text-primary align-middle"></i>
                      <span class="align-middle">14</span>
                    </li>
                    <li class="list-inline-item">
                      <i class="ri-share-line text-primary align-middle"></i>
                      <span class="align-middle">5</span>
                    </li>
                  </ul>
                </div>
                <p>
                  Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante
                  sollicitudin.
                  Cras purus odio, vestibulum in vulputate at, tempus viverra turpis.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="media mt-4">
          <img src="assets/images/thumb/thumb-100-4.jpg" alt="Author avatar" class="me-3 rounded-circle">
          <div class="media-body">
            <div class="comment-header">
              <h5 class="mb-2 mb-md-0">Brandon Kelley</h5>
              <ul class="list-inline text-uppercase font-size-15 font-weight-medium mb-0">
                <li class="list-inline-item me-3">
                  <i class="ri-timer-2-line text-primary align-middle"></i>
                  <span class="align-middle">May 17, 2020</span>
                </li>
                <li class="list-inline-item me-3">
                  <i class="ri-heart-2-fill text-primary align-middle"></i>
                  <span class="align-middle">20</span>
                </li>
                <li class="list-inline-item">
                  <i class="ri-share-line text-primary align-middle"></i>
                  <span class="align-middle">10</span>
                </li>
              </ul>
            </div>
            <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante
              sollicitudin.
              Cras
              purus odio, vestibulum in vulputate at, tempus viverra turpis.</p>
          </div>
        </div>
      </div>
      <hr class="my-5">
      <h4>Leave a comment</h4>
      <div class="blog-footer-comment-form mt-4">
        <form name="commentForm" [formGroup]="commentForm" (ngSubmit)="onSubmit('commentForm')" id="commentForm" novalidate>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group app-label mb-30"
                [ngClass]="{'invalid-field': submitted && form['name'].errors, 'is-valid': submitted && !form['name'].errors}">
                <input type="text" class="form-control" formControlName="name" id="name" floatingLabel />
                <label for="name">Name</label>
                <div *ngIf="submitted && form['name']?.errors" class="invalid-feedback">
                  <p>This field is required</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group app-label mb-30"
                [ngClass]="{'invalid-field': submitted && form['email'].errors, 'is-valid': submitted && !form['email'].errors}">
                <input type="email" class="form-control" formControlName="email" id="signupEmail"
                  floatingLabel />
                <label for="signupEmail">Email Address</label>
                <div *ngIf="submitted && form['email']?.errors?.required" class="invalid-feedback">
                  <p>This field is required</p>
                </div>
                <div *ngIf="submitted && form['email']?.errors?.email" class="invalid-feedback">
                  <p>Email must be a valid email address</p>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group app-label mb-30"
            [ngClass]="{'invalid-field': submitted && form['website'].errors, 'is-valid': submitted && !form['website'].errors}">
            <input type="text" class="form-control" formControlName="website" id="website"
              floatingLabel />
            <label for="website">Website</label>
            <div *ngIf="submitted && form['website']?.errors" class="invalid-feedback">
              <p>This field is required</p>
            </div>
          </div>
          <div class="form-group app-label mb-30"
            [ngClass]="{'invalid-field': submitted && form['message'].errors, 'is-valid': submitted && !form['message'].errors}">
            <textarea rows="3" class="form-control" formControlName="message" id="message"
              floatingLabel></textarea>
            <label for="message">Message</label>
            <div *ngIf="submitted && form['message']?.errors" class="invalid-feedback">
              <p>This field is required</p>
            </div>
          </div>
          <div class="form-group text-center">
            <button class="btn btn-primary" type="submit">
              <span *ngIf="loader"><i class="ri-loader-4-line spin h6 text-white"></i></span>
              <span *ngIf="!loader">Comment</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--Footer end-->
</div>