import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { ScrollspyDirective } from './scrollspy.directive';
import { AppLabelDirective } from './input.directive';
// import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { FooterComponent } from './footer/footer.component';
import { PageheaderComponent } from './pageheader/pageheader.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginModalComponent } from './loginModal/login.component';
import { SignupModalComponent } from './signupModal/signup.component';
import { PageBannerComponent } from './page-banner/page-banner.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    HeaderComponent,
    ScrollspyDirective,
    AppLabelDirective,
    FooterComponent,
    PageheaderComponent,
    LoginModalComponent, 
    SignupModalComponent, PageBannerComponent
  ],
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    NgxPageScrollModule,
    NgbModule,
    RouterModule
  ],
  exports: [
    HeaderComponent,
    ScrollspyDirective,
    AppLabelDirective,
    FooterComponent,
    PageheaderComponent,
    LoginModalComponent, 
    SignupModalComponent,
    PageBannerComponent
  ]
})
export class SharedModule { }
