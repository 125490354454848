import { IFaq } from "../interfaces/faqs.interface";

export const faqs: IFaq[] = [
  {
    head: "Pre and post launch mobile app marketing pitfalls to avoid "
  }, 
  {
    head: "Boostup your application traffic is just a step away"
  },
  {
    head: "How to update application new features"
  },
  {
    head: "Boostup your application traffic is just a step away"
  },
  {
    head: "Pre and post launch mobile app marketing pitfalls to avoid "
  },
  {
    head: "How to update application new features"
  }
]