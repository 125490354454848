<!-- TODO: mover css a clase -->
<div id="quicksight_chart" style="width:75vw; height:100vh;padding: 0px 2vw;max-width: 1920px;margin: 0 auto;">

    <div *ngIf="!dashboard_view" class="d-flex justify-content-center" style="height: 75vh;align-items: center;">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <iframe *ngIf="dashboard_view" style="width:100%" height="100%" [src]="dashboard_view_url">
    </iframe>

</div>