import { Component, OnInit } from '@angular/core';
import { blogs } from 'src/app/data/blogs.data';
import { faqs } from 'src/app/data/faqs.data';
import { features } from 'src/app/data/features.data';
import { IBlog } from 'src/app/interfaces/blogs.interface';
import { IFaq } from 'src/app/interfaces/faqs.interface';
import { IFeature } from 'src/app/interfaces/features.interface';
import { IShape } from 'src/app/interfaces/pricings.interface';
import { NavlinkService } from 'src/app/shared/navlink.service';

@Component({
  selector: 'app-home8',
  templateUrl: './home8.component.html',
  styleUrls: ['./home8.component.scss']
})
export class Home8Component implements OnInit {
  navigations: Array<string> = ['home', 'features', 'pricing', 'faq', 'blog'];
  shapeImg: IShape = {
    img: "assets/images/shape-2.png",
    class: "shape-square square-dot-anim",
    top: true
  }
  featuresList: IFeature[] = features;
  blogList: IBlog[] = blogs;
  faqList: IFaq[] = faqs;
  constructor(private navlinkService: NavlinkService) { }

  ngOnInit(): void {
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.navlinkService.setCurrentSectionId(sectionId);
  }
}
