import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { faqs } from 'src/app/data/faqs.data';
import { features } from 'src/app/data/features.data';
import { prices } from 'src/app/data/pricings.data';
import { testi } from 'src/app/data/testimonials.data';
import { IFaq } from 'src/app/interfaces/faqs.interface';
import { IFeature } from 'src/app/interfaces/features.interface';
import { IPrice, IShape } from 'src/app/interfaces/pricings.interface';
import { Itestimonial } from 'src/app/interfaces/testimonials.interface';
import { NavlinkService } from 'src/app/shared/navlink.service';

interface ActiveAccordianI {
  nextState: boolean;
  panelId: string
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  navigations: Array<string> = ['home', 'features', 'pricing', 'faq', 'client'];
  activeAccordian: ActiveAccordianI = {
    nextState: true,
    panelId: 'ba0'
  };
  testimonialOptions: OwlOptions = {
    items: 1,
    loop: false,
    margin: 0,
    nav: true,
    dots: false,
    navText:['<i class="ri-arrow-left-line"></i>', '<i class="ri-arrow-right-line"></i>'],
    responsive: {
      0: {
        items: 1
      }
    }
  }
  featuresList: IFeature[] = features;
  priceList: IPrice[] = prices;
  faqList: IFaq[] = faqs;
  testimonialList: Itestimonial[] = testi;
  shapeImg: IShape = {
    img: "assets/images/shape-2.png",
    class: "shape-square square-dot-anim",
    top: true
  }
  constructor(private navlinkService: NavlinkService, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.navlinkService.setCurrentSectionId(sectionId);
  }

  changeTitle(event: NgbPanelChangeEvent) {
    this.activeAccordian = {
      nextState: event.nextState,
      panelId: event.panelId
    };
  }

  openLoginModal(content) {
    this.modalService.open(content, { centered: true, windowClass: 'auth-modal', backdrop: 'static' });
  }
}
