<app-header [navigations]="navigations" [classes]="'navbar-light'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
  <!--Home Section-->
  <section class="section-lg home-alt bg-img" id="home" data-scroll-index="home">
    <div class="bg-overlay-gradient bg-overlay opacity-9"></div>
    <div class="container text-over-bg">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <p class="text-white-50 mb-15" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">HELLO! THIS IS KISOK</p>
          <h1 class="font-weight-medium mb-30" data-aos="fade-up" data-aos-duration="400" data-aos-delay="600">We love to create creative beautiful templates</h1>
          <a href="https://www.youtube.com/watch?v=Kl5B6MBAntI" class="video-btn-circle circle-anim-2 bg-white text-default" data-aos="fade" data-aos-duration="400" data-aos-delay="800"><i class="ri-play-line font-48"></i></a>
        </div>
      </div>
    </div>
  </section>

  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--End Home Section-->

  <!-- Hero Banner -->
  <div class="home-one-slider text-center text-over-bg" data-aos="fade" data-aos-offset="0" data-aos-duration="400" data-aos-delay="1000">
    <div class="container">
      <img src="assets/images/home/hero-7.jpg" alt=""
        class="img-fluid border-radius-2x"/>
    </div>
  </div><!-- End Hero Banner -->

  <!--Fact Section-->
  <section class="text-over-bg" data-scroll-index="home" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="facts-box text-center">
            <div class="row">
              <div class="col-md-3 col-6 fact">
                <h3 class="mt-15 mb-2">100K+</h3>
                <h6 class="text-muted mb-15">Total Downloads</h6>
              </div>

              <div class="col-md-3 col-6 fact">
                <h3 class="mt-15 mb-2">10K+</h3>
                <h6 class="text-muted mb-15">Positive Reviews</h6>
              </div>

              <div class="col-md-3 col-6 fact">
                <h3 class="mt-15 mb-2">50k+</h3>
                <h6 class="text-muted mb-15">Daily Visitors</h6>
              </div>

              <div class="col-md-3 col-6 fact">
                <h3 class="mt-15 mb-2">15K+</h3>
                <h6 class="text-muted mb-15">Pro User</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section><!--End Fact Section-->

  <!--Features Section-->
  <section class="section more-features" id="features" data-scroll-index="features">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 mb-30" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="mobile-mock text-center text-lg-start">
            <img src="assets/images/mobile-mock.png" class="img-fluid" alt="mobile mock">
          </div>
        </div>
        <div class="col-lg-6 mb-30 ps-lg-5" data-aos="fade-left" data-aos-duration="400" data-aos-delay="400">
          <div class="section-title mb-40">
            <p class="sub">Our App Feature Lists</p>
            <h2 class="mt-0">Powerful App for <br>Your Mobile</h2>
          </div>
          <p class="mb-15">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla
            pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <ul class="list-unstyled highlight-list mb-0">
            <li>Aenean quam ornare. Curabitur blandit.</li>
            <li>Aenean quam ornare. Curabitur blandit.</li>
            <li>Aenean quam ornare. Curabitur blandit.</li>
            <li>Aenean quam ornare. Curabitur blandit.</li>
          </ul>
        </div>
      </div>

    </div>
  </section>
  <!--End Features Section-->

  <!--Extra Features Section-->
  <section class="section more-features bg-light" data-scroll-index="features">
    <div class="shape-bg-top-left" style="background-image: url(assets/images/shape-3.png)"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 mb-30 pe-lg-5" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="section-title mb-40">
            <p class="sub">Our App Feature Lists</p>
            <h2 class="mt-0">User Friendly App <br /> and Interface</h2>
          </div>
          <p class="mb-30">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla pariatur. Lorem
            Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <div class="accordion highlight-accordion" id="accordion1" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
            <ngb-accordion [closeOthers]="true" (panelChange)="highlightChangeTitle($event)" class="accordion" activeIds="ha1">
              <ngb-panel
                [cardClass]="(activeHighlightAccordian.panelId === 'ba1' && activeHighlightAccordian.nextState) ? 'active' : ''"
                id="ha1">
                <ng-template ngbPanelTitle>
                  <h6>
                    24/7 Support
                  </h6>
                </ng-template>
                <ng-template ngbPanelContent>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla pariatur.
                  Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.
                </ng-template>
              </ngb-panel>
              <ngb-panel
                [cardClass]="(activeHighlightAccordian.panelId === 'ba1' && activeHighlightAccordian.nextState) ? 'active' : ''"
                id="ha2">
                <ng-template ngbPanelTitle>
                  <h6>
                    Professional Design
                  </h6>
                </ng-template>
                <ng-template ngbPanelContent>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla pariatur.
                  Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
        <div class="col-lg-6 mb-30" data-aos="fade-left" data-aos-duration="400" data-aos-delay="400">
          <div class="mobile-mock text-center text-lg-end text-end">
            <img src="assets/images/mobile-mock-3.png" class="img-fluid" alt="mobile mock">
          </div>
        </div>
      </div>

    </div>
  </section>
  <!--End Extra Features Section-->
  
 <!--Extra Feature Section-->
 <app-powerfulfeatures className="section"></app-powerfulfeatures>
 <!--End Extra Feature Section-->

  <!--Pricing Section-->
  <app-pricing [className]="'pb-70 position-relative'" [shapeObj]="shapeImg"></app-pricing>
	<!--End Pricing Section-->

  <!--Faq Section-->
  <section class="section bg-light position-relative" id="faq" data-scroll-index="faq">
    <div class="shape-bg-bottom-right" style="background-image: url(assets/images/shape-4.png)"></div>
    <div class="container text-over-bg">
			<div class="section-title text-center" data-aos="fade" data-aos-duration="400" data-aos-delay="400">
				<p class="sub">Frequently Asked Questions</p>
				<h2>Have Any Question?</h2>
			</div> <!-- end section -->
			<div class="accordion" id="accordion1" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
				<ngb-accordion [closeOthers]="true" (panelChange)="changeTitle($event)" class="accordion" activeIds="ba0">
					<ngb-panel *ngFor="let item of faqList;let i = index" 
						[cardClass]="(activeAccordian.panelId === 'ba'+i && activeAccordian.nextState) ? 'active' : ''"
						[id]="'ba'+i">
						<ng-template ngbPanelTitle>
							<h6>
								{{ item?.head }}
							</h6>
						</ng-template>
						<ng-template ngbPanelContent>
							Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
							wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
							eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
							assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
							sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
							farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
							labore sustainable VHS.
						</ng-template>
					</ngb-panel>
				</ngb-accordion>
			</div>
		</div>
  </section>
  <!--End Faq Section-->
</div>
<app-footer></app-footer>

<ng-template #accordionContent>
  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
  wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
  eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
  assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
  sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
  farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
  labore sustainable VHS.
</ng-template>