import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit {

    public dashboard_view = false;
    public dashboard_view_url: SafeResourceUrl;
    public API_GATEWAY_URL = 'https://cyu7efdvmg.execute-api.us-west-2.amazonaws.com/test/embed-sample'

  constructor(private sanitizer: DomSanitizer, ) { }

  async ngOnInit(): Promise<void> {

    console.log('Iniciando componente Insights...')

    try {

        let token = sessionStorage.getItem('AccessToken');

        const response = await fetch(this.API_GATEWAY_URL + '?mode=getUrl&urlType=insights' + `&openIdToken=${token}&timestamp=${new Date().getTime()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'text/plain',
            },
        });

        if (!response.ok) {
            throw new Error('Error on making API gateway call to retrieve dashboard embed url.');
        }

        const result = await response.json();
        this.dashboard_view_url = this.sanitizer.bypassSecurityTrustResourceUrl(result.DashboardEmbedUrl);
        this.dashboard_view = true;

    } catch (error) {
        console.log(error.message);
    }

  }

}
