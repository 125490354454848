import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-blogsdetailsrightsidebar',
  templateUrl: './blogsdetailsrightsidebar.component.html',
  styleUrls: ['./blogsdetailsrightsidebar.component.scss']
})
export class BlogsdetailsrightsidebarComponent implements OnInit {
  navigations: Array<string> = ['home', 'features', 'pricing', 'blog', 'faq'];
  constructor() { }

  ngOnInit(): void {
  }
}
