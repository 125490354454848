import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MustMatch } from './mustMatch';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  signupForm: UntypedFormGroup;
  loader: boolean = false;
  submitted: boolean = false;
  customOptions: OwlOptions = {
    loop: true,
    margin: 0,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  }
  constructor(private formBuilder: UntypedFormBuilder, private router: Router) { }

  /**
  * Get Form controls
  */
  get form() { return this.signupForm.controls; }

  ngOnInit(): void {
    //Initialize form
    this.signupForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmpwd: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmpwd'),
    });
  }

  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.signupForm.invalid) {
      return;
    }

    this.loader = true;
    const obj = {
      name: this.form.name.value,
      email: this.form.email.value,
      password: this.form.password.value,
      confirmPassword: this.form.confirmpwd.value
    }
    alert(JSON.stringify(obj));
    console.log("form values ===>", obj);
    setTimeout(() => {
      this.loader = false
      this.router.navigate(['/confirm']);
    }, 1000);
  }
}
