<div class="modal-header">
  <h4 class="mb-2">Login into account</h4>
  <p>Use your credentials to access your account.</p>
  <button type="button" class="btn-close shadow-none" (click)="closeModal()">
    <i class="ri-close-line font-18"></i>
  </button>
</div>
<div class="modal-body">
  <form class="mb-30" id="login-form" name="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="form-group app-label mb-15"
      [ngClass]="{'invalid-field': submitted && form.email.errors, 'is-valid': submitted && !form.email.errors}">
      <input type="email" class="form-control" formControlName="email" id="loginEmail" floatingLabel />
      <label for="loginEmail">Email Address</label>
      <div *ngIf="submitted && form.email?.errors?.required" class="invalid-feedback">
        <p>This field is required</p>
      </div>
      <div *ngIf="submitted && form.email?.errors?.email" class="invalid-feedback">
        <p>Email must be a valid email address</p>
      </div>
    </div>
    <div class="form-group app-label mb-15"
      [ngClass]="{'invalid-field': submitted && form.password.errors, 'is-valid': submitted && !form.password.errors}">
      <input type="password" class="form-control" formControlName="password" id="password" floatingLabel />
      <label for="password">Password</label>
      <div *ngIf="submitted && form.password?.errors" class="invalid-feedback">
        <p>This field is required</p>
      </div>
    </div>
    <div class="d-grid">
      <button class="btn btn-primary" type="submit">
        <span *ngIf="loader"><i class="ri-loader-4-line spin h6 text-white"></i></span>
        <span *ngIf="!loader">Log In</span>
      </button>
    </div>
  </form>
  <div class="text-center">
    <p class="mb-1">
      <a (click)="closeModal()" routerLink="/recover" class="link">Forgot your password?</a>
    </p>
    <p class="mb-30">
      Don't have an account?
      <a href="javascript:void(0)" class="link" (click)="navigateSignup(componentRef)">Create account</a>
    </p>
    <div class="position-relative mb-30">
      <span class="login-border"></span>
      <p class="social-login">OR</p>
    </div>
    <div class="social-icons justify-content-center">
      <button class="social-icon btn-twitter text-white" type="button">
        <i class="ri-twitter-line"></i>
      </button>
      <button class="social-icon btn-facebook text-white" type="button">
        <i class="ri-facebook-line"></i>
      </button>
      <button class="social-icon btn-google text-white" type="button">
        <i class="ri-google-line"></i>
      </button>
    </div>
  </div>
</div>