import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  navigations: Array<string> = ['home', 'features', 'pricing', 'blog', 'faq'];
  constructor() { }

  ngOnInit(): void {
  }

}
