<app-pageheader [navigations]="navigations"></app-pageheader>
<!--Home Section-->
<app-page-banner title="Contact Us"></app-page-banner>
<!--End Home Section-->
<section class="pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 pe-lg-5 mb-30">
        <div class="section-title">
          <p class="sub">Get In Touch</p>
          <h2>Have Question? Write a Message</h2>
        </div>
        <ngb-alert *ngIf="showSuccessMsg" [type]="alertType" [innerHTML]="successMsg"></ngb-alert>
        <!-- <div id="message" class="alert alert-{{errclass}}" *ngIf="showSuccessMsg" [innerHTML]="successMsg"></div> -->

        <form name="contactForm" [formGroup]="contactForm" (ngSubmit)="onSubmit('contactForm')" id="contactForm" novalidate>
          <div class="form-group app-label mb-30"
            [ngClass]="{'invalid-field': submitted && form.name.errors, 'is-valid': submitted && !form.name.errors}">
            <input type="text" class="form-control" formControlName="name" id="name" floatingLabel />
            <label for="name">Name</label>
            <div *ngIf="submitted && form.name?.errors" class="invalid-feedback">
              <p>This field is required</p>
            </div>
          </div>
          <div class="form-group app-label mb-30"
            [ngClass]="{'invalid-field': submitted && form.email.errors, 'is-valid': submitted && !form.email.errors}">
            <input type="email" class="form-control" formControlName="email" id="signupEmail" floatingLabel />
            <label for="signupEmail">Email Address</label>
            <div *ngIf="submitted && form.email?.errors?.required" class="invalid-feedback">
              <p>This field is required</p>
            </div>
            <div *ngIf="submitted && form.email?.errors?.email" class="invalid-feedback">
              <p>Email must be a valid email address</p>
            </div>
          </div>
          <div class="form-group app-label mb-30"
            [ngClass]="{'invalid-field': submitted && form.phone.errors, 'is-valid': submitted && !form.phone.errors}">
            <input type="text" class="form-control" formControlName="phone" id="phone" floatingLabel />
            <label for="phone">Phone</label>
            <div *ngIf="submitted && form.phone?.errors?.required" class="invalid-feedback">
              <p>This field is required</p>
            </div>
            <div *ngIf="submitted && form.phone?.errors?.pattern" class="invalid-feedback">
              <p>Please enter number value</p>
            </div>
          </div>
          <div class="form-group app-label mb-30"
            [ngClass]="{'invalid-field': submitted && form.message.errors, 'is-valid': submitted && !form.message.errors}">
            <textarea rows="3" class="form-control" formControlName="message" id="message" floatingLabel></textarea>
            <label for="message">Message</label>
            <div *ngIf="submitted && form.message?.errors" class="invalid-feedback">
              <p>This field is required</p>
            </div>
          </div>
          <div class="d-grid">
            <button class="btn btn-primary" type="submit">
              <span *ngIf="loader"><i class="ri-loader-4-line spin h6 text-white"></i></span>
              <span *ngIf="!loader">Submit Now</span>
            </button>
          </div>
        </form>
      </div>
      <div class="col-lg-5 ps-lg-5 mb-30">
        <div class="contact-info">
          <div class="address-info mb-30 rounded">
            <i class="ri-map-pin-line bg-soft-primary text-primary"></i>
            <address>
              <strong>Address</strong> 199E Harrow Way Watford<br>New York, USA
            </address>
          </div>
          <div class="address-info mb-30 rounded">
            <i class="ri-phone-line bg-soft-info text-info"></i>
            <div class="text">
              <strong>Phone Number</strong>
              <p class="mb-15">Assistance hours: Monday – Friday, 9 am to 5 pm</p>
              <p><a href="tel:0000 000 000" class="font-18 link">(+91) 0000 000 000</a></p>
            </div>
          </div>
          <div class="address-info mb-30 rounded">
            <i class="ri-message-line bg-soft-warning text-warning"></i>
            <div class="text">
              <strong>Email Address</strong>
              <p class="mb-15">Our support team will get back to in 24-h during standard business hours.</p>
              <p>
                <a href="mailto:example@example.com" class="font-18 link">support@lettstartdesign.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-100 mb-30">
    <div class="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224610.9688095933!2d77.17898630135954!3d28.402765666701594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdc15f5a424b1%3A0xe4f50576c850e0f2!2sFaridabad%2C+Haryana!5e0!3m2!1sen!2sin!4v1511102666636"
        style="border:0" allowfullscreen=""></iframe>
    </div>
  </div>
</section>
<app-footer></app-footer>