<app-header [navigations]="navigations" [classes]="'navbar-light'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
  <!--Home Section-->
  <section class="home home-1 bg-dark" id="home" data-scroll-index="home">
    <div class="container">
      <div class="row  justify-content-center">
        <div class="col-lg-10 col-xl-8 text-center">
          <p class="text-white-50 sup" data-aos="fade-up" data-aos-duration="400" data-aos-delay="500">Creative
            Landing Page Template</p>
          <h1 class="text-gred text-gred-2 mb-30" data-aos="fade-up" data-aos-duration="400" data-aos-delay="600">We
            love to create creative beautiful templates</h1>
          <div data-aos="fade-up" data-aos-duration="400" data-aos-delay="700">
            <a href="javascript:void(0)" class="btn btn-primary me-15">Get Started</a>
            <a href="javascript:void(0)" class="btn btn-outline-white">Learn More</a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--End Home Section-->

  <!--Fact Section-->
  <section data-scroll-index="home" data-aos="fade-up" data-aos-duration="400" data-aos-offset="0" data-aos-delay="800">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="facts-box text-center">
            <div class="row">
              <div class="col-md-3 col-6 fact">
                <h3 class="mt-15 mb-2">100K+</h3>
                <h6 class="text-muted mb-15">Total Downloads</h6>
              </div>

              <div class="col-md-3 col-6 fact">
                <h3 class="mt-15 mb-2">10K+</h3>
                <h6 class="text-muted mb-15">Positive Reviews</h6>
              </div>

              <div class="col-md-3 col-6 fact">
                <h3 class="mt-15 mb-2">50k+</h3>
                <h6 class="text-muted mb-15">Daily Visitors</h6>
              </div>

              <div class="col-md-3 col-6 fact">
                <h3 class="mt-15 mb-2">15K+</h3>
                <h6 class="text-muted mb-15">Pro User</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--Extra Feature Section-->
  <section class="section more-features" id="features" data-scroll-index="features">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 mb-30" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="mobile-mock text-center text-lg-start">
            <img src="assets/images/mobile-mock.png" class="img-fluid" alt="mobile mock">
          </div>
        </div>
        <div class="col-lg-6 mb-30 ps-lg-5" data-aos="fade-left" data-aos-duration="400" data-aos-delay="600">
          <div class="section-title mb-40">
            <p class="sub">Our App Feature Lists</p>
            <h2 class="mt-0">Powerful App for <br>Your Mobile</h2>
          </div>
          <div class="sub-feature mb-30">
            <div class="media flex-grow-1 mb-3 mb-sm-0">
              <div class="remix-icon">
                <i class="ri-device-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Responsive <br class="d-none d-lg-inline-block">Design</h5>
              </div>
            </div>
            <div class="media flex-grow-1">
              <div class="remix-icon">
                <i class="ri-global-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Online <br class="d-none d-lg-inline-block">Marketing</h5>
              </div>
            </div>
          </div>
          <p class="mb-15">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla
            pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <p class="mb-30">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla
            pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <a href="javascript:void(0)" class="btn btn-outline-dark">Discover More</a>
        </div>
      </div>

    </div>
  </section>
  <!--End Extra Feature Section-->

  <!--Extra Feature Section-->
  <section class="section more-features bg-light" data-scroll-index="features">
    <div class="shape-bg-top-left" style="background-image: url(assets/images/shape-3.png)"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 mb-30 pe-lg-5" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="section-title mb-40">
            <p class="sub">Our App Feature Lists</p>
            <h2 class="mt-0">User Friendly App <br /> and Interface</h2>
          </div>
          <div class="sub-feature mb-30">
            <div class="media flex-grow-1 mb-3 mb-sm-0">
              <div class="remix-icon">
                <i class="ri-file-shield-2-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Data <br class="d-none d-lg-inline-block">Privacy</h5>
              </div>
            </div>
            <div class="media flex-grow-1">
              <div class="remix-icon">
                <i class="ri-shield-check-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Analytics <br class="d-none d-lg-inline-block">Security</h5>
              </div>
            </div>
          </div>
          <p class="mb-15">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla
            pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <ul class="list-unstyled highlight-list mb-30">
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
          </ul>
          <a href="javascript:void(0)" class="btn btn-outline-dark">Discover More</a>
        </div>
        <div class="col-lg-6 mb-30" data-aos="fade-left" data-aos-duration="400" data-aos-delay="600">
          <div class="mobile-mock text-center text-lg-end">
            <img src="assets/images/mobile-mock-3.png" class="img-fluid" alt="mobile mock">
          </div>
        </div>
      </div>

    </div>
  </section>
  <!--End Extra Feature Section-->

  <!--Extra Feature Section-->
  <section class="section position-relative more-features" data-scroll-index="features">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 mb-30">
          <div class="mobile-mock text-center text-lg-start">
            <img src="assets/images/mobile-mock.png" class="img-fluid" alt="mobile mock">
          </div>
        </div>
        <div class="col-lg-6 mb-30 ps-lg-5">
          <div class="section-title mb-40">
            <p class="sub">Our App Feature Lists</p>
            <h2 class="mt-0">Powerful App for <br>Your Mobile</h2>
          </div>
          <div class="sub-feature mb-30">
            <div class="media flex-grow-1 mb-3 mb-sm-0">
              <div class="remix-icon">
                <i class="ri-customer-service-2-fill"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Dedicated <br class="d-none d-lg-inline-block">Support</h5>
              </div>
            </div>
            <div class="media flex-grow-1">
              <div class="remix-icon">
                <i class="ri-pencil-ruler-2-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Digital <br class="d-none d-lg-inline-block">Design</h5>
              </div>
            </div>
          </div>
          <p class="mb-15">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla
            pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <ul class="list-unstyled highlight-list mb-30">
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
          </ul>
          <a href="https://www.youtube.com/watch?v=Kl5B6MBAntI" class="btn btn-outline-dark">
            <i class="ri-play-line font-18 fw-medium align-middle me-1"></i>
            <span class="align-middle">Watch Video</span>
          </a>
        </div>
      </div>

    </div>

    <div class="shape-square square-dot-anim tobottom">
      <img src="assets/images/shape-2.png" alt="" class="img-fluid" />
    </div>
  </section>
  <!--End Extra Feature Section-->

  <!--Pricing Section-->
  <app-pricing [className]="'pb-70'"></app-pricing>
  <!--End Pricing Section-->

  <!--Client Section-->
  <app-testimonial></app-testimonial>
  <!--End Client Section-->

  <!--Download Section-->
  <app-downloads></app-downloads>
  <!--End Download Section-->
</div>
<app-footer></app-footer>