<div class="position-relative">
	<div class="shape text-footer">
		<svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="#292838"></path>
		</svg>
	</div>
</div>
<footer class="footer-one">
	<div class="container">
		<div class="row">
			<div class="col-md-6 col-lg-4">
				<a class="navbar-brand text-white" href="index.html">
					<span class="dot"></span>GREENLAKE
				</a>
				<p class="my-30">Síguenos en nuestras redes sociales</p>
				<ul class="list-inline footer-social-one">
					<li class="list-inline-item"><a href="javascript:void(0)"><i class="ri-facebook-line"></i></a></li>
					<li class="list-inline-item"><a href="javascript:void(0)"><i class="ri-twitter-line"></i></a></li>
					<li class="list-inline-item"><a href="javascript:void(0)"><i class="ri-linkedin-line"></i></a></li>
					<li class="list-inline-item"><a href="javascript:void(0)"><i class="ri-dribbble-line"></i></a></li>
					<li class="list-inline-item"><a href="javascript:void(0)"><i class="ri-skype-line"></i></a></li>
				</ul>
			</div>

			<div class="col-lg-2 col-md-6">
				<h5>Empresa</h5>
				<ul class="list-unstyled footer-links">
					<li><a routerLink="/">Acerca de nosotros</a></li>
					<li><a routerLink="/">Servicios</a></li>
					<li><a routerLink="/">Contáctanos</a></li>
					<li><a routerLink="/">FAQ</a></li>
				</ul>
			</div>

			<div class="col-lg-2 col-md-6">
				<h5>Soporte</h5>
				<ul class="list-unstyled footer-links">
					<li><a routerLink="/">Ayuda &amp; Soporte</a></li>
					<li><a routerLink="/">Política Privada</a></li>
					<li><a routerLink="/">Terminos &amp; Condiciones</a></li>
				</ul>
			</div>

			<div class="col-md-6 col-lg-4">
				<div class="mb-15">
					<h5>Dónde puedes ubicarnos</h5>
					<ul class="list-unstyled footer-links">
						<li>
							<i class="ri-map-pin-line me-2 font-18"></i>
							<span>Isidora Goyenechea 2915, Las Condes, Chile</span>
						</li>
						<li>
							<i class="ri-phone-line me-2 font-18"></i>
							<span>(569) 5235XXXX</span>
						</li>
						<li>
							<i class="ri-mail-send-line me-2 font-18"></i>
							<span>contacto@greenlake.cl</span>
						</li>
					</ul>
				</div>
			</div>

		</div> <!-- end row -->
	</div>
	<!-- end container -->

</footer>