import { IFact } from "../interfaces/facts.interface";

export const facts: IFact[] = [
  {
    head: 100,
    text: "Total Downloads"
  },
  {
    head: 10,
    text: "Positive Reviews"
  },
  {
    head: 50,
    text: "Daily Visitors"
  },
  {
    head: 15,
    text: "Pro User"
  }
]

export const facts2: IFact[] = [
  {
    head: 159,
    text: "Awards Won",
    icon: "ri-trophy-line",
    type: "success"
  },
  {
    head: 1950,
    text: "Projects Done",
    icon: "ri-file-2-line",
    type: "danger"
  },
  {
    head: 100,
    text: "Happy Clients",
    icon: "ri-user-line",
    type: "warning"
  },
  {
    head: 4500,
    text: "Experts Received",
    icon: "ri-group-line",
    type: "info"
  }
]