import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { features } from 'src/app/data/features.data';
import { testi } from 'src/app/data/testimonials.data';
import { IFeature } from 'src/app/interfaces/features.interface';
import { Itestimonial } from 'src/app/interfaces/testimonials.interface';
import { NavlinkService } from 'src/app/shared/navlink.service';

@Component({
  selector: 'app-home9',
  templateUrl: './home9.component.html',
  styleUrls: ['./home9.component.scss']
})
export class Home9Component implements OnInit {

  navigations: Array<string> = ['home', 'features', 'pricing', 'client', 'download'];
  customOptions: OwlOptions = {
    items: 1,
    loop: false,
    margin: 0,
    nav: false,
    responsive: {
      0: {
        items: 1
      }
    }
  }
  featuresList: IFeature[] = features;
  testimonialList: Itestimonial[] = testi;
  constructor(private navlinkService: NavlinkService) { }

  ngOnInit(): void {
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.navlinkService.setCurrentSectionId(sectionId);
  }
}
