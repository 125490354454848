<section [class]="className" id="pricing" data-scroll-index="pricing">
  <div [class]="shapeObj.class" [ngStyle]="{'background-image': 'url(' + shapeObj?.img + ')'}" *ngIf="shapeObj?.bg"></div>
  <div class="container">
    <div class="section-title text-center" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
      <p class="sub">Choose Our Pricing</p>
      <h2>Our Pricing Plans</h2>
    </div><!-- end title -->

    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="row">

          <!--Pricing Column-->
          <div *ngFor="let item of priceList; let i = index" data-aos="fade-up" [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+400" class="pricing-column col-md-6 col-lg-4 mb-30">
            <div class="card border-0 inner-box box-shadow">
              <div class="card-body p-0 text-center">
                <div class="plan-header">
                  <div class="avatar-lg mx-auto mb-30">
                    <div class="avatar-title h4 shadow-icon rounded-circle" [ngClass]="{'bg-primary text-white': item?.recommended, 'text-primary': !item?.recommended}">
                      <i class="{{ icons[i] }}"></i>
                    </div>
                  </div>
                  <h3 class="plan-title">{{ item?.type }}</h3>
                </div>
                <h2 class="mt-3">${{ item?.price }} <small class="plan-duration">/Per Month</small></h2>
                <ul class="plan-stats list-unstyled">
                  <li *ngFor="let featureIem of item?.planFeatures"> <i
                      class="{{ featureIem?.include ? 'ri-check-line text-info' : 'ri-close-line text-danger'}} me-2"></i>
                      {{featureIem?.text }}</li>
                </ul>
                <div class="mt-30">

                  <a href="javascript:void(0)" *ngIf="item?.recommended;else not_recommended" class="btn btn-sm btn-primary">Buy Now</a>
                  <ng-template #not_recommended>
  
                    <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Buy Now</a>
                  </ng-template>
  
                </div>
              </div>
            </div>
          </div>

        </div>
      </div><!-- end col -->
    </div>
    <!-- end row -->
  </div>
</section>