import { Component, OnInit } from '@angular/core';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { faqs } from 'src/app/data/faqs.data';
import { IFaq } from 'src/app/interfaces/faqs.interface';
import { IShape } from 'src/app/interfaces/pricings.interface';
import { NavlinkService } from 'src/app/shared/navlink.service';

interface ActiveAccordianI {
  nextState: boolean;
  panelId: string
}

@Component({
  selector: 'app-home7',
  templateUrl: './home7.component.html',
  styleUrls: ['./home7.component.scss']
})
export class Home7Component implements OnInit {

  navigations: Array<string> = ['home', 'features', 'pricing', 'faq'];
  activeAccordian: ActiveAccordianI = {
    nextState: true,
    panelId: 'ba0'
  }; 
  activeHighlightAccordian: ActiveAccordianI = {
    nextState: true,
    panelId: 'ha1'
  }; 

  faqList: IFaq[] = faqs;
  shapeImg: IShape = {
    img: "assets/images/shape-5.png",
    class: "shape-square-2-left square-rotate-anim top-0",
    top: true
  }
  constructor(private navlinkService: NavlinkService) { }

  ngOnInit(): void {
  }

  
  highlightChangeTitle(event: NgbPanelChangeEvent) {
    this.activeAccordian = {
      nextState: event.nextState,
      panelId: event.panelId
    };
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.navlinkService.setCurrentSectionId(sectionId);
  }

  changeTitle(event: NgbPanelChangeEvent) {
    this.activeAccordian = {
      nextState: event.nextState,
      panelId: event.panelId
    };
  }
}
