<section class="section mb-20" id="download" data-scroll-index="download">
  <div class="container text-center">
    <div class="d-block mb-30" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
      <div class="download-icon bg-overlay-gradient-reverse text-white mb-30">
        A
      </div>
      <h2 data-aos="fade-up" data-aos-duration="400" data-aos-delay="500">Download Anywhere</h2>
    </div>
    <div class="row justify-content-center" data-aos="fade-up" data-aos-duration="400" data-aos-delay="600">
      <div class="col-lg-8 text-center">
        <p>Available for all major mobile and desktop platforms. Rapidiously visualize
          optimal ROI rather than enterprise-wide methods of empowerment. Repellat dignissimos libero. Qui sed at corrupti expedita voluptas odit. Nihil ea
          quia nesciunt. Repellat dignissimos libero.</p>
        <div class="mt-30">
          <a class="btn btn-outline-dark text-start mt-1 me-sm-2 mb-3 mb-sm-0" href="javascript:void(0)">
            <div class="media align-items-center">
              <i class="ri-apple-fill fs-4 me-2 my-0"></i>
              <div class="media-body">
                <strong class="d-block">Download App</strong> <span class="text-capitalize">from Apple Store</span>
              </div>
            </div>
          </a>
          <a class="btn btn-outline-dark text-start mt-1" href="javascript:void(0)">
            <div class="media align-items-center">
              <i class="ri-android-fill fs-4 me-2 my-0"></i>
              <div class="media-body">
                <strong class="d-block">Download App</strong> <span class="text-capitalize">from play Store</span>
              </div>
            </div>
          </a>
        </div>
      </div><!-- end col -->
    </div><!-- end row -->
  </div><!-- end container -->
</section>