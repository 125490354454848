import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { faqs } from 'src/app/data/faqs.data';
import { awesomeFeatures, features } from 'src/app/data/features.data';
import { prices } from 'src/app/data/pricings.data';
import { IFaq } from 'src/app/interfaces/faqs.interface';
import { IFeature } from 'src/app/interfaces/features.interface';
import { IPrice, IShape } from 'src/app/interfaces/pricings.interface';
import { NavlinkService } from 'src/app/shared/navlink.service';
import Typewriter from 'typewriter-effect/dist/core';

@Component({
  selector: 'app-home4',
  templateUrl: './home4.component.html',
  styleUrls: ['./home4.component.scss']
})
export class Home4Component implements OnInit {
  navigations: Array<string> = ['home', 'features',  'pricing', 'screens', 'faq'];
  customOptions: OwlOptions = {
    "loop": true,
    "margin": 30,
    "nav": false,
    "dots": true,
    "autoWidth": false,
    "autoplay": true,
    "smartSpeed": 700,
    "autoplayTimeout": 5000,
    "autoplayHoverPause": true,
    "slideBy": 5,
    "responsive": {
      "0": { "items": 1 },
      "480": { "items": 2 },
      "600": { "items": 3 },
      "991": { "items": 4 },
      "1000": { "items": 5 },
      "1200": { "items": 5 }
    }
  }
  featuresList: IFeature[] = features;
  awesomeFeaturesList: IFeature[] = awesomeFeatures;
  priceList: IPrice[] = prices;
  icons: string[] = ['ri-stack-line', 'ri-bookmark-3-line', 'ri-user-received-line'];
  screenshots: string[] = ['app-shot-n-1-1.jpg', 'app-shot-n-1-2.jpg', 'app-shot-n-1-3.jpg', 'app-shot-n-1-4.jpg', 'app-shot-n-1-5.jpg', 'app-shot-n-1-1.jpg', 'app-shot-n-1-4.jpg', 'app-shot-n-1-5.jpg', 'app-shot-n-1-2.jpg', 'app-shot-n-1-3.jpg'];
  faqList: IFaq[] = faqs;
  shapeImg: IShape = {
    img: "assets/images/shape-5.png",
    class: "shape-square-2 square-rotate-anim top-0",
    top: true
  }
  
  constructor(private navlinkService: NavlinkService) { }

  ngOnInit(): void {
    new Typewriter('#typewriter', {
      strings: ["designs", "creatives", "themes"],
      autoStart: true,
      loop: true
    });
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.navlinkService.setCurrentSectionId(sectionId);
  }
}
