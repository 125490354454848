import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { features } from 'src/app/data/features.data';
import { testi2 } from 'src/app/data/testimonials.data';
import { IFeature } from 'src/app/interfaces/features.interface';
import { IShape } from 'src/app/interfaces/pricings.interface';
import { Itestimonial } from 'src/app/interfaces/testimonials.interface';
import { NavlinkService } from 'src/app/shared/navlink.service';

@Component({
  selector: 'app-home3',
  templateUrl: './home3.component.html',
  styleUrls: ['./home3.component.scss']
})
export class Home3Component implements OnInit {

  navigations: Array<string> = ['home', 'features', 'screens', 'pricing', 'client'];
  customOptions: OwlOptions = {
    "loop": true,
    "margin": 30,
    "nav": false,
    "dots": true,
    "autoWidth": false,
    "autoplay": true,
    "smartSpeed": 700,
    "autoplayTimeout": 5000,
    "autoplayHoverPause": true,
    "slideBy": 5,
    "responsive": {
      "0": { "items": 1 },
      "480": { "items": 2 },
      "600": { "items": 3 },
      "991": { "items": 4 },
      "1000": { "items": 5 },
      "1200": { "items": 5 }
    }
  }
  testiOptions: OwlOptions = {
    items: 1,
    loop: false,
    margin: 24,
    nav: false,
    responsive: {
      640: {
        items: 2
      },
      900: {
        items: 3
      }
    }
  }
  signupForm: UntypedFormGroup;
  loader: boolean = false;
  submitted: boolean = false;
  featuresList: IFeature[] = features;
  screenshots: string[] = ['app-shot-n-1-1.jpg', 'app-shot-n-1-2.jpg', 'app-shot-n-1-3.jpg', 'app-shot-n-1-4.jpg', 'app-shot-n-1-5.jpg', 'app-shot-n-1-1.jpg', 'app-shot-n-1-4.jpg', 'app-shot-n-1-5.jpg', 'app-shot-n-1-2.jpg', 'app-shot-n-1-3.jpg']
  shapeImg: IShape = {
    img: "assets/images/shape-4.png",
    class: "shape-bg-bottom-right",
    top: false,
    bg: true
  }
  testimonialList: Itestimonial[] = testi2;
  constructor(private formBuilder: UntypedFormBuilder, private router: Router, private navlinkService: NavlinkService) { }

  /**
  * Get Form controls
  */
 get form() { return this.signupForm.controls; }

  ngOnInit(): void {
    //Initialize form
    this.signupForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.navlinkService.setCurrentSectionId(sectionId);
  }

  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.signupForm.invalid) {
      return;
    }

    this.loader = true;
    const obj = {
      name: this.form['name'].value,
      email: this.form['email'].value,
      username: this.form['username'].value,
      password: this.form['password'].value
    }
    alert(JSON.stringify(obj));
    console.log("form values ===>", obj);
    setTimeout(() => {
      this.loader = false
      this.router.navigate(['/confirm']);
    }, 1000);
  }
}
