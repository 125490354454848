<section class="{{ className }}" id="pricing" data-scroll-index="pricing">
  <div [class]="shapeObj?.class" *ngIf="shapeObj?.top">
    <img [src]="shapeObj?.img" alt="" class="img-fluid" />
  </div>
  <div class="container">
    <div class="section-title text-center" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
      <p class="sub">Choose Our Pricing</p>
      <h2>Our Pricing Plans</h2>
    </div><!--end title-->
    <div class="row justify-content-center">
      <div class="col-xl-10">
        <div class="row">
          <div *ngFor="let item of priceList; let i = index" data-aos="fade-up" [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+400" class="pricing-column col-md-6 col-lg-4 mb-30">
            <div class="inner-box text-center" [ngClass]="{'active': item?.recommended}">
              <div class="plan-header">
                <h3 class="plan-title">{{ item?.type }}</h3>
                <h2 class="plan-price">${{ item?.price }}</h2>
                <div class="plan-duration">Per Month</div>
              </div>
              <ul class="plan-stats list-unstyled">
                <li *ngFor="let featureIem of item?.planFeatures"> <i
                    class="{{ featureIem?.include ? 'ri-check-line text-info' : 'ri-close-line text-danger'}} me-2"></i>{{
                  featureIem?.text }}</li>
              </ul>

              <div class="clearfix">

                <a href="javascript:void(0)" *ngIf="item?.recommended;else not_recommended" class="btn btn-sm btn-primary">Buy Now</a>
                <ng-template #not_recommended>

                  <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Buy Now</a>
                </ng-template>

              </div>
            </div>
          </div><!--end col-->
        </div><!-- end row -->
      </div><!-- end col -->
    </div><!-- end row -->
  </div><!-- end container -->
</section>