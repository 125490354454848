<app-header [navigations]="navigations" [classes]="'navbar-light'"></app-header>

<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
  <!--Home Section-->
  <section class="section-lg home home-alt bg-img" id="home" data-scroll-index="home">
    <div class="bg-overlay bg-overlay-gradient-reverse"></div>
    <div class="container text-over-bg">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-xl-7 col-xxl-7 text-center">
          <h2 class="font-weight-normal text-white mb-0 mb-4" data-aos="fade-up" data-aos-duration="400"
            data-aos-delay="400"><img src="assets/images/home/leaf.png" class="img-fluid" alt="mobile mock" width="6%"
              height="6%"> Creemos en el desarrollo sostenible de las empresas</h2>
        </div>
        <div class="col-lg-8 col-xl-9 col-xxl-7 text-center">
          <h5 class="text-white opacity-9 mb-30" data-aos="fade-up" data-aos-duration="400" data-aos-delay="600">
            en el crecimiento amigable con el mediombiente, el compromiso con las comunidad y el desarrollo y movimiento
            de las personas</h5>
          <a href="javascript:void(0)" class="btn btn-white" data-aos="fade-up" data-aos-duration="400"
            data-aos-delay="800" (click)="comenzar()">Comenzar</a>
        </div>
      </div>
    </div>
  </section>

  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--End Home Section-->

  <div class="home-one-slider text-over-bg">
    <div class="container">
      <div class="row">
        <div *ngFor="let item of featuresList | slice:0:4; let i = index;" data-aos="fade-up"
          [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+400" class="col-md-6 col-lg-3 mb-30">
          <div class="features-box-4 features-box-4-{{ item?.type }} box-shadow border-bottom-0">
            <div class="icon">
              <i class="{{ item?.icon }}"></i>
            </div>
            <h5 class="mb-2">{{ item?.title }}</h5>
            <p class="w-100">{{ item?.desc }}</p>
            <div class="mt-15">
              <a href="javascript:void(0)" (click)="triggerScrollTo(2)" class="link">Sigue Leyendo</a>
            </div>
          </div>
        </div><!-- end col-->
      </div><!-- end row-->
    </div><!-- end container-->
  </div><!-- end div-->

  <!--Extra Features Section-->
  <section class="section more-features" id="productos" data-scroll-index="productos">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 mb-30" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="mobile-mock text-center text-lg-start">
            <img src="assets/images/mobile-mock.png" class="img-fluid" alt="mobile mock">
          </div>
        </div>
        <div class="col-lg-6 mb-30 ps-lg-5" data-aos="fade-left" data-aos-duration="400" data-aos-delay="500">
          <div class="section-title mb-40">
            <p class="sub">
              Gestiona tus emisiones de manera inteligente <!-- PRIMER PRODUCTO DE LA PÁGINA -->
            </p>
            <h2 class="mt-0">Con Greenlake <br>gestiona tus emisiones en un solo click </h2>
          </div>
          <div class="sub-feature mb-30">
            <div class="media flex-grow-1 mb-3 mb-sm-0">
              <div class="remix-icon">
                <i class="ri-device-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>
                  App <br class="d-none d-lg-inline-block">de Seguimiento</h5>
              </div>
            </div>
            <div class="media flex-grow-1">
              <div class="remix-icon">
                <i class="ri-global-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Apoyo <br class="d-none d-lg-inline-block">de Expertos</h5>
              </div>
            </div>
          </div>
          <p class="mb-15">Entendemos que la visualización y gestión de la información es importante, por lo que creamos
            una herramienta que te permite conocer, ordenar y organizar los diferentes alcances de la Huella de Carbono
            (Categorías 1, 2 y 3), siguiendo los estándares definidos por GHG Protocol, separando la información por
            Holding, Sitios, Equipos y otros, que permiten el desglose completo de tus datos para un seguimiento
            exhaustivo.</p>
          <p class="mb-30">La Plataforma Greenlake COPEC permite realizar seguimiento de forma automática la huella de
            carbono
            de cada organización, permitiendo a los clientes una visualización completa del estado de sus procesos
            en términos de Gases Efecto Invernadero (GEI),.</p>
          <a href="javascript:void(0)" (click)="triggerScrollTo(4)" class="btn btn-outline-dark">Conocer Mas</a>
        </div>
      </div>

    </div>
  </section>
  <!--End Extra Features Section-->

  <!--Extra Features Section-->
  <section class="section more-features bg-light" data-scroll-index="productos">
    <div class="shape-bg-top-left" style="background-image: url(assets/images/shape-3.png)"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 mb-30 pe-lg-5" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="section-title mb-40">
            <p class="sub">IOT para el seguimiento de tus procesos</p>
            <h2 class="mt-0">IOENERGY <br /> solución de sensorización</h2>
          </div>
          <div class="sub-feature mb-30">
            <div class="media flex-grow-1 mb-3 mb-sm-0">
              <div class="remix-icon">
                <i class="ri-file-shield-2-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Datos Cercanos<br class="d-none d-lg-inline-block"> a Tiempo Real</h5>
              </div>
            </div>
            <div class="media flex-grow-1">
              <div class="remix-icon">
                <i class="ri-shield-check-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Reportes <br class="d-none d-lg-inline-block">Analíticos</h5>
              </div>
            </div>
          </div>
          <p class="mb-15">Hoy en día es un factor decisivo contar con información cercana al tiempo real para tomar
            decisiones
            estratégicas que pueden ser un factor fundamental entre la excelencia operacional o el no llegar a las metas
            . <br><br>La razón de ser de IOENERGY es apoyarte en lograr tus objetivos operacionales al brindarte
            información analítica o insights sobre tus procesos para que logres
            un crecimiento sostenible en tus metas productivos.</p>
          <ul class="list-unstyled highlight-list mb-30">
            <li>Realiza el seguimiento de tus procesos por medio del dashboard de control operacional</li>
            <li>Entérate de las incidencias por medio de notificaciones en tiempo real</li>
            <li>Obtén los insight necesarios para tomar las mejores decisiones a través de reportes de analítica</li>
            <li>Crea reportes e informes de forma automática</li>
          </ul>
          <a href="javascript:void(0)" (click)="triggerScrollTo(4)" class="btn btn-outline-dark">Saber más</a>
        </div>
        <div class="col-lg-6 mb-30" data-aos="fade-left" data-aos-duration="400" data-aos-delay="500">
          <div class="mobile-mock text-center text-lg-end text-end">
            <img src="assets/images/mobile-mock-3.png" class="img-fluid" alt="mobile mock">
          </div>
        </div>
      </div>

    </div>
  </section>
  <!--End Extra Features Section-->

  <!--Extra Features Section-->
  <section class="section position-relative more-features" data-scroll-index="productos">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 mb-30" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="mobile-mock text-center text-lg-start">
            <img src="assets/images/mobile-mock-4.png" class="img-fluid" alt="mobile mock">
          </div>
        </div>
        <div class="col-lg-6 mb-30 ps-lg-5" data-aos="fade-left" data-aos-duration="400" data-aos-delay="500">
          <div class="section-title mb-40">
            <p class="sub">Lleva el control operacional con Sigmo</p>
            <h2 class="mt-0">Sistema de Mantenimiento <br>de Gestión Integrado</h2>
          </div>
          <div class="sub-feature mb-30">
            <div class="media flex-grow-1 mb-3 mb-sm-0">
              <div class="remix-icon">
                <i class="ri-device-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>App <br class="d-none d-lg-inline-block">Móvil</h5>
              </div>
            </div>
            <div class="media flex-grow-1">
              <div class="remix-icon">
                <i class="ri-pencil-ruler-2-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Reportes <br class="d-none d-lg-inline-block">de Analítica</h5>
              </div>
            </div>
          </div>
          <p class="mb-15">SIGMO es una poderosa herramienta de gestión de mantenimiento y un compañero ideal para
            cumplir con tus objetivos de excelencia operacional, control de actividades de mantenimiento y mucho más.
          </p>
          <ul class="list-unstyled highlight-list mb-30">
            <li>Revisa las evidencias de detenciones no programadas</li>
            <li>Crea planes de mantenimiento desde la comodidad de tu teléfono</li>
            <li>Comunícate con tu equipo y toma las mejores decisiones</li>
          </ul>
          <a href="javascript:void(0)" (click)="triggerScrollTo(4)" class="btn btn-outline-dark">Saber más</a>
        </div>
      </div>

    </div>

    <div class="shape-square square-dot-anim tobottom">
      <img src="assets/images/shape-2.png" alt="" class="img-fluid" />
    </div>
  </section>
  <!--End Extra Features Section-->

  <!--Pricing Section
  <app-pricing [className]="'pb-70'"></app-pricing>
  End Pricing Section-->

  <!--Client Section-->
  <section class="section" id="clientes" data-scroll-index="clientes">
    <div class="shape-bg-bottom-right" style="background-image: url(assets/images/shape-4.png)"></div>
    <div class="container" data-aos="fade" data-aos-duration="400" data-aos-delay="400">
      <div class="section-title text-center">
        <p class="sub">Testimonios</p>
        <h2 class="title">¿Qué dicen nuestros clientes?</h2>
      </div> <!-- end title -->
      <owl-carousel-o [options]="testiOptions" class="pb-30 testi-carousel">
        <ng-template carouselSlide *ngFor="let item of testimonialList">
          <div class="item mb-30">
            <div class="bg-soft-primary rounded p-4">
              <p>{{ item?.testimonio }}</p>
            </div>
            <div class="media pt-30 align-items-center">
              <div class="avatar-lg me-3">
                <div class="avatar-title bg-soft-{{ item?.icon?.type }} text-{{ item?.icon?.type }} rounded-circle"
                  *ngIf="item?.icon">
                  {{ item?.icon?.name }}
                </div>
                <img [src]="'assets/images/'+item?.img" *ngIf="item?.img" alt="testi-user"
                  class="rounded-circle img-fluid d-block me-3">
              </div>
              <div class="media-body">
                <h6 class="mb-0">{{ item?.author }}</h6>
                <p class="font-14">{{ item?.desig }}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </section><!-- end container -->
  <!--End Client Section-->

  <!--Contact us Section-->
  <section class="section" id="contactanos" data-scroll-index="contactanos">
    <div class="container">
      <div class="contact-box p-0 shadow-none">
        <div class="row align-items-center">
          <div class="col-lg-6 col-xl-5 pe-lg-5 mb-30 mb-lg-0" data-aos="fade-right" data-aos-duration="400"
            data-aos-delay="400">
            <div class="contact-info-card bg-overlay-gradient-reverse border-radius-2x">
              <div class="section-title">
                <span class="sub-text text-white">Conversemos</span>
                <h2 class="text-white">Contacta a Nuestros Expertos</h2>
              </div>
              <div class="address-card mb-30">
                <div class="icon">
                  <i class="ri-message-line fs-4"></i>
                </div>
                <div class="text">
                  <span class="label">Email:</span>
                  <a href="mailto:csepulveda@greenlake.cl" class="text-white">
                    contacto@greenlake.cl
                  </a>
                </div>
              </div>
              <div class="address-card mb-30">
                <div class="icon">
                  <i class="ri-phone-line fs-4"></i>
                </div>
                <div class="text">
                  <span class="label">Teléfono:</span>
                  <span> (+569) 5235XXXX </span>
                </div>
              </div>
              <div class="address-card mb-30">
                <div class="icon">
                  <i class="ri-map-pin-line fs-4"></i>
                </div>
                <div class="text">
                  <span class="label">Dirección:</span>
                  <span> Isidora Goyenechea 2915,<br>Santiago, Chile</span>
                </div>
              </div>
              <hr class="my-30 text-white">
              <div class="social-icons">
                <a href="javascript:void(0)"><i class="ri-facebook-line"></i></a>
                <a href="javascript:void(0)"><i class="ri-twitter-line"></i></a>
                <a href="javascript:void(0)"><i class="ri-linkedin-line"></i></a>
                <a href="javascript:void(0)"><i class="ri-dribbble-line"></i></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-7" data-aos="fade-left" data-aos-duration="400" data-aos-delay="500"
            >
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.350327711982!2d-70.60324868420304!3d-33.41410980303539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf41adad7909%3A0xf342629bfb300f28!2sIsidora%20Goyenechea%202915%2C%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1676007311824!5m2!1ses-419!2scl" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Contact us Section-->
</div>
<app-footer></app-footer>