export const mock = {
  categories: [{
    text: "Mobile Apps",
    count: 5
  }, {
    text: "Repair",
    count: 7
  }, {
    text: "SASS",
    count: 3
  }, {
    text: "PC",
    count: 3
  }, {
    text: "Adwords", 
    count: 12
  }, {
    text: "Social media",
    count: 20
  }, {
    text: "Dashboard",
    count: 6
  }, {
    text: "Creative",
    count: 8
  }, {
    text: "Infographic",
    count: 9
  }],
  post: [{
    img: "assets/images/thumb/thumb-65.jpg",
    text: "Lorem ipsum dolor amet fast vlora istikam ...",
    date: "18 Oct 2017"
  },{
    img: "assets/images/thumb/thumb-65-2.jpg",
    text: "Lorem ipsum dolor amet fast vlora istikam ...",
    date: "19 Oct 2017"
  },{
    img: "assets/images/thumb/thumb-65-4.jpg",
    text: "Lorem ipsum dolor amet fast vlora istikam ...",
    date: "20 Oct 2017"
  },{
    img: "assets/images/thumb/thumb-65-5.jpg",
    text: "Lorem ipsum dolor amet fast vlora istikam ...",
    date: "21 Oct 2017"
  }],
  tags: ["Electronics", "SEO", "Wordpress", "Adwords", "Creative", "Infographic", "Web Development", "Design", "Educational", "PC", "Designer"],
  feeds: ["assets/images/thumb/thumb-100.jpg", "assets/images/thumb/thumb-100-2.jpg", "assets/images/thumb/thumb-100-3.jpg", "assets/images/thumb/thumb-100-4.jpg", "assets/images/thumb/thumb-100-5.jpg", "assets/images/thumb/thumb-100-6.jpg"]
}