<div class="auth-pages bg-light">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-xl-11">
				<div class="row justify-content-center">
					<div class="col-md-6 col-xl-5">
						<div class="card mb-0 p-2 p-md-3">
							<div class="card-body">
								<div class="mb-4">
									<div class="text-center">
										<img src="assets/images/thumb/thumb-100.jpg" class="rounded-circle" alt="Lettstart Admin" />
										<h4 class="mt-2 font-weight-600">John Doe</h4>
									</div>
								</div>
								<form name="lockscreenForm" [formGroup]="lockscreenForm" (ngSubmit)="onSubmit()" novalidate>
									<div class="form-group app-label"
										[ngClass]="{'invalid-field': submitted && form.password.errors, 'is-valid': submitted && !form.password.errors}">
										<input type="password" class="form-control" formControlName="password" id="lockpassword" floatingLabel />
										<label for="lockpassword">Password</label>
										<div *ngIf="submitted && form.password?.errors?.required" class="invalid-feedback">
											<p>This field is required</p>
										</div>
									</div>
									<div class="d-grid mt-30 mb-15">
										<button class="btn btn-primary btn-block" type="submit">
											<span *ngIf="loader"><i class="ri-loader-4-line spin h6 text-white"></i></span>
											<span *ngIf="!loader">Submit</span>
										</button>
									</div>
								</form>
								<p class="text-center">
									<a routerLink="/recover" class="link">Forgot Password?</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- end container -->
</div>