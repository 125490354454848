import { AfterViewInit, Component } from '@angular/core';
import { init, AosOptions } from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'GREENLAKE';
  AOSOption: AosOptions = {
    disable: "tablet",
    offset: 150,
    duration: 400,
    easing: 'linear',
    delay: 0,
    once: true
  }
  ngAfterViewInit(): void {
    //Initialize AOS
    init(this.AOSOption);
  }
}
