<app-pageheader [navigations]="navigations"></app-pageheader>
<!--Home Section-->
<app-page-banner title="Teams"></app-page-banner>
<!--End Home Section-->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-xl-3 mb-30">
        <div class="team">
          <img src="assets/images/ourteam-images/370-350_1.jpg" class="img-fluid" alt="team" />
          <div class="desc">
            <h5 class="text-uppercase">Jim Beglin</h5>
            <small>Web Designer</small>
            <div class="social-icons social-white mt-15 justify-content-center">
              <a href="javascript:void(0)"><i class="ri-facebook-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-twitter-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-instagram-line"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xl-3 mb-30">
        <div class="team">
          <img src="assets/images/ourteam-images/370-350_2.jpg" class="img-fluid" alt="team" />
          <div class="desc">
            <h5 class="text-uppercase">Mark</h5>
            <small>Web Dev</small>
            <div class="social-icons social-white mt-15 justify-content-center">
              <a href="javascript:void(0)"><i class="ri-facebook-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-twitter-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-instagram-line"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xl-3 mb-30">
        <div class="team">
          <img src="assets/images/ourteam-images/370-350_3.jpg" class="img-fluid" alt="team" />
          <div class="desc">
            <h5 class="text-uppercase">Riya</h5>
            <small>QA</small>
            <div class="social-icons social-white mt-15 justify-content-center">
              <a href="javascript:void(0)"><i class="ri-facebook-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-twitter-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-instagram-line"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xl-3 mb-30">
        <div class="team">
          <img src="assets/images/ourteam-images/370-350_4.jpg" class="img-fluid" alt="team" />
          <div class="desc">
            <h5 class="text-uppercase">Apk</h5>
            <small>Manager</small>
            <div class="social-icons social-white mt-15 justify-content-center">
              <a href="javascript:void(0)"><i class="ri-facebook-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-twitter-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-instagram-line"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xl-3 mb-30">
        <div class="team">
          <img src="assets/images/ourteam-images/370-350_5.jpg" class="img-fluid" alt="team" />
          <div class="desc">
            <h5 class="text-uppercase">Maria</h5>
            <small>FE Dev</small>
            <div class="social-icons social-white mt-15 justify-content-center">
              <a href="javascript:void(0)"><i class="ri-facebook-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-twitter-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-instagram-line"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xl-3 mb-30">
        <div class="team">
          <img src="assets/images/ourteam-images/370-350_6.jpg" class="img-fluid" alt="team" />
          <div class="desc">
            <h5 class="text-uppercase">Brick</h5>
            <small>Lead Engineer</small>
            <div class="social-icons social-white mt-15 justify-content-center">
              <a href="javascript:void(0)"><i class="ri-facebook-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-twitter-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-instagram-line"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xl-3 mb-30">
        <div class="team">
          <img src="assets/images/ourteam-images/370-350_5.jpg" class="img-fluid" alt="team" />
          <div class="desc">
            <h5 class="text-uppercase">Maria</h5>
            <small>FE Dev</small>
            <div class="social-icons social-white mt-15 justify-content-center">
              <a href="javascript:void(0)"><i class="ri-facebook-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-twitter-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-instagram-line"></i></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xl-3 mb-30">
        <div class="team">
          <img src="assets/images/ourteam-images/370-350_6.jpg" class="img-fluid" alt="team" />
          <div class="desc">
            <h5 class="text-uppercase">Brick</h5>
            <small>Lead Engineer</small>
            <div class="social-icons social-white mt-15 justify-content-center">
              <a href="javascript:void(0)"><i class="ri-facebook-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-twitter-line"></i></a>
              <a href="javascript:void(0)"><i class="ri-instagram-line"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>