import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { blogs } from 'src/app/data/blogs.data';
import { facts2 } from 'src/app/data/facts';
import { features } from 'src/app/data/features.data';
import { prices } from 'src/app/data/pricings.data';
import { IBlog } from 'src/app/interfaces/blogs.interface';
import { IFact } from 'src/app/interfaces/facts.interface';
import { IFeature } from 'src/app/interfaces/features.interface';
import { IPrice } from 'src/app/interfaces/pricings.interface';
import { NavlinkService } from 'src/app/shared/navlink.service';
// import Swiper core and required modules
import SwiperCore, { Navigation, SwiperOptions } from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation]);
@Component({
  selector: 'app-home6',
  templateUrl: './home6.component.html',
  styleUrls: ['./home6.component.scss', "../../../../node_modules/swiper/swiper.scss"],
  encapsulation: ViewEncapsulation.None
})
export class Home6Component implements OnInit {
  navigations: Array<string> = ['home', 'services', 'features', 'pricing', 'blog'];
  customOptions: OwlOptions = {
    items: 1,
    loop: false,
    margin: 0,
    nav: false,
    responsive: {
      0: {
        items: 1
      }
    }
  }
  responsiveBreakpoints: {
    [width: number]: SwiperOptions;
    [ratio: string]: SwiperOptions;
  } = {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 15
    },
    // when window width is >= 640px
    768: {
      slidesPerView: 2,
      spaceBetween: 30
    }
  }
  featuresList: IFeature[] = features;
  communityFacts: IFact[] = facts2;
  screenshots: string[] = ["app-2_1.jpg", "app-2_2.jpg", "app-2_3.jpg", "app-2_4.jpg", "app-2_5.jpg"];
  blogList: IBlog[] = blogs;
  priceList: IPrice[] = prices;
  icons: string[] = ['ri-stack-line', 'ri-bookmark-3-line', 'ri-user-received-line'];
  constructor(private navlinkService: NavlinkService) { }

  ngOnInit(): void {
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.navlinkService.setCurrentSectionId(sectionId);
  }
}
