import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-work-single',
  templateUrl: './work-single.component.html',
  styleUrls: ['./work-single.component.scss']
})
export class WorkSingleComponent implements OnInit {

  navigations: Array<string> = ['home', 'features', 'pricing', 'blog', 'faq'];
  customOption: OwlOptions = {
    loop: false,
    margin: 0,
    autoplay: false,
    dots: false,
    smartSpeed: 350,
    nav: true,
    navText: ["<i class='ri-arrow-left-s-line'></i>", "<i class='ri-arrow-right-s-line'></i>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
