<app-header [navigations]="navigations" [classes]="'navbar-dark'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
  <!--Home Section-->
  <section class="home pb-0" id="home" data-scroll-index="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-9 col-xl-7 text-center">
          <h1 class="text-gred mb-30" data-aos="fade-down" data-aos-duration="400" data-aos-delay="400">Creative. Smart. Awesome.</h1>
          <p class="font-24 mb-30" data-aos="fade-down" data-aos-duration="400" data-aos-delay="600">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua.</p>
          <div class="mb-45" data-aos="fade-down" data-aos-duration="400" data-aos-delay="800">
            <a href="javascript:void(0)" class="btn btn-primary me-15">Get Started</a>
            <a href="javascript:void(0)" class="btn btn-outline-primary">Contact Us</a>
          </div>
          <div data-aos="fade-down" data-aos-duration="400" data-aos-delay="1000">
            <img src="assets/images/showcase_7.png" alt="" class="img-fluid d-block">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Home Section-->

  <!--Features Section-->
  <section class="section" id="features" data-scroll-index="services">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-5 mb-30 mb-lg-0" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="section-title mb-40">
            <p class="sub">Our Services</p>
            <h2>What We Do?</h2>
          </div>
          <p class="mb-15">Donec ullamcorper nulla non metus auctor fringilla. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit.</p>
          <p class="mb-30">Donec ullamcorper nulla non metus auctor fringilla. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Nullam quis risus eget urna mollis ornare vel eu leo. Nullam quis risus eget urna mollis
            ornare vel eu leo. Maecenas faucibus mollis elit interdum. Duis mollis, est non commodo luctus, nisi erat
            ligula.</p>
          <a href="javascript:void(0)" class="btn btn-primary">More Details</a>
        </div>

        <div class="col-lg-7 col-xl-6 ms-xl-auto">
          <div class="row mt-30 mt-lg-0">
            <div class="col-sm-6" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
              <div class="features-icon-box card shadow-none mb-30">
                <div class="card-body p-30">
                  <i class="ri-file-shield-2-line fs-2 m-0"></i>
                  <h3 class="my-15">Data Privacy</h3>
                  <p>Sed ut perspiciatis unde omnis iste natus error
                    sit.</p>
                </div>
                <div class="big-icon icon-right">
                  <i class="ri-stack-line"></i>
                </div>
              </div>
              <div class="features-icon-box card shadow-none mb-30">
                <div class="card-body p-30">
                  <i class="ri-shield-check-line fs-2 m-0"></i>
                  <h3 class="my-15">Analytics Security</h3>
                  <p>Sed ut perspiciatis unde omnis iste natus error
                    sit.</p>
                </div>
                <div class="big-icon icon-right">
                  <i class="ri-shield-check-line"></i>
                </div>
              </div>
            </div>

            <div class="col-sm-6" data-aos="fade-up" data-aos-duration="400" data-aos-delay="600">
              <div class="features-icon-box card shadow-none mt-md-5 mb-30">
                <div class="card-body p-30">
                  <i class="ri-pencil-ruler-2-line fs-2 m-0"></i>
                  <h3 class="my-15">Digital Design</h3>

                  <p>Sed ut perspiciatis unde omnis iste natus error
                    sit.</p>
                </div>
                <div class="big-icon icon-right">
                  <i class="ri-pencil-ruler-2-line"></i>
                </div>
              </div>

              <div class="features-icon-box card shadow-none mb-30">
                <div class="card-body p-30">
                  <i class="ri-stack-line fs-2 m-0"></i>
                  <h3 class="my-15">Strategy Solutions</h3>

                  <p>Sed ut perspiciatis unde omnis iste natus error
                    sit.</p>
                </div>
                <div class="big-icon icon-right">
                  <i class="ri-stack-line"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
  <!--End Features Section-->

  <!--Services Section-->
  <section class="section pt-0" id="services" data-scroll-index="features">
    <div class="container">
      <div class="section-title text-center" data-aos="fade-down" data-aos-duration="400" data-aos-delay="400">
        <p class="sub">Our App Service Lists</p>
        <h2>Great Services</h2>
      </div>
      <div class="row">
        <div class="col-sm-6 col-lg-4 mb-30" *ngFor="let item of featuresList;  let i = index" data-aos="fade-up" [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+200">
          <div class="features-icon-box card shadow-none">
            <div class="card-body p-30">
              <i class="{{ item?.icon }} fs-2 m-0"></i>
              <h3 class="my-15">{{ item?.title }}</h3>
              <p>{{ item?.desc.substr(0, 110) }}</p>
            </div>
            <div class="big-icon icon-right">
              <i class="{{ item?.icon }}"></i>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
  <!--End Services Section-->

  <!--Facts Section-->
  <section class="pt-100 pb-70 bg-light" data-scroll-index="features">
    <div class="container">
      <div class="row align-items-center flex-lg-row-reverse">
        <div class="col-lg-6 ps-lg-5 mb-30" data-aos="fade-left" data-aos-duration="400" data-aos-delay="900">
          <div class="section-title mb-40">
            <p class="sub">Our Team</p>
            <h2>Join Our Community</h2>
          </div>
          <p>Donec ullamcorper nulla non metus auctor fringilla. Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Nullam quis risus eget urna mollis ornare vel eu leo. Nullam quis risus eget urna mollis ornare vel eu
            leo. Maecenas faucibus mollis elit interdum. Duis mollis, est non commodo luctus, nisi erat ligula.</p>
        </div>
        <div class="col-lg-6 mb-30">
          <div class="row">
            <div class="col-md-6 mb-30" *ngFor="let item of communityFacts;  let i = index" data-aos="fade" [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+200">
              <div class="fact-box bg-white border-radius-2x">
                <i class="{{ item?.icon }} bg-soft-{{ item?.type }} text-{{ item?.type }} me-15"></i>
                <div class="text">
                  <h3 class="counter mb-2">{{ item?.head }}</h3>
                  <h6>{{ item?.text }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Facts Section-->

  <!-- Screenshot section-->
  <section class="section">
    <div class="container" data-aos="fade" data-aos-duration="400" data-aos-delay="400">
      <div class="section-title text-center">
        <p class="sub">Check Before Choosing</p>
        <h2 class="mt-0">App Screenshots</h2>
      </div>
      <swiper [breakpoints]="responsiveBreakpoints" [navigation]="{
        nextEl: nextBtn,
        prevEl: prevBtn 
      }" class="overflow-visible" #swiperRef>
        <ng-template swiperSlide *ngFor="let item of screenshots">
          <img [src]="'assets/images/screens/'+item" class="img-fluid border-radius-2x" alt="ss"/>
        </ng-template>
      </swiper>
      <div class="swiper-slider-button mt-30">
        <button #prevBtn class="slider-button-prev">
          <i class="ri-arrow-left-line"></i>
        </button>
        <button #nextBtn class="slider-button-next">
          <i class="ri-arrow-right-line"></i>
        </button>
      </div>
    </div>
  </section>
  <!-- End Screenshot section-->

  <!--Pricing Section-->
  <section class="pt-100 pb-70 bg-light" id="pricing" data-scroll-index="pricing">
    <div class="container">
      <div class="section-title text-center" data-aos="fade-down" data-aos-duration="400" data-aos-delay="400">
        <p class="sub">Choose Our Pricing</p>
        <h2>Our Pricing Plans</h2>
      </div><!--end title -->
      <div class="row">
        <!--Pricing Column-->
        <div class="pricing-column col-md-6 col-lg-4 mb-30" *ngFor="let item of priceList; let i = index" data-aos="fade-up" [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+400">
          <div class="card border-0 inner-box box-shadow">
            <div class="card-body p-0">
              <div class="plan-header border-bottom">
                <div class="media">
                  <div class="media-body">
                    <h4 class="plan-title text-dark font-weight-medium m-0">{{ item?.type }}</h4>
                  </div>
                  <div class="ms-2">
                    <i class="{{ icons[i] }} text-primary h3"></i>
                  </div>
                </div>
                <div class="mb-3">
                  <h3>${{ item?.price }} / <span class="plan-duration">Per Month</span></h3>
                </div>
              </div>
              <ul class="plan-stats list-unstyled text-muted">
                <li *ngFor="let featureIem of item?.planFeatures"> <i class="ri-arrow-right-s-line me-2 text-primary"></i>{{featureIem?.text }}</li>
              </ul>
              <div class="text-center mt-30">
                <a href="javascript:void(0)" *ngIf="item?.recommended;else not_recommended" class="btn btn-sm btn-primary">Buy Now</a>
                <ng-template #not_recommended>
                  <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Buy Now</a>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Pricing Section-->

  <!--Client Section-->
  <section class="section" id="client" data-scroll-index="blog">
    <div class="container" data-aos="fade" data-aos-duration="400" data-aos-delay="400">
      <div class="section-title text-center">
        <p class="sub">Happy Clients</p>
        <h2 class="title">Testimonials</h2>
      </div> <!-- end title -->
      <div class="row justify-content-center">
        <div class="col-lg-8 text-center">
          <owl-carousel-o [options]="customOptions" class="testimonial-slider-3">
            <ng-template carouselSlide>
              <div class="item pb-30">
                <h5 class="testi-text">"I have been using this template for all my company needs for the last 3 years and couldn’t be happier with their service and expertise. They’ve surpassed all of my expectations and customer service! "</h5>
                <div class="mt-30 mb-15">
                  <img src="assets/images/user-1.jpg" alt="testi-user" class="rounded-circle mx-auto d-block testi-img">
                </div>
                <h5 class="mb-1">John Doe</h5>
                <h6 class="font-14">CEO &amp; Founder of Company</h6>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item pb-30">
                <h5 class="testi-text">" I have been using this template for all my company needs for the last 3 years and couldn’t be happier with their service and expertise. They’ve surpassed all of my expectations and customer service! "</h5>
                <div class="mt-30 mb-15">
                  <img src="assets/images/user-2.jpg" alt="testi-user" class="rounded-circle mx-auto d-block testi-img">
                </div>
                <h5 class="mb-1">Kenneth Bailey</h5>
                <h6 class="font-14">CEO &amp; Founder of Company</h6>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item pb-30">
                <h5 class="testi-text">" I have been using this template for all my company needs for the last 3 years and couldn’t be happier with their service and expertise. They’ve surpassed all of my expectations and customer service! "</h5>
                <div class="mt-30 mb-15">
                  <img src="assets/images/user-3.jpg" alt="testi-user" class="rounded-circle mx-auto d-block testi-img">
                </div>
                <h5 class="mb-1">Mark Wainright</h5>
                <h6 class="font-14">CEO &amp; Founder of Company</h6>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
    <!-- end container -->
  </section>
  <!--End Client Section-->
  
  <!--Blogs Section-->
  <section class="section pt-0" id="blog" data-scroll-index="blog">
    <div class="container">
      <div class="section-title text-center" data-aos="fade-down" data-aos-duration="400" data-aos-delay="400">
        <p class="sub">See Our Latest News</p>
        <h2>Our Blogs</h2>
      </div><!-- end title -->
      <div class="row">
        <div class="col-md-6 col-lg-4 mb-30" *ngFor="let item of blogList | slice:0:3; let i = index" data-aos="fade-up" [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+200">
          <div class="blog box-shadow">
            <img [src]="'assets/images/blogs/'+item?.img" class="img-fluid" alt="blog" />
            <div class="blog-body">
              <ul class="meta list-unstyled">
                <li><i class="ri-calendar-2-line me-2"></i> {{ item?.meta?.date }}</li>
                <li><i class="ri-user-2-line me-2"></i> {{ item?.meta?.author }}</li>
                <li><i class="ri-message-2-line me-2"></i> {{ item?.meta?.comments>10 ? item?.meta?.comments : ('0'+item?.meta?.comments) }}</li>
              </ul>
              <h5 class="my-15 font-18"><a href="javascript:void(0)">{{ item?.title }}</a></h5>
              <p class="mb-15">{{item?.desc}}</p>
              <a href="javascript:void(0)" class="link">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Blogs Section-->
</div>
<app-footer></app-footer>