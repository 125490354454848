import { IPrice } from "../interfaces/pricings.interface";

export const prices: IPrice[] = [
  {
    type: "basic",
    price: 19,
    planFeatures: [{
      include: true,
      text: "10 GB Storege"
    }, {
      include: true,
      text: "500 GB Bandwidth", 
    }, {
      include: false,
      text: "Email Support", 
    }, {
      include: false,
      text:  "24x7 Support"
    }]
  },
  {
    type: "standard",
    price: 29,
    recommended: true,
    planFeatures: [{
      include: true,
      text: "10 GB Storege"
    }, {
      include: true,
      text: "500 GB Bandwidth", 
    }, {
      include: true,
      text: "Email Support", 
    }, {
      include: false,
      text:  "24x7 Support"
    }]
  },
  {
    type: "premium",
    price: 39,
    planFeatures: [{
      include: true,
      text: "10 GB Storege"
    }, {
      include: true,
      text: "500 GB Bandwidth", 
    }, {
      include: true,
      text: "Email Support", 
    }, {
      include: true,
      text:  "24x7 Support"
    }]
  }
]