import { Component, Input, OnInit } from '@angular/core';
import { prices } from 'src/app/data/pricings.data';
import { IPrice, IShape } from 'src/app/interfaces/pricings.interface';

@Component({
  selector: 'app-pricing2',
  templateUrl: './pricing2.component.html',
  styleUrls: ['./pricing2.component.scss']
})
export class Pricing2Component implements OnInit {
  @Input() className: string;
  @Input() shapeObj?: IShape;
  priceList: IPrice[] = prices;
  icons: string[] = ['ri-stack-line', 'ri-bookmark-3-line', 'ri-user-received-line']
  constructor() { }

  ngOnInit(): void {
  }

}
