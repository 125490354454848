import { IBlog } from "../interfaces/blogs.interface";

export const blogs: IBlog[] = [
  {
    img: "01.jpg",
    title: "How to optimize your Instagram profile for success",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's...",
    meta: {
      date: "Sep 15, 2020",
      author: "Author",
      comments: 3
    }
  },
  {
    img: "02.jpg",
    title: "Research & development advisor success innovator",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's...",
    meta: {
      date: "Sep 15, 2020",
      author: "Author",
      comments: 3
    }
  },
  {
    img: "03.jpg",
    title: "What’s trending in the tech industries?",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's...",
    meta: {
      date: "Sep 15, 2020",
      author: "Author",
      comments: 3
    }
  },
  {
    img: "04.jpg",
    title: "Channels business model canvas network effects",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's...",
    meta: {
      date: "Sep 15, 2020",
      author: "Author",
      comments: 3
    }
  },
  {
    img: "05.jpg",
    title: "User experience business-to-business pitch",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's...",
    meta: {
      date: "Sep 15, 2020",
      author: "Author",
      comments: 3
    }
  },
  {
    img: "06.jpg",
    title: "What’s trending in the tech industries?",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's...",
    meta: {
      date: "Sep 15, 2020",
      author: "Author",
      comments: 3
    }
  },
  {
    img: "07.jpg",
    title: "Channels business model canvas network effects",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's...",
    meta: {
      date: "Sep 15, 2020",
      author: "Author",
      comments: 3
    }
  },
  {
    img: "08.jpg",
    title: "How to optimize your Instagram profile for success",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's...",
    meta: {
      date: "Sep 15, 2020",
      author: "Author",
      comments: 3
    }
  },
  {
    img: "02.jpg",
    title: "User experience business-to-business pitch",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been industry's...",
    meta: {
      date: "Sep 15, 2020",
      author: "Author",
      comments: 3
    }
  }
]