import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { awesomeFeatures, features } from 'src/app/data/features.data';
import { IFeature } from 'src/app/interfaces/features.interface';
import { IShape } from 'src/app/interfaces/pricings.interface';
import { NavlinkService } from 'src/app/shared/navlink.service';

@Component({
  selector: 'app-home5',
  templateUrl: './home5.component.html',
  styleUrls: ['./home5.component.scss']
})
export class Home5Component implements OnInit {

  navigations: Array<string> = ['home', 'features', 'pricing', 'client', 'contact us'];
  testiOptions: OwlOptions = {
    items: 1,
    loop: false,
    margin: 24,
    nav: false,
    responsive: {
      640: {
        items: 2
      },
      900: {
        items: 3
      }
    }
  }
  contactForm: UntypedFormGroup;
  loader: boolean = false;
  submitted: boolean = false;
  showSuccessMsg: boolean = false;
  successMsg: string = '';
  numrPattern = "^[0-9]+$";
  alertType: string = 'success';
  
  featuresList: IFeature[] = features;
  awesomeFeaturesList: IFeature[] = awesomeFeatures;
  shapeImg: IShape = {
    img: "assets/images/shape-5.png",
    class: "shape-square-2-left square-rotate-anim top-0",
    top: true
  }
  constructor(private navlinkService: NavlinkService, private formBuilder: UntypedFormBuilder, private http: HttpClient) { }


  ngOnInit(): void {
    //Initialize form
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(this.numrPattern), Validators.min(10)]],
      website: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  /**
  * Get Form controls
  */
  get form() { return this.contactForm.controls; }

  /**
   * On submit form
   */
  onSubmit(id: string) {
    console.log(this.contactForm)
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }

    this.loader = true;
    let body = new URLSearchParams();
    body.set('name', this.form.name.value);
    body.set('email', this.form.email.value);
    body.set('phone', this.form.phone.value);
    body.set('website', this.form.website.value);
    body.set('message', this.form.message.value);
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    
    this.http.post("assets/php/index.php", body.toString(), { ...options, responseType: "text" }).subscribe({
      next: (data) => {
        this.successMsg = data;
        this.showSuccessMsg = true;
        this.loader = false;
        this.resetForm(id);
      },
      error: (error) => {
        this.alertType = 'danger';
        this.successMsg = "<p class='mb-0'>Unable to submit form. Send mail to us support@lettstartdesign.com</p>";
        this.showSuccessMsg = true;
        this.loader = false;
        this.resetForm(id);
      }
    });
  }

  resetForm(id: string): void {
    setTimeout(() => {
      this.showSuccessMsg = false;
    }, 5000)
    this.submitted=false;
    this.contactForm.reset();
    this.contactForm.markAsUntouched();
    this.contactForm.clearValidators();
    this.contactForm.clearAsyncValidators();
    const form = document.getElementById(id);
    const elems = form.querySelectorAll('.up');
    elems.forEach((item) => {
      item.classList.remove('up')
      item.classList.remove('invalid-field')
    })
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.navlinkService.setCurrentSectionId(sectionId);
  }
}
