import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blogcontent',
  templateUrl: './blogcontent.component.html',
  styleUrls: ['./blogcontent.component.scss']
})
export class BlogcontentComponent implements OnInit {
  commentForm: UntypedFormGroup;
  loader: boolean = false;
  submitted: boolean = false;
  constructor(private formBuilder: UntypedFormBuilder, private router: Router) { }

  ngOnInit(): void {
    //Initialize form
    this.commentForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      website: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  /**
  * Get Form controls
  */
 get form() { return this.commentForm.controls; }
 
  /**
   * On submit form
   */
  onSubmit(id: string) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.commentForm.invalid) {
      return;
    }

    this.loader = true;
    const obj = {
      name: this.form.name.value,
      email: this.form.email.value,
      website: this.form.website.value,
      message: this.form.message.value
    }
    console.log("form values ===>", obj);
    
    setTimeout(() => {
      this.loader = false;
      this.commentForm.reset();
      this.commentForm.get('name').clearValidators();
      this.commentForm.get('name').updateValueAndValidity();
      this.commentForm.get('email').clearValidators();
      this.commentForm.get('email').updateValueAndValidity();
      this.commentForm.get('website').clearValidators();
      this.commentForm.get('website').updateValueAndValidity();
      this.commentForm.get('message').clearValidators();
      this.commentForm.get('message').updateValueAndValidity();
      const form = document.getElementById(id);
      const elems =  form.querySelectorAll('.up');
      elems.forEach((item) => {
        item.classList.remove('up')
      })
    }, 1000);
  }
}
