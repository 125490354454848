<div class="auth-pages bg-light">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-xl-11">
				<div class="row justify-content-center">
					<div class="col-md-6 pe-md-0">
						<div class="auth-page-sidebar">
							<div class="bg-overlay opacity-6"></div>
							<div class="auth-user-testimonial">
								<!--
								<owl-carousel-o [options]="customOptions">
									<ng-template carouselSlide>
										<div class="item">
											<h3 class="text-white mb-1">I love this theme!</h3>
											<h5 class="text-white mb-3">"I love it very much!"</h5>
											<p>- Admin User</p>
										</div>
									</ng-template>
									<ng-template carouselSlide>
										<div class="item">
											<h3 class="text-white mb-1">I love this theme!</h3>
											<h5 class="text-white mb-3">"I love it very much!"</h5>
											<p>- Admin User</p>
										</div>
									</ng-template>
									<ng-template carouselSlide>
										<div class="item">
											<h3 class="text-white mb-1">I love this theme!</h3>
											<h5 class="text-white mb-3">"I love it very much!"</h5>
											<p>- Admin User</p>
										</div>
									</ng-template>
								</owl-carousel-o>
								-->
							</div>
						</div>
					</div>
					<div class="col-md-6 ps-md-0">
						<div class="card mb-0 p-2 p-md-3">
							<div class="card-body">
								<div class="clearfix">
									<a class="navbar-brand logo" routerLink="/">
										<span class="dot"></span>GREENLAKE
									</a>
								</div>
								<h5 class="mt-30 mb-1 font-weight-600">Welcome back!</h5>
								<p class="text-muted mb-15">Enter your email address and password to access admin panel.
								</p>
								<form name="signupForm" [formGroup]="signupForm" (ngSubmit)="onSubmit()" novalidate>
									<div class="form-group app-label mb-30"
										[ngClass]="{'invalid-field': submitted && form.name.errors, 'is-valid': submitted && !form.name.errors}">
										<input type="text" class="form-control" formControlName="name" id="name" floatingLabel />
										<label for="name">Name</label>
										<div *ngIf="submitted && form.name?.errors" class="invalid-feedback">
											<p>This field is required</p>
										</div>
									</div>
									<div class="form-group app-label mb-30"
										[ngClass]="{'invalid-field': submitted && form.email.errors, 'is-valid': submitted && !form.email.errors}">
										<input type="email" class="form-control" formControlName="email" id="signupEmail" floatingLabel />
										<label for="signupEmail">Email Address</label>
										<div *ngIf="submitted && form.email?.errors?.required" class="invalid-feedback">
											<p>This field is required</p>
										</div>
										<div *ngIf="submitted && form.email?.errors?.email" class="invalid-feedback">
											<p>Email must be a valid email address</p>
										</div>
									</div>
									<div class="form-group app-label mb-30"
										[ngClass]="{'invalid-field': submitted && form.password.errors, 'is-valid': submitted && !form.password.errors}">
										<input type="password" class="form-control" formControlName="password" id="password"
											floatingLabel />
										<label for="password">Password</label>
										<div *ngIf="submitted && form.password?.errors" class="invalid-feedback">
											<p>This field is required</p>
										</div>
									</div>
									<div class="form-group app-label mb-30"
										[ngClass]="{'invalid-field': submitted && form.confirmpwd.errors, 'is-valid': submitted && !form.confirmpwd.errors}">
										<input type="password" class="form-control" formControlName="confirmpwd" id="confirmpwd"
											floatingLabel />
										<label for="confirmpwd">Confirm Password</label>
										<div *ngIf="submitted && form.confirmpwd?.errors?.mustMatch" class="invalid-feedback">
											<p>Password and confirm password does not match</p>
										</div>
									</div>

									<div class="form-group">
										<div class="form-check">
											<input type="checkbox" class="form-check-input" id="checkbox-signin" checked>
											<label class="form-check-label" for="checkbox-signin">I accept <a routerLink="/">Term and
													Conditions</a></label>
										</div>
									</div>
									<div class="d-grid mt-30 mb-15">
										<button class="btn btn-primary btn-block" type="submit">
											<span *ngIf="loader"><i class="ri-loader-4-line spin h6 text-white"></i></span>
											<span *ngIf="!loader">Register</span>
										</button>
									</div>
									<div class="clearfix text-center">
										<p>Already have an account? <a routerLink="/login" class="link font-weight-bold">Login</a>
										</p>
									</div>
									<div class="py-3 text-center"><span class="font-size-16 font-weight-bold">OR</span>
									</div>
									<div class="d-flex justify-content-center flex-wrap">
										<button class="btn btn-danger brand-btn mb-3 me-2" type="button">
											<span class="brand-icon">
												<i class="ri-google-line align-middle"></i>
											</span>
											<span class="align-middle">Google</span>
										</button>
										<button class="btn btn-primary brand-btn mb-3" type="button">
											<span class="brand-icon">
												<i class="ri-facebook-line align-middle"></i>
											</span>
											<span class="align-middle">Facebook</span>
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- end container -->
</div>