<nav id="navbar" #navbar
  class="navbar navbar-expand-lg navbar-default navbar-fixed {{classes}}" (window:scroll)="windowScroll()">
  <div class="container">
    <a class="navbar-brand logo" routerLink="/">
      <span class="dot"></span>GREENLAKE
    </a>
    <div class="navbar-collapse" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" id="navbarCollapse">
      <ul class="nav navbar-nav ms-auto navbar-center" id="mySidenav">
        <li class="nav-item" *ngFor="let nav of navigations; let i = index">

          <a href="javascript: void(0);" (click)="changeNavigation(nav)"
            [ngClass]="{'active':(currentSection$ | async) === nav}" class="nav-link">{{nav}}</a>

        </li>
      </ul>
    </div>
  </div>
</nav>

<section class="section">

  <ng-container [ngSwitch]="selectedNavigation">

    <app-dashboard *ngSwitchCase="'Dashboard'"></app-dashboard>

    <!-- <app-insights *ngSwitchCase="'Insights'"></app-insights> -->

  </ng-container>
  
</section>

<app-footer></app-footer>