<aside class="blog-sidebar">
  <div class="widget widget-search mb-30 box-shadow">
    <h5 class="widget-title">Search</h5>
    <div class="input-group">
      <input type="text" class="form-control" placeholder="Find your thinking...">
      <span class="input-group-btn">
        <button class="btn text-primary" type="button">
          <i class="ri-search-2-line"></i>
        </button></span>
    </div>
  </div>
  <div class="widget mb-30 box-shadow">
    <h5 class="widget-title">Categories</h5>
    <ul class="widget-category-list list-unstyled">
      <li *ngFor="let cat of sidebarData.categories">
        <a href="javascript:void(0)">
          <span class="text">{{cat?.text}}</span>
          <span>{{cat?.count}}</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="widget widget-post mb-30 box-shadow">
    <h5 class="widget-title">Recent Post</h5>
    <div class="media" *ngFor="let item of sidebarData.post">
      <img [src]="item?.img" class="rounded" alt="recent thumb">
      <div class="media-body">
        <p><a href="javascript:void(0)">{{item?.text}}</a></p>
        <small class="text-primary">
          <i class="ri-calendar-2-line fa-lg align-middle me-1"></i> 
          <span class="align-middle">{{item?.date}}</span>
        </small>
      </div>
    </div>
  </div>
  <div class="widget mb-30 box-shadow">
    <h5 class="widget-title">Tags</h5>
    <ul class="widget-tags list-unstyled">
      <li *ngFor="let tag of sidebarData.tags">
        <a href="javascript:void(0)" class="btn btn-sm btn-outline-dark mb-2">
          <span>{{tag}}</span>
        </a>
      </li>
    </ul>
  </div>
  <div class="widget widget-insta mb-30 box-shadow">
    <h5 class="widget-title">Instragram Feed</h5>
    <ul class="insta-feeds list-unstyled">
      <li *ngFor="let feed of sidebarData.feeds">
        <a href="javascript:void(0)">
          <img [src]="feed" class="rounded" alt="">
        </a>
      </li>
    </ul>
  </div>
</aside>