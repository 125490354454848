import { Itestimonial } from "../interfaces/testimonials.interface";

export const testi: Itestimonial[] = [
  {
    img: "user-1.jpg",
    author: "John Doe",
    desig: "CEO & Founder of Company",
    testimonio: ""
  },
  {
    img: "user-2.jpg",
    author: "Mark Joe",
    desig: "CEO & Founder of Company",
    testimonio: ""
  },
  {
    img: "user-3.jpg",
    author: "Peterson",
    desig: "CEO & Founder of Company",
    testimonio: ""
  },{
    img: "user-1.jpg",
    author: "John Doe",
    desig: "CEO & Founder of Company",
    testimonio: ""
  },
  {
    img: "user-2.jpg",
    author: "Mark Joe",
    desig: "CEO & Founder of Company",
    testimonio: ""
  }
]

export const testi2: Itestimonial[] = [
  {
    icon: {
      type: 'primary',
      name: 'F'
    },
    author: "Francisco",
    desig: "Gerente",
    testimonio: "SIGMO nos ha permitido tener mayor trazabilidad de nuestras actividades de mantenimiento y estar informados prontamente cuando ocurre alguna incidencia en la operación"
  },
  {
    author: "Leandro",
    desig: "Gerente",
    icon: {
      type: 'primary',
      name: 'L'
    },
    testimonio: "IOENERGY se ha transformado en una herramienta fundamental para siempre contar con el lubricante necesario para cumplir con nuestras metas productivas"
  },
  {
    icon: {
      type: 'primary',
      name: 'O'
    },
    author: "",
    desig: "",
    testimonio: ""
  }
]

