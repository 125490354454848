import { Component, OnInit } from '@angular/core';
import { blogs } from 'src/app/data/blogs.data';
import { IBlog } from 'src/app/interfaces/blogs.interface';
import { IPageNumber } from 'src/app/interfaces/pagination.interface';

@Component({
  selector: 'app-blogsrightsidebar',
  templateUrl: './blogsrightsidebar.component.html',
  styleUrls: ['./blogsrightsidebar.component.scss']
})
export class BlogsrightsidebarComponent implements OnInit {
  navigations: Array<string> = ['home', 'features', 'pricing', 'blog', 'faq'];
  blogList: IBlog[] = blogs;
  public paginationSetting: IPageNumber = {
    currentPage: 1,
    pageSize: 6
  }
  constructor() { }

  ngOnInit(): void {
  }

}
