<app-header [navigations]="navigations" [classes]="'navbar-light'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
  <!--Home Section-->
  <section class="section-lg home-alt bg-img" id="home" data-scroll-index="home">
    <div class="bg-dark bg-overlay opacity-9"></div>
    <div class="container text-over-bg">
      <div class="row justify-content-center">
        <div class="col-lg-10 col-xl-8 text-center">
          <p class="text-white-50 mb-15" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">Creative Landing Page Template</p>
          <h1 class="font-weight-medium mb-30" data-aos="fade-up" data-aos-duration="400" data-aos-delay="600">We love to create creative beautiful templates</h1>
          <a href="javascript:void(0)" class="btn btn-primary" data-aos="fade-up" data-aos-duration="400" data-aos-delay="800">Get Started</a>
        </div>
      </div>
    </div>
  </section>

  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--End Home Section-->

  <!--Features Section-->
  <div class="home-one-slider text-over-bg">
    <div class="container">
      <div class="row">
        <div *ngFor="let item of featuresList | slice:0:4; let i = index;" data-aos="fade-up" [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+400" class="col-md-6 col-lg-3 mb-30">
          <div class="features-box-4 features-box-4-{{ item?.type }} box-shadow">
            <div class="icon">
              <i class="{{ item?.icon }}"></i>
            </div>
            <h5 class="mb-2">{{ item?.title }}</h5>
            <p class="w-100">{{ item?.desc }}</p>
            <div class="mt-15">
              <a routerLink="/index-9" class="link">Read More</a>
            </div>
          </div>
        </div><!-- end col-->
      </div><!-- end row-->
    </div><!-- end container-->
  </div><!-- end Features Section-->

  <!--Features Section-->
  <section class="section more-features" id="features" data-scroll-index="features">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 mb-30" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="mobile-mock text-center text-lg-start">
            <img src="assets/images/mobile-mock.png" class="img-fluid" alt="mobile mock">
          </div>
        </div>
        <div class="col-lg-6 mb-30 ps-lg-5" data-aos="fade-left" data-aos-duration="400" data-aos-delay="500">
          <div class="section-title mb-40">
            <p class="sub">Our App Feature Lists</p>
            <h2 class="mt-0">Powerful App for <br>Your Mobile</h2>
          </div>
          <div class="sub-feature mb-30">
            <div class="media flex-grow-1 mb-3 mb-sm-0">
              <div class="remix-icon">
                <i class="ri-device-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Responsive <br class="d-none d-lg-inline-block">Design</h5>
              </div>
            </div>
            <div class="media flex-grow-1">
              <div class="remix-icon">
                <i class="ri-global-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Online <br class="d-none d-lg-inline-block">Marketing</h5>
              </div>
            </div>
          </div>
          <p class="mb-15">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla
            pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <p class="mb-30">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla
            pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <a href="javascript:void(0)" class="btn btn-outline-dark">Discover More</a>
        </div>
      </div>

    </div>
  </section>
  <!--End Features Section-->

  <!--Extra Features Section-->
  <section class="section more-features bg-light" data-scroll-index="features">
    <div class="shape-bg-top-left" style="background-image: url(assets/images/shape-3.png)"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 mb-30 pe-lg-5" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="section-title mb-40">
            <p class="sub">Our App Feature Lists</p>
            <h2 class="mt-0">User Friendly App <br /> and Interface</h2>
          </div>
          <div class="sub-feature mb-30">
            <div class="media flex-grow-1 mb-3 mb-sm-0">
              <div class="remix-icon">
                <i class="ri-file-shield-2-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Data <br class="d-none d-lg-inline-block">Privacy</h5>
              </div>
            </div>
            <div class="media flex-grow-1">
              <div class="remix-icon">
                <i class="ri-shield-check-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Analytics <br class="d-none d-lg-inline-block">Security</h5>
              </div>
            </div>
          </div>
          <p class="mb-15">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla
            pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <ul class="list-unstyled highlight-list mb-30">
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
          </ul>
          <a href="javascript:void(0)" class="btn btn-outline-dark">Discover More</a>
        </div>
        <div class="col-lg-6 mb-30" data-aos="fade-left" data-aos-duration="400" data-aos-delay="500">
          <div class="mobile-mock text-center text-lg-end text-end">
            <img src="assets/images/mobile-mock-3.png" class="img-fluid" alt="mobile mock">
          </div>
        </div>
      </div>

    </div>
  </section>
  <!--End Extra Features Section-->

  <!--Extra Features Section-->
  <section class="section position-relative more-features" data-scroll-index="features">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 mb-30" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="mobile-mock text-center text-lg-start">
            <img src="assets/images/mobile-mock.png" class="img-fluid" alt="mobile mock">
          </div>
        </div>
        <div class="col-lg-6 mb-30 ps-lg-5" data-aos="fade-left" data-aos-duration="400" data-aos-delay="500">
          <div class="section-title mb-40">
            <p class="sub">Our App Feature Lists</p>
            <h2 class="mt-0">Powerful App for <br>Your Mobile</h2>
          </div>
          <div class="sub-feature mb-30">
            <div class="media flex-grow-1 mb-3 mb-sm-0">
              <div class="remix-icon">
                <i class="ri-customer-service-2-fill"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Dedicated <br class="d-none d-lg-inline-block">Support</h5>
              </div>
            </div>
            <div class="media flex-grow-1">
              <div class="remix-icon">
                <i class="ri-pencil-ruler-2-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Digital <br class="d-none d-lg-inline-block">Design</h5>
              </div>
            </div>
          </div>
          <p class="mb-15">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla
            pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <ul class="list-unstyled highlight-list mb-30">
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
          </ul>
          <a href="https://www.youtube.com/watch?v=Kl5B6MBAntI" class="btn btn-outline-dark">
            <i class="ri-play-line font-18 fw-medium align-middle me-1"></i>
            <span class="align-middle">Watch Video</span>
          </a>
        </div>
      </div>

    </div>

    <div class="shape-square square-dot-anim tobottom">
      <img src="assets/images/shape-2.png" alt="" class="img-fluid" />
    </div>
  </section>
  <!--End Extra Features Section-->

  <!--Pricing Section-->
  <app-pricing [className]="'pb-70'"></app-pricing>
	<!--End Pricing Section-->

  <!--Client Section-->
  <section class="section bg-light" id="client" data-scroll-index="client">
		<div class="shape-bg-bottom-right" style="background-image: url(assets/images/shape-4.png)"></div>
    <div class="container" data-aos="fade" data-aos-duration="400" data-aos-delay="400">
      <div class="section-title text-center">
        <p class="sub">Happy Clients</p>
        <h2 class="title">Testimonials</h2>
      </div> <!-- end title -->
      <div class="row justify-content-center">
        <div class="col-lg-8 text-center">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let item of testimonialList | slice:0:3">
              <div class="item pb-30">
                <h5 class="testi-text">I have been using this template for all my company needs for the last 3 years and couldn’t be happier with their service and expertise.
                  They’ve surpassed all of my expectations and customer service!</h5>
                <div class="mt-30 mb-15">
                  <img [src]="'assets/images/'+item?.img" alt="testi-user" class="rounded-circle mx-auto d-block testi-img">
                </div>
								<h5 class="mb-1">{{ item?.author }}</h5>
								<h6 class="font-14">{{ item?.desig }}</h6>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
    <!-- end container -->
  </section>
  <!--End Client Section-->

  <!--Download Section-->
  <app-downloads></app-downloads>
  <!--End Download Section-->
</div>
<app-footer></app-footer>