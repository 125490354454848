import { Component, OnInit } from '@angular/core';
import { NavlinkService } from 'src/app/shared/navlink.service';

@Component({
  selector: 'app-home2',
  templateUrl: './home2.component.html',
  styleUrls: ['./home2.component.scss']
})
export class Home2Component implements OnInit {
  navigations: Array<string> = ['home', 'features', 'pricing', 'client', 'download'];
 
  constructor(private navlinkService: NavlinkService) { }

  ngOnInit(): void {
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.navlinkService.setCurrentSectionId(sectionId);
  }
}
