import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lockscreen',
  templateUrl: './lockscreen.component.html',
  styleUrls: ['./lockscreen.component.scss']
})
export class LockscreenComponent implements OnInit {
  lockscreenForm: UntypedFormGroup;
  submitted = false;
  error = '';
  loader = false;
  constructor(private formBuilder: UntypedFormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.lockscreenForm = this.formBuilder.group({
      password: ['', [Validators.required]],
    });
  }

  // convenience getter for easy access to form fields
  get form() { return this.lockscreenForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.lockscreenForm.invalid) {
      return;
    }

    this.loader = true;
    setTimeout(() => {
      this.loader = false;
      this.router.navigate(['/']);
    }, 1000);
  }
}
