import { IFeature } from "../interfaces/features.interface";

export const features: IFeature[] = [
  {
    title: "Privacidad de los Datos",
    desc: "con Greenlake, realiza el seguimiento de tu huella organizacional y productiva, con la confianza de un equipo profesional a tu alcance",
    icon: "ri-file-shield-2-line",
    type: "primary"
  },
  {
    title: "Seguimiento Operacional",
    desc: "ten trazabilidad de tus planes de excelencia operacional con SIGMO, entérate rápidamente de incidentes y revisa las evidencias para tomar decisiones de mantenimiento informado",
    icon: "ri-shield-check-line",
    type: "warning"
  },
  {
    title: "IOT",
    desc: "a través de la sensorización ofrecida en IOEnergy,  sácale partido a tus datos y obten el insight necesario para tomar decisiones de excelencia operacional soportado por reportes analíticos adhoc",
    icon: "ri-settings-3-line",
    type: "danger"
  },
  {
    title: "Trazabilidad de Consumos",
    desc: "con INFUEL visualiza tus indicadores claves de tu operación, eficientando el control y gestión de combustibles en tu faena",
    icon: "ri-stack-line",
    type: "info"
  },
  {
    title: "Dedicated Support",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry ut perspiciatis. Sed ut perspiciatis unde omnis iste natus error sit.",
    icon: "ri-customer-service-2-fill",
    type: "purple"
  },
  {
    title: "Digital Design",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry ut perspiciatis. Sed ut perspiciatis unde omnis iste natus error sit.",
    icon: "ri-pencil-ruler-2-line",
    type: "success"
  }
]

export const awesomeFeatures: IFeature[] = [
  {
    title: "Unlimated Lifetime",
    desc: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.",
    icon: "ri-file-shield-2-line",
    type: "primary"
  },
  {
    title: "Our Mission",
    desc: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.",
    icon: "ri-shield-check-line",
    type: "warning"
  },
  {
    title: "Find Your Docs",
    desc: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.",
    icon: "ri-settings-3-line",
    type: "danger"
  },
  {
    title: "Calculate Your Coins",
    desc: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.",
    icon: "ri-stack-line",
    type: "info"
  },
  {
    title: "Great App Musics",
    desc: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.",
    icon: "ri-customer-service-2-fill",
    type: "purple"
  },
  {
    title: "Take Your Coffe",
    desc: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.",
    icon: "ri-pencil-ruler-2-line",
    type: "success"
  }
]
