<section class="inner-home bg-img" data-scroll-index="0">
  <div class="bg-overlay-gradient bg-overlay opacity-8"></div>
  <div class="container text-over-bg">
    <div class="row justify-content-center">
      <div class="col-lg-8 text-center">
        <h1 class="font-weight-medium mb-0">{{ title }}</h1>
        <ul class="breadcrumb">
          <li><a href="index_1.html">Home</a></li>
          <li>{{ title }}</li>
        </ul>
      </div>
    </div>
  </div>
</section>  
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>