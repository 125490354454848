<app-header [navigations]="navigations" [classes]="'navbar-light'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">

  <!--Home Section-->
  <section class="home-carousel" id="home" data-scroll-index="home">
    <ngb-carousel [showNavigationIndicators]="false">
      <ng-template ngbSlide>
        <div style="background: url(assets/images/bg-img-1.jpg);">
          <div class="bg-overlay"></div>
          <div class="container home-alt text-over-bg">
            <div class="row justify-content-center">
              <div class="col-xl-8 text-white text-center" data-aos="fade" data-aos-duration="400"
                data-aos-delay="400">
                <p class="text-white-50 mb-15">Creative Landing Page Template</p>
                <h1 class="font-weight-medium">We love to create creative beautiful templates</h1>
                <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started <i
                    class="ri-arrow-right-line align-middle"></i></a>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div style="background: url(assets/images/bg-img-2.jpg);">
          <div class="bg-overlay"></div>
          <div class="container home-alt text-over-bg">
            <div class="row justify-content-center">
              <div class="col-xl-8 text-white text-center">
                <p class="text-white-50 mb-15">Creative Landing Page Template</p>
                <h1 class="font-weight-medium">We love to create creative beautiful templates</h1>
                <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started <i
                    class="ri-arrow-right-line align-middle"></i></a>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
  </section>
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--End Home Section-->

  <!--Features Section-->
  <section class="pt-100 pb-70" id="features" data-scroll-index="features">
    <div class="container">
      <div class="section-title text-center" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
        <p class="sub">Our App Feature Lists</p>
        <h2>Great Features</h2>
      </div>
      <!--End title-->
      <div class="row">
        <div *ngFor="let item of featuresList; let i = index;" class="col-md-6 col-lg-4 mb-45" data-aos="fade-up"
          [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+400">
          <div class="features-box-2 text-center">
            <div class="avatar-lg mx-auto mb-30">
              <div class="avatar-title font-24 shadow-icon rounded-circle">
                <i class="{{ item?.icon }} bg-soft-{{ item?.type }} text-{{ item?.type }}"></i>
              </div>
            </div>
            <h5 class="mb-15">{{ item?.title }}</h5>
            <p>{{ item?.desc }}</p>
            <div class="mt-15">
              <a routerlink="/index-8" class="link">Read More</a>
            </div>
          </div>
        </div>
      </div>
      <!--End row-->
    </div>
    <!--End container-->
  </section>
  <!--End Features Section-->

  <!--Extra Features-->
  <section class="section bg-light more-features pb-200" data-scroll-index="features">
    <div class="shape-bg-top-left" style="background-image: url(assets/images/shape-3.png)"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 mb-30" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="mobile-mock text-center text-lg-start">
            <img src="assets/images/mobile-mock.png" class="img-fluid" alt="mobile mock" />
          </div>
        </div>
        <div class="col-lg-6 mb-30 ps-lg-5" data-aos="fade-left" data-aos-duration="500" data-aos-delay="500">
          <div class="section-title mb-40">
            <p class="sub">Our App Feature Lists</p>
            <h2 class="mt-0">Powerful App for <br />Your Mobile</h2>
          </div>
          <div class="sub-feature mb-30">
            <div class="media flex-grow-1">
              <div class="remix-icon">
                <i class="ri-device-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Responsive <br class="d-none d-lg-inline-block" />Design</h5>
              </div>
            </div>
            <div class="media flex-grow-1">
              <div class="remix-icon">
                <i class="ri-global-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Online <br class="d-none d-lg-inline-block" />Marketing</h5>
              </div>
            </div>
          </div>
          <p class="mb-15">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla
            pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <p class="mb-30">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
            eufugiat nulla
            pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <div class="mt-4">
            <a class="btn btn-outline-dark text-start mt-1 me-2" href="javascript:void(0)">

              <div class="media align-items-center">
                <i class="ri-apple-fill fs-4 me-2 my-0"></i>
                <div class="media-body">
                  <strong class="d-block">Download App</strong> <span class="text-capitalize">from
                    Apple Store</span>
                </div>
              </div>
            </a>

            <a class="btn btn-outline-dark text-start mt-1" href="javascript:void(0)">

              <div class="media align-items-center">
                <i class="ri-android-fill fs-4 me-2 my-0"></i>
                <div class="media-body">
                  <strong class="d-block">Download App</strong> <span class="text-capitalize">from
                    play Store</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

    </div>
  </section>
  <!--End Extra Features-->

  <!--Video Section-->
  <section class="section pt-0 video-one mt--100" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400"
    data-scroll-index="features">
    <div class="container">
      <div class="video-bg position-relative bg-1">
        <div class="bg-overlay bg-overlay-gradient opacity-8"></div>
        <div class="video-content text-over-bg">
          <a href="https://www.youtube.com/watch?v=Kl5B6MBAntI" class="video-btn-circle circle-anim mb-50">
            <i class="ri-play-line font-48"></i>
          </a>
          <div class="video-title">
            <h2 class="h1 text-white">Watch Our Video</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Video Section-->

  <!--Pricing Section-->
  <app-pricing [className]="'pt-100 pb-70 position-relative'" [shapeObj]="shapeImg"></app-pricing>
  <!--End Pricing Section-->

  <!--Faq Section-->
  <section class="section bg-light" id="faq" data-scroll-index="faq">
    <div class="shape-bg-bottom-right" style="background-image: url(assets/images/shape-4.png)"></div>
    <div class="container">
      <div class="section-title text-center" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
        <p class="sub">Frequently Asked Questions</p>
        <h2 class="title">Have Any Question?</h2>
      </div> <!-- end title -->
      <div class="row">
        <div class="col-md-6" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="question-box mb-45" *ngFor="let item of faqList | slice:0:2">
            <div class="media">
              <div class="avatar-md me-3">
                <div class="avatar-title bg-soft-primary text-primary m-0 shadow-icon rounded-circle">
                  Q
                </div>
              </div>
              <div class="media-body ms-2">
                <h6 class="mb-15">{{ item?.head }}</h6>
                <p>Lorem ipsum dolor sit amet, in mea
                  nonumes dissentias dissentiunt, pro te solet iriure. Cu sit consetetur moderatius
                  intellegam, ius decore accusamus Mutat convenirete.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6" data-aos="fade-left" data-aos-duration="400" data-aos-delay="500">
          <div class="question-box mb-45" *ngFor="let item of faqList | slice:3:5">
            <div class="media">
              <div class="avatar-md me-3">
                <div class="avatar-title bg-soft-primary text-primary m-0 shadow-icon rounded-circle">
                  Q
                </div>
              </div>
              <div class="media-body ms-2">
                <h6 class="mb-15">{{ item?.head }}</h6>
                <p>Lorem ipsum dolor sit amet, in mea
                  nonumes dissentias dissentiunt, pro te solet iriure. Cu sit consetetur moderatius
                  intellegam, ius decore accusamus Mutat convenirete.</p>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
  <!--End Faq Section-->

  <!--Blog Section-->
  <section class="section" id="blog" data-scroll-index="blog">
    <div class="container">
      <div class="section-title text-center" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
        <p class="sub">See Our Latest News</p>
        <h2>Our Blogs</h2>
      </div><!-- end title -->
      <div class="row">
        <div class="col-md-6 col-lg-4 mb-30" *ngFor="let item of blogList | slice:0:3; let i = index" data-aos="fade-up"
          [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+200">
          <div class="blog box-shadow">
            <img [src]="'assets/images/blogs/'+item?.img" class="img-fluid" alt="blog" />
            <div class="blog-body">
              <ul class="meta list-unstyled">
                <li><i class="ri-calendar-2-line me-2"></i> {{ item?.meta?.date }}</li>
                <li><i class="ri-user-2-line me-2"></i> {{ item?.meta?.author }}</li>
                <li><i class="ri-message-2-line me-2"></i> {{ item?.meta?.comments>10 ? item?.meta?.comments :
                  ('0'+item?.meta?.comments) }}</li>
              </ul>
              <h5 class="my-15 font-18"><a href="javascript:void(0)">{{ item?.title }}</a></h5>
              <p class="mb-15">{{item?.desc}}</p>
              <a href="javascript:void(0)" class="link">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Blog Section-->
</div>
<app-footer></app-footer>