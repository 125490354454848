import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {NgbAlertConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  navigations: Array<string> = ['home', 'features', 'pricing', 'blog', 'faq'];
  contactForm: UntypedFormGroup;
  loader: boolean = false;
  submitted: boolean = false;
  showSuccessMsg: boolean = false;
  successMsg: string = '';
  numrPattern = "^[0-9]+$";
  alertType: string = 'success';
  constructor(private formBuilder: UntypedFormBuilder, private http: HttpClient) { }

  ngOnInit(): void {
    //Initialize form
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(this.numrPattern), Validators.min(10)]],
      message: ['', Validators.required],
    });
  }

  /**
  * Get Form controls
  */
  get form() { return this.contactForm.controls; }

  /**
   * On submit form
   */
  onSubmit(id: string) {
    console.log(this.contactForm)
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }

    this.loader = true;
    let body = new URLSearchParams();
    body.set('name', this.form.name.value);
    body.set('email', this.form.email.value);
    body.set('phone', this.form.phone.value);
    body.set('message', this.form.message.value);
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    this.http.post("assets/php/index.php", body.toString(), {...options, responseType: "text"}).subscribe(data => {
      this.successMsg = data;
      this.showSuccessMsg = true;
      this.loader = false;
      this.resetForm(id);
    }, error => {
      this.alertType = 'danger';
      this.successMsg = "<p class='mb-0'>Unable to submit form. Send mail to us support@lettstartdesign.com</p>";
      this.showSuccessMsg = true;
      this.loader = false;
      this.resetForm(id);
    });
  }

  resetForm(id: string):void {
    setTimeout(() => {
      this.showSuccessMsg = false;
    }, 5000)
    this.contactForm.reset();
    this.contactForm.get('name').clearValidators();
    this.contactForm.get('name').updateValueAndValidity();
    this.contactForm.get('email').clearValidators();
    this.contactForm.get('email').updateValueAndValidity();
    this.contactForm.get('phone').clearValidators();
    this.contactForm.get('phone').updateValueAndValidity();
    this.contactForm.get('message').clearValidators();
    this.contactForm.get('message').updateValueAndValidity();
    const form = document.getElementById(id);
    const elems = form.querySelectorAll('.up');
    elems.forEach((item) => {
      item.classList.remove('up')
    })
  }

  close($event) {
    console.log($event)
  }
}
