import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavlinkService {
  sectionId: BehaviorSubject<string> = new BehaviorSubject('home');
  sectionId$: Observable<string>;
  constructor() { 
    this.sectionId$ = this.sectionId.asObservable()
  }

  getCurrentSectionId(): Observable<string> {
    return this.sectionId$;
  }

  setCurrentSectionId(sectionId: string) {
    this.sectionId.next(sectionId);
  }
}
