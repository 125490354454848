import { Component, OnInit, Input, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginModalComponent implements OnInit {
  loginForm: UntypedFormGroup;
  submitted: boolean = false;
  loader: boolean = false;
  @Input() closeModalClickRef:Function;
  @Input() componentRef: ViewContainerRef; //Template Ref
  constructor(private modalService: NgbModal, private formBuilder: UntypedFormBuilder) { 
  }

  /**
   * Get Form controls
   */
  get form() { return this.loginForm.controls; }

  ngOnInit(): void {
    //Initialize form
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  /**
  * Open signup form
  * @param registercontent content
  */
  navigateSignup(registerContent) {
    this.modalService.dismissAll();
    this.modalService.open(registerContent, { centered: true, windowClass: 'auth-modal', backdrop: 'static' });
  }

  /**
  * Open All modals
  */
  closeModal() {
    this.closeModalClickRef('Close')
  }

  /**
   * @description Reset form
   * @param id form id
   */
  resetForm(id: string): void {
    this.loader = false;
    this.submitted=false;
    this.loginForm.reset();
    this.loginForm.markAsUntouched();
    this.loginForm.clearValidators();
    this.loginForm.clearAsyncValidators();
    const form = document.getElementById(id);
    const elems = form.querySelectorAll('.up');
    elems.forEach((item) => {
      item.classList.remove('up')
      item.classList.remove('invalid-field')
    })
  }

  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loader = true;
    const obj = {
      email: this.form.email.value,
      password: this.form.password.value
    }
    alert(JSON.stringify(obj));
    console.log("form values ===>", obj);
    this.resetForm('login-form')
  }
}
