import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogsdetailsleftsidebar',
  templateUrl: './blogsdetailsleftsidebar.component.html',
  styleUrls: ['./blogsdetailsleftsidebar.component.scss']
})
export class BlogsdetailsleftsidebarComponent implements OnInit {
  navigations: Array<string> = ['home', 'features', 'pricing', 'blog', 'faq'];
  constructor() { }

  ngOnInit(): void {
  }

}
