<app-header [navigations]="navigations" [classes]="'navbar-light'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">

  <!--Home Section-->
  <section class="home home-1 home-3 bg-dark hero-3-bg pt-150" id="home" data-scroll-index="home">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 mb-45 mb-md-0">
          <h1 class="text-white mb-30" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">We love to create <br class="d-none d-lg-block"/> beautiful templates</h1>
          <p class="text-white-50 font-24" data-aos="fade-up" data-aos-duration="400" data-aos-delay="500">Sed ut
            perspiciatis unde omnis iste natus error sit voluptatem accusantium
            doloremque laudantium, totam rem aperiam eaque.</p>
          <div class="mt-30" data-aos="fade-up" data-aos-duration="400" data-aos-delay="600">
            <a href="" class="btn btn-primary me-2">Learn more</a>
            <a href="http://vimeo.com/99025203" class="video-btn btn"><i class="ri-play-fill"></i>Watch
              Video</a>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 offset-lg-2" data-aos="fade-left" data-aos-duration="400"
          data-aos-delay="800">
          <form name="signupForm" [formGroup]="signupForm" (ngSubmit)="onSubmit()" novalidate class="home-3-intro-form">
            <h4 class="text-center"> Register for free </h4>
            <div class="form-group app-label mb-15"
              [ngClass]="{'invalid-field': submitted && form['name'].errors, 'is-valid': submitted && !form['name'].errors}">
              <input type="text" class="form-control" formControlName="name" id="name" floatingLabel />
              <label for="name">Name</label>
              <div *ngIf="submitted && form['name']?.errors" class="invalid-feedback">
                <p>This field is required</p>
              </div>
            </div>
            <div class="form-group app-label mb-15"
              [ngClass]="{'invalid-field': submitted && form['email'].errors, 'is-valid': submitted && !form['email'].errors}">
              <input type="email" class="form-control" formControlName="email" id="registerEmail" floatingLabel />
              <label for="registerEmail">Email Address</label>
              <div *ngIf="submitted && form['email']?.errors?.required" class="invalid-feedback">
                <p>This field is required</p>
              </div>
              <div *ngIf="submitted && form['email']?.errors?.email" class="invalid-feedback">
                <p>Email must be a valid email address</p>
              </div>
            </div>
            <div class="form-group app-label mb-15"
              [ngClass]="{'invalid-field': submitted && form['username'].errors, 'is-valid': submitted && !form['email']?.errors}">
              <input type="text" class="form-control" formControlName="username" id="username" floatingLabel />
              <label for="username">Username</label>
              <div *ngIf="submitted && form['username']?.errors?.required" class="invalid-feedback">
                <p>This field is required</p>
              </div>
            </div>
            <div class="form-group app-label mb-15"
              [ngClass]="{'invalid-field': submitted && form['password']?.errors, 'is-valid': submitted && !form['password'].errors}">
              <input type="password" class="form-control" formControlName="password" id="password" floatingLabel />
              <label for="password">Password</label>
              <div *ngIf="submitted && form['password']?.errors" class="invalid-feedback">
                <p class="font-">This field is required</p>
              </div>
            </div>
            <div class="form-group mb-0 text-center">
              <button class="btn btn-primary btn-sm" type="submit">
                <span *ngIf="loader"><i class="ri-loader-4-line spin h6 text-white"></i></span>
                <span *ngIf="!loader">Start Free</span>
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </section>
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--End Home Section-->

  <!--Features Section-->
  <section class="pt-100 pb-70 position-relative" id="features" data-scroll-index="features">
    <div class="container">
      <div data-aos="fade" data-aos-duration="400" data-aos-delay="400"
        class="section-title text-center">
        <p class="sub">Our App Feature Lists</p>
        <h2 class="mt-0">Great Features</h2>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4 mb-30" *ngFor="let item of featuresList;  let i = index" data-aos="fade-up" [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+200">
          <div class="features-box-2 features-box-2-{{ item?.type }} text-center">
            <div class="avatar-lg mx-auto mb-30">
              <div class="avatar-title font-24 shadow-icon rounded-circle">
                <i class="{{ item?.icon }}"></i>
              </div>
            </div>
            <h5 class="mb-15">{{ item?.title }}</h5>
            <p>{{ item?.desc }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="shape-square-2-left square-rotate-anim tobottom">
      <img src="assets/images/shape-5.png" alt="" class="img-fluid" />
    </div>
  </section>
  <!--End Features Section-->

  <!--Screens Section-->
  <section class="section pt-0" id="screens" data-scroll-index="screens">
    <div class="container-fluid">
      <div class="section-title text-center" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
        <p class="sub">Check Before Choosing</p>
        <h2 class="mt-0">App Screenshots</h2>
      </div><!-- end title -->
      <div class="app-screens" data-aos="fade" data-aos-duration="400" data-aos-delay="500">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide *ngFor="let img of screenshots">
            <div class="item">
              <img [src]="'assets/images/screens/'+img" alt="Awesome Image">
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
      <!-- end div -->
    </div>
  </section>
  <!--End Screens Section-->

  <!--Pricing Section-->
  <app-pricing2 [className]="'pt-100 pb-70 bg-light text-over-bg'" [shapeObj]="shapeImg"></app-pricing2>
  <!--End Pricing Section-->

  <!--Client Section-->
  <section class="section" id="client" data-scroll-index="client">
    <div class="container" data-aos="fade" data-aos-duration="400" data-aos-delay="400">
      <div class="section-title text-center">
        <p class="sub">Testimonials</p>
        <h2>What Our Client Says</h2>
      </div> <!-- end title -->
      <owl-carousel-o [options]="testiOptions" class="pb-30 testi-carousel">
        <ng-template carouselSlide *ngFor="let item of testimonialList">
          <div class="item mb-30">
            <div class="bg-light rounded p-4">
              <p>" I have been using this template for all my company needs for the last 3 years and
                couldn’t be happier with their service and expertise. They’ve surpassed all of my expectations and
                customer service! "</p>
            </div>
            <div class="media pt-4">
              <div class="avatar-lg me-3">
                <div class="avatar-title bg-soft-{{ item?.icon?.type }} text-{{ item?.icon?.type }} rounded-circle"
                  *ngIf="item?.icon">
                  {{ item?.icon?.name }}
                </div>
                <img [src]="'assets/images/'+item?.img" *ngIf="item?.img" alt="testi-user"
                  class="rounded-circle img-fluid d-block me-3">
              </div>
              <div class="media-body align-self-center">
                <h4 class="font-16">{{ item?.author }}</h4>
                <p>{{ item?.desig }}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
    <!-- end container -->
  </section>
  <!--End Client Section-->
</div>
<app-footer></app-footer>