<app-pageheader [navigations]="navigations"></app-pageheader>

<!--Home Section-->
<app-page-banner title="Portoflio Single"></app-page-banner>
<!--End Home Section-->
<section class="section">
  <div class="container">
    <div class="row flex-md-row-reverse pb-80">
      <div class="col-md-6 mb-30">
        <div class="project-details">
          <h4 class="mb-30">Project Summery</h4>
          <p class="mb-15">Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa eum quod et, atque
            expedita ducimus cumque, voluptatibus, fugit eveniet doloremque veniam impedit qui quibusdam aliquid
            accusantium beatae quam! Ea, voluptatibus?</p>
          <p class="mb-15">Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa eum quod et, atque
            expedita ducimus cumque, voluptatibus, fugit eveniet doloremque veniam impedit qui quibusdam aliquid
            accusantium beatae quam! Ea, voluptatibus?</p>
          <ul class="line-list mb-30 list-unstyled">
            <li><strong>Role:</strong><span class="text-end">Design, Front-end, CMS</span></li>
            <li><strong>Client:</strong><span class="text-end">XYZ Coorpation LTD.</span></li>
            <li><strong>Date:</strong><span class="text-end">29-07-2020</span></li>
          </ul>
          <div class="share-portfolio p-30 mb-30 bg-light">
            <label class="mb-0">Share:</label>
            <div class="social-icons social-white">
              <a href="javascript:void(0)" class="fb filled"><i class="ri-facebook-line"></i></a>
              <a href="javascript:void(0)" class="ln filled"><i class="ri-linkedin-line"></i></a>
              <a href="javascript:void(0)" class="dribble filled"><i class="ri-dribbble-line"></i></a>
            </div>
          </div>
          <div class="clearfix">
            <a href="javascript:void(0)" class="btn btn-primary">
              <span class="align-middle me-1">Visit Website</span> 
              <i class="ri-arrow-right-line align-middle"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="portfolio-slider pe-lg-3">
          <owl-carousel-o [options]="customOption" class="portfolio-slider">
            <ng-template carouselSlide>
              <div class="item">
                <img src="assets/images/portfolio/portofolio-1.jpg" class="border-2x" alt="Project Image" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <img src="assets/images/portfolio/portofolio-2.jpg" class="border-2x" alt="Project Image" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <img src="assets/images/portfolio/portofolio-3.jpg" class="border-2x" alt="Project Image" />
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <img src="assets/images/portfolio/portofolio-4.jpg" class="border-2x" alt="Project Image" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section bg-light">
  <div class="container">
    <div class="section-title text-center">
      <p class="sub">Our Portfolio</p>
      <h2>Similar Projects</h2>
    </div>
    <div class="row">
      <div class="col-md-4 mb-30">
        <div class="work-grid-box-1">
          <a href="assets/images/blogs/02.jpg" class="grid-image">
            <img src="assets/images/blogs/02.jpg" alt="Project name">
          </a>
          <div class="grid-content">
            <h4>Dalmia</h4>
            <p>Web Design</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-30">
        <div class="work-grid-box-1">
          <a href="assets/images/blogs/03.jpg" class="grid-image">
            <img src="assets/images/blogs/03.jpg" alt="Project name">
          </a>
          <div class="grid-content">
            <h4>LAMP</h4>
            <p>Branding</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-30">
        <div class="work-grid-box-1">
          <a href="assets/images/blogs/04.jpg" class="grid-image">
            <img src="assets/images/blogs/04.jpg" alt="Project name">
          </a>
          <div class="grid-content">
            <h4>Hunger</h4>
            <p>Graphic Design</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>