<section class="{{ className }}" data-scroll-index="features">
  <div [class]="shapeObj?.class" *ngIf="shapeObj?.top">
    <img [src]="shapeObj?.img" alt="" class="img-fluid" />
  </div>
  <div class="container">
    <div class="section-title text-center" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
      <p class="sub">Our App Feature Lists</p>
      <h2 class="mt-0">Powerful Features</h2>
    </div><!-- end title -->
    <div class="row">
      <div class="col-lg-4">
        <ul class="item-list-right item-list-big list-unstyled">
          <li *ngFor="let item of awesomeFeaturesList | slice: 0:3; let i = index" data-aos="fade-down" [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+200">
            <div class="avatar-lg feature-position-icon">
              <div class="avatar-title bg-soft-primary text-primary h5 shadow-icon rounded-circle">
                {{i<10 ? "0" + (i+1) : i+1 }}
              </div>
            </div>
            <h5 class="mb-2">{{ item?.title }}</h5>
            <p>{{ item?.desc }}</p>
          </li>
        </ul>
      </div>

      <div class="col-lg-4 mb-50 mb-lg-0" data-aos="fade" data-aos-duration="400" data-aos-delay="600">
        <div class="text-center">
          <img class="screen" src="assets/images/mobile-mock-2.png" alt="" width="280">
        </div>
      </div>

      <div class="col-lg-4">
        <ul class="item-list-left item-list-big list-unstyled">
          <li *ngFor="let item of awesomeFeaturesList | slice: 3:awesomeFeaturesList.length+1; let i = index" data-aos="fade-down" [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+200">
            <div class="avatar-lg feature-position-icon">
              <div class="avatar-title bg-soft-primary text-primary h5 shadow-icon rounded-circle">
                {{i<10 ? "0" + (i+4) : i+4 }}
              </div>
            </div>
            <h5 class="mb-2">{{ item?.title }}</h5>
            <p>{{ item?.desc }}</p>
          </li>
        </ul>
      </div>

    </div><!-- row end -->
  </div>
</section>