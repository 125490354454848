import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {ElementRef} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { features } from 'src/app/data/features.data';
import { testi2 } from 'src/app/data/testimonials.data';
import { IFeature } from 'src/app/interfaces/features.interface';
import { Itestimonial } from 'src/app/interfaces/testimonials.interface';
import { NavlinkService } from 'src/app/shared/navlink.service';
import { Observable } from 'rxjs';
import {Router} from "@angular/router"

@Component({
  selector: 'app-home10',
  templateUrl: './home10.component.html',
  styleUrls: ['./home10.component.scss']
})
export class Home10Component implements OnInit {
  currentSection$: Observable<string>;
  @ViewChild('navbar') el:ElementRef<HTMLElement>;
  navigations: Array<string> = ['login', 'home', 'productos', 'clientes', 'contactanos'];
  testiOptions: OwlOptions = {
    items: 1,
    loop: false,
    margin: 24,
    nav: false,
    responsive: {
      640: {
        items: 2
      },
      900: {
        items: 3
      }
    }
  }
  featuresList: IFeature[] = features;
  contactForm: UntypedFormGroup;
  loader: boolean = false;
  submitted: boolean = false;
  showSuccessMsg: boolean = false;
  successMsg: string = '';
  numrPattern = "^[0-9]+$";
  alertType: string = 'success'
  testimonialList: Itestimonial[] = testi2;
  constructor(private navlinkService: NavlinkService, private formBuilder: UntypedFormBuilder, private http: HttpClient, private router: Router) { 
    this.currentSection$ = this.navlinkService.getCurrentSectionId();
  }


  ngOnInit(): void {
    //Initialize form
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(this.numrPattern), Validators.min(10)]],
      website: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  /**
  * Get Form controls
  */
  get form() { return this.contactForm.controls; }

  /**
   * On submit form
   */
  onSubmit(id: string) {
    console.log(this.contactForm)
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }

    this.loader = true;
    let body = new URLSearchParams();
    body.set('name', this.form.name.value);
    body.set('email', this.form.email.value);
    body.set('phone', this.form.phone.value);
    body.set('website', this.form.website.value);
    body.set('message', this.form.message.value);
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    
    this.http.post("assets/php/index.php", body.toString(), { ...options, responseType: "text" }).subscribe({
      next: (data) => {
        this.successMsg = data;
        this.showSuccessMsg = true;
        this.loader = false;
        this.resetForm(id);
      },
      error: (error) => {
        this.alertType = 'danger';
        this.successMsg = "<p class='mb-0'>Unable to submit form. Send mail to us support@lettstartdesign.com</p>";
        this.showSuccessMsg = true;
        this.loader = false;
        this.resetForm(id);
      }
    });
  }

  resetForm(id: string): void {
    setTimeout(() => {
      this.showSuccessMsg = false;
    }, 5000)
    this.submitted=false;
    this.contactForm.reset();
    this.contactForm.markAsUntouched();
    this.contactForm.clearValidators();
    this.contactForm.clearAsyncValidators();
    const form = document.getElementById(id);
    const elems = form.querySelectorAll('.up');
    elems.forEach((item) => {
      item.classList.remove('up')
      item.classList.remove('invalid-field')
    })
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.navlinkService.setCurrentSectionId(sectionId);
  }

  public triggerScrollTo(index) {
    window.scrollTo({
      top: document.getElementById(this.navigations[index]).offsetTop - 30,
      left: 0,
      behavior: 'smooth'
    });
    this.navlinkService.setCurrentSectionId(this.navigations[index]);
  }

  public comenzar(){
    this.router.navigate(['login'])
  }
}
