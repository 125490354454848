import { Component, Input, OnInit } from '@angular/core';
import { prices } from 'src/app/data/pricings.data';
import { IPrice, IShape } from 'src/app/interfaces/pricings.interface';


@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  @Input() className: string;
  @Input() shapeObj?: IShape;
  priceList: IPrice[] = prices;
  constructor() { }

  ngOnInit(): void {
  }

}
