<app-header [navigations]="navigations" [classes]="'navbar-light'" [rightBar]="true" (loginClick)="openLoginModal(loginContent)"></app-header>
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
	<!--Home Section-->
	<section class="home home-1 bg-blue mh-100vh" id="home" data-scroll-index="home">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6">
					<div class="text-center text-lg-start mb-45 mb-lg-0">
						<h1 class="text-white mb-30" data-aos="fade-up" data-aos-duration="400" data-aos-delay="500">We love to
							create creative beautiful templates</h1>
						<p class="text-white opacity-8 mb-45 font-24" data-aos="fade-up" data-aos-duration="400" data-aos-delay="600">You can combine all the templates into a single one, you can take a component from the application theme and use it in the website.</p>
						<div class="d-flex justify-content-center justify-content-lg-start" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
							<a href="javascript:void(0)" class="btn btn-primary mb-15 me-15">Get Started</a>
							<a href="javascript:void(0)" class="btn btn-outline-white mb-15">Know More</a>
						</div>
					</div>
				</div>
				<div class="col-lg-6 ps-lg-5" data-aos="fade-left" data-aos-duration="400" data-aos-delay="1000">
					<img src="assets/images/home/home1.png" class="img-fluid" alt="shape">
				</div>
			</div>
		</div>
		<div class="home-shape">
			<div class="shape1">
				<img src="assets/images/shape/1.png" alt="shape">
			</div>
			<div class="shape2">
				<img src="assets/images/shape/2.png" alt="shape">
			</div>
			<div class="shape3">
				<img src="assets/images/shape/3.png" alt="shape">
			</div>
			<div class="shape4">
				<img src="assets/images/shape/4.png" alt="shape">
			</div>
			<div class="shape5">
				<img src="assets/images/shape/5.png" alt="shape">
			</div>
			<div class="shape6">
				<img src="assets/images/shape/6.png" alt="shape">
			</div>
		</div>
	</section>
	<!--End Home Section-->

	<!--Features List-->
	<section class="pt-100 pb-70" id="features" data-scroll-index="features">
		<div class="container">
			<div class="section-title text-center" data-aos="fade" data-aos-duration="400" data-aos-delay="400">
				<p class="sub">Our App Feature Lists</p>
				<h2>Great Features</h2>
			</div> <!-- end section -->
			<div class="row">
				<div *ngFor="let item of featuresList; let i = index;" class="col-md-6 col-lg-4 mb-45" data-aos="fade-up" [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+400">
					<div class="features-box features-box-{{ item?.type }}">
						<div class="remix-icon">
							<i class="{{ item?.icon }} bg-soft-{{ item?.type }} text-{{ item?.type }}"></i>
						</div>
						<h5 class="mt-30 mb-15">{{ item?.title }}</h5>
						<p>{{ item?.desc }}</p>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--Features List End-->

	<!--Extra Features-->
	<section class="section bg-light more-features pb-200" data-scroll-index="features">
		<div class="shape-bg-top-left" style="background-image: url(assets/images/shape-3.png)"></div>
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6 mb-30" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
					<div class="mobile-mock text-center text-lg-start">
						<img src="assets/images/mobile-mock.png" class="img-fluid" alt="mobile mock" />
					</div>
				</div>
				<div class="col-lg-6 mb-30 ps-lg-5" data-aos="fade-left" data-aos-duration="500" data-aos-delay="500">
					<div class="section-title mb-40">
						<p class="sub">Our App Feature Lists</p>
						<h2 class="mt-0">Powerful App for <br />Your Mobile</h2>
					</div>
					<div class="sub-feature mb-30">
						<div class="media flex-grow-1 mb-15 mb-sm-0">
							<div class="remix-icon">
								<i class="ri-device-line"></i>
							</div>
							<div class="media-body align-self-center ms-15">
								<h5>Responsive <br class="d-none d-lg-inline-block" />Design</h5>
							</div>
						</div>
						<div class="media flex-grow-1">
							<div class="remix-icon">
								<i class="ri-global-line"></i>
							</div>
							<div class="media-body align-self-center ms-15">
								<h5>Online <br class="d-none d-lg-inline-block" />Marketing</h5>
							</div>
						</div>
					</div>
					<p class="mb-15">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla
						pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
					<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
						eufugiat nulla
						pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
					<div class="mt-15">
						<a class="btn btn-outline-dark text-start mt-15 me-2" href="javascript:void(0)">
							<div class="media align-items-center">
								<i class="ri-apple-fill fs-4 me-2 my-0"></i>
								<div class="media-body">
									<strong class="d-block">Download App</strong> <span class="text-capitalize">from
										Apple Store</span>
								</div>
							</div>
						</a>
						<a class="btn btn-outline-dark text-start mt-15" href="javascript:void(0)">
							<div class="media align-items-center">
								<i class="ri-android-fill fs-4 me-2 my-0"></i>
								<div class="media-body">
									<strong class="d-block">Download App</strong> <span class="text-capitalize">from
										play Store</span>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>

		</div>
	</section>
	<!--End Extra Features-->

	<!--Video Section-->
	<section class="section pt-0 video-one mt--100" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400"
		data-scroll-index="features">
		<div class="container">
			<div class="video-bg position-relative bg-1">
				<div class="bg-overlay bg-overlay-gradient opacity-8"></div>
				<div class="video-content text-over-bg">
					<a href="https://www.youtube.com/watch?v=Kl5B6MBAntI" class="video-btn-circle circle-anim mb-50">
						<i class="ri-play-line font-48"></i>
					</a>
					<div class="video-title">
						<h2 class="h1 text-white">Watch Our Video</h2>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--End Video Section-->

	<!--Pricing Section-->
  <app-pricing [className]="'pb-70 position-relative'" [shapeObj]="shapeImg"></app-pricing>
	<!--End Pricing Section-->

	<!--Faq Section-->
	<section class="section bg-light position-relative" id="faq" data-scroll-index="faq">
		<div class="shape-bg-bottom-right" style="background-image: url(assets/images/shape-4.png)"></div>
		<div class="container text-over-bg">
			<div class="section-title text-center" data-aos="fade" data-aos-duration="400" data-aos-delay="400">
				<p class="sub">Frequently Asked Questions</p>
				<h2>Have Any Question?</h2>
			</div> <!-- end section -->
			<div class="accordion" id="accordion1" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
				<ngb-accordion [closeOthers]="true" (panelChange)="changeTitle($event)" class="accordion" activeIds="ba0">
					<ngb-panel *ngFor="let item of faqList;let i = index" 
						[cardClass]="(activeAccordian.panelId === 'ba'+i && activeAccordian.nextState) ? 'active' : ''"
						[id]="'ba'+i">
						<ng-template ngbPanelTitle>
							<h6>
								{{ item?.head }}
							</h6>
						</ng-template>
						<ng-template ngbPanelContent>
							Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
							wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
							eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
							assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
							sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
							farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
							labore sustainable VHS.
						</ng-template>
					</ngb-panel>
				</ngb-accordion>
			</div>
		</div>
	</section>
	<!--End Faq Section-->

	<!-- Client Section -->
	<section class="section" id="client" data-scroll-index="client">
		<div class="container">
			<div class="text-center section-title" data-aos="fade" data-aos-duration="400" data-aos-delay="400">
				<p class="sub">Testimonials</p>
				<h2 class="title">What Our Client Says</h2>
			</div> <!-- end section -->
			<div class="row justify-content-center" data-aos="fade" data-aos-duration="400" data-aos-delay="400">
				<div class="col-lg-8 text-center">
					<owl-carousel-o [options]="testimonialOptions">
						<ng-template carouselSlide *ngFor="let item of testimonialList.slice(0, 3)">
							<div class="item pb-50">
								<h5 class="testi-text">Fusce dapibus tellus ac cursus commodo, tortor mauris condimentum nibh ut fermentum massa, justo sit amet vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed
								</h5>
								<div class="mt-30">
									<img src="assets/images/{{ item?.img }}" alt="testi-user" class="rounded-circle mx-auto d-block testi-img">
								</div>
								<h4 class="mt-15 font-18 mb-1">{{ item?.author }}</h4>
								<h6 class="font-14">{{ item?.desig }}</h6>
							</div>
						</ng-template>
					</owl-carousel-o>
				</div>
			</div>
		</div>
		<!-- end container -->
	</section>
	<!-- End Client Section -->
</div>
<app-footer></app-footer>

<ng-template #accordionContent>
	Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
	wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum
	eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
	assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
	sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
	farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
	labore sustainable VHS.
</ng-template>

<ng-template #loginContent let-c="close" let-loginModal="dismiss">
  <app-login-modal [componentRef]="registerContent" [closeModalClickRef]="loginModal"></app-login-modal>
</ng-template>

<ng-template #registerContent let-c="close" let-signupModal="dismiss">
  <app-signup-modal [componentRef]="loginContent"></app-signup-modal>
</ng-template>