import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[floatingLabel]'
})
export class AppLabelDirective {
  constructor(private el: ElementRef) { }

  @HostListener('change') onChange() {
    this.changeInput();
  }

  private changeInput() {
    const target = (this.el.nativeElement) as HTMLInputElement;
    if (target.value !== "") {
      target?.parentElement?.classList.add("up");
    }
    else {
      target?.parentElement?.classList.remove("up");
    }
  }
}