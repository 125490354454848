import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team-single',
  templateUrl: './team-single.component.html',
  styleUrls: ['./team-single.component.scss']
})
export class TeamSingleComponent implements OnInit {

  navigations: Array<string> = ['home', 'features', 'pricing', 'blog', 'faq'];
  constructor() { }

  ngOnInit(): void {
  }

}
