<app-pageheader [navigations]="navigations"></app-pageheader>
<!--Home Section-->
<app-page-banner title="Blogs"></app-page-banner>
<!--End Home Section-->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-4 mb-30" *ngFor="let item of blogList | slice: (paginationSetting.currentPage-1) * paginationSetting.pageSize : (paginationSetting.currentPage-1) * paginationSetting.pageSize + paginationSetting.pageSize; let i = index" data-aos="fade-up"
        [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+200">
        <div class="blog box-shadow">
          <img [src]="'assets/images/blogs/'+item?.img" class="img-fluid" alt="blog" />
          <div class="blog-body">
            <ul class="meta list-unstyled">
              <li><i class="ri-calendar-2-line me-2"></i> {{ item?.meta?.date }}</li>
              <li><i class="ri-user-2-line me-2"></i> {{ item?.meta?.author }}</li>
              <li><i class="ri-message-2-line me-2"></i> {{ item?.meta?.comments>10 ? item?.meta?.comments :
                ('0'+item?.meta?.comments) }}</li>
            </ul>
            <h5 class="my-15 font-18"><a href="javascript:void(0)">{{ item?.title }}</a></h5>
            <p class="mb-15">{{item?.desc}}</p>
            <a href="javascript:void(0)" class="link">Read More</a>
          </div>
        </div>
      </div>
    </div>
    <ngb-pagination class="pagination justify-content-center flat-rounded-pagination my-30"
      [collectionSize]="blogList.length" [(page)]="paginationSetting.currentPage"
      [pageSize]="paginationSetting.pageSize" [rotate]="true" [ellipses]="false">
      <ng-template ngbPaginationPrevious><i class="ri-arrow-left-s-line"></i></ng-template>
      <ng-template ngbPaginationNext><i class="ri-arrow-right-s-line"></i></ng-template>
      <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</section>
<app-footer></app-footer>