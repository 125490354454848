<app-pageheader [navigations]="navigations"></app-pageheader>
<!--Home Section-->
<app-page-banner title="Blog Post"></app-page-banner>
<!--End Home Section-->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-10">
        <div class="blogs-details">
          <div class="blog-image mb-md-5">
            <img src="assets/images/blogs/blog-post.jpg" alt="blog img" class="img-fluid">
            <div class="blog-header">
              <h1 class="title text-shadow mb-4 h2">Can you work everywhere</h1>
              <ul class="list-inline text-uppercase font-size-15 font-weight-medium mb-2">
                <li class="list-inline-item me-3">
                  <i class="ri-calendar-2-line text-primary align-middle me-1"></i>
                  <span class="align-middle">May 25, 2020</span>
                </li>
                <li class="list-inline-item me-3">
                  <i class="ri-user-2-line text-primary align-middle me-1"></i>
                  <span class="align-middle">Admin</span>
                </li>
                <li class="list-inline-item">
                  <i class="ri-message-2-line text-primary align-middle me-1"></i>
                  <span class="align-middle">250</span>
                </li>
              </ul>
            </div>
          </div>
          <!--Image end-->
          <div class="row justify-content-end">
            <div class="col-xl-10">
              <app-blogcontent></app-blogcontent>
              <!--content end-->
            </div>
            <!--Col end-->
          </div>
          <!--Row end-->
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>