<nav id="navbar" #navbar class="navbar navbar-expand-lg navbar-default navbar-fixed {{classes}}" (window:scroll)="windowScroll()" >
    <div class="container">
        <!-- LOGO -->
        <a class="navbar-brand logo" routerLink="/">
            <span class="dot"></span>GREENLAKE
        </a>
        
        <button type="button" class="navbar-toggler" [ngClass]="{'collapsed': isCollapsed}" (click)="toggle()" [attr.aria-expanded]="!isCollapsed"
            aria-controls="navbarCollapse" aria-label="Toggle navigation">
            <span></span>
            <span></span>
            <span></span>
        </button>
        <div class="navbar-collapse" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" id="navbarCollapse">
            <ul class="nav navbar-nav ms-auto navbar-center" id="mySidenav">
                <li class="nav-item" *ngFor="let nav of navigations; let i = index">
                    <!--<a href="javascript: void(0);" *ngIf="nav==='login'; else nologin" [routerLink]="['/login']" [ngClass]="{'active':(currentSection$ | async) === nav}" class="nav-link">{{nav}}</a>
                    <ng-template #nologin>-->
                        <a href="javascript: void(0);" (click)="triggerScrollTo(i)" [ngClass]="{'active':(currentSection$ | async) === nav}" class="nav-link">{{nav}}</a>
                    <!--</ng-template>-->    
                </li>
            </ul>
        </div>
        <div class="right-bar ms-30" *ngIf="rightBar">
            <button class="btn btn-primary btn-sm shadow-none login-btn" (click)="openLoginModal()">
                <span class="d-none d-md-block">Login</span>
                <i class="ri-user-line d-block d-md-none"></i>
            </button>
        </div>
    </div>
</nav><!--End Header-->