<app-header [navigations]="navigations" [classes]="'navbar-light'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
  <!--Home Section-->
  <section class="home home-1 home-4 bg-dark" id="home" data-scroll-index="home">
    <div class="container">
      <div class="row text-center text-lg-start align-items-center">
        <div class="col-lg-6 mb-45 mb-lg-0">
          <h1 class="text-white mb-30 mt-lg-5" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">We love to create beautiful <span class="text-gred" id="typewriter"></span></h1>
          <p class="text-white opacity-8 mb-45 font-24" data-aos="fade-up" data-aos-duration="500" data-aos-delay="400">We carefully consider our solutions to support each and every stage of your growth.</p>
          <div class="subscribe-form" data-aos="fade-up" data-aos-offset="0" data-aos-duration="400" data-aos-delay="600">
            <form action="#">
              <input type="text" placeholder="Email">
              <button type="submit"><i class="ri-send-plane-2-line"></i></button>
            </form>
          </div>
        </div> <!-- end col -->
        <div class="col-lg-5 offset-lg-1 text-over-bg" data-aos="fade-left" data-aos-duration="400" data-aos-delay="800">
          <div class="home-4-outer-section video-btn-over">
            <img src="assets/images/home/hero-4.jpg" class="img-fluid border-radius-2x" alt=""/>
            <a href="https://www.youtube.com/watch?v=Kl5B6MBAntI" class="video-btn-circle circle-anim-2 bg-info text-white">
              <i class="ri-play-line font-48"></i>
            </a>
          </div>
        </div>
      </div> <!-- end row -->
    </div> <!-- end container -->
  </section>
  <!--End Home Section-->

  <!--Features Section-->
  <section class="pt-100 pb-70" id="features" data-scroll-index="features">
    <div class="container">
      <div class="section-title text-center" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
        <p class="sub">Our App Feature Lists</p>
        <h2>Great Features</h2>
      </div><!-- end title -->
      <div class="row">
        <div class="col-md-6 col-lg-4 mb-30" *ngFor="let item of featuresList;  let i = index" data-aos="fade-up" [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+200">
          <div class="features-box-3 mt-30 bg-light card shadow-none">
            <div class="card-body p-30">
              <div class="avatar-xl feature-position-icon">
                <div class="avatar-title bg-soft-primary text-primary h5 shadow-icon mt-0 rounded-circle">
                 {{i<10 ? "0" + (i+1) : i+1 }}
                </div>
              </div>
              <h3 class="mt-25 mb-15">{{ item?.title }}</h3>
              <p class="w-100 mb-0">{{ item?.desc.substr(0, 80) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Features Section-->

  <!--Extra Feature Section-->
  <app-powerfulfeatures className="section pt-0 position-relative" [shapeObj]="shapeImg"></app-powerfulfeatures>
  <!--End Extra Feature Section-->

  <!--Pricing Section-->
  <section class="pt-100 pb-70 bg-light text-over-bg" id="pricing" data-scroll-index="pricing">
    <div class="shape-bg-top-left" style="background-image: url(assets/images/shape-3.png)"></div>
    <div class="container">
      <div class="section-title text-center" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
        <p class="sub">Choose Our Pricing</p>
        <h2 class="title">Our Pricing Plans</h2>
      </div><!-- end title -->
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="row">
            <!--Pricing Column-->
            <div *ngFor="let item of priceList; let i = index" data-aos="fade-up" [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+400" class="pricing-column col-md-6 col-lg-4 mb-30">
              <div class="card border-0 inner-box box-shadow">
                <div class="card-body p-0">
                  <div class="plan-header mb-30 ps-2">
                    <div class="media">
                      <div class="avatar-lg me-3">
                        <div class="avatar-title h5 m-0 shadow-icon rounded-circle" [ngClass]="{'bg-primary text-white': item?.recommended, 'bg-soft-primary text-primary': !item?.recommended}">
                          <i class="{{ icons[i] }}"></i>
                        </div>
                      </div>
                      <div class="media-body align-self-center">
                        <h3 class="plan-title m-0">{{ item?.type }}</h3>
                      </div>
                    </div>
                  </div>
                  <h3>${{ item?.price }} / <span class="plan-duration">Per Month</span></h3>
                  <ul class="plan-stats list-unstyled">
                    <li *ngFor="let featureIem of item?.planFeatures"> <i
                        class="ri-arrow-right-s-line text-primary me-2"></i>
                        {{featureIem?.text }}</li>
                  </ul>
                  <div class="d-grid mt-30">
                    <a href="javascript:void(0)" *ngIf="item?.recommended;else not_recommended" class="btn btn-sm btn-primary">Buy Now</a>
                    <ng-template #not_recommended>
                      <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Buy Now</a>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div><!--end Pricing Column-->
          </div><!-- end row -->
        </div><!-- end col -->
      </div><!-- end row -->
    </div><!-- end container -->
  </section>
  <!--End Pricing Section-->

  <!--Screens Section-->
  <section class="section" id="screens" data-scroll-index="screens">
    <div class="container-fluid">
      <div class="section-title text-center" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
        <p class="sub">Check Before Choosing</p>
        <h2 class="mt-0">App Screenshots</h2>
      </div>
      <div class="app-screens" data-aos="fade" data-aos-duration="400" data-aos-delay="500">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide *ngFor="let img of screenshots">
            <div class="item">
              <img [src]="'assets/images/screens/'+img" alt="Awesome Image">
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
      <!-- end row -->
    </div>
    <div class="shape-square-left square-dot-anim tobottom"><img src="assets/images/shape-2.png" alt="" class="img-fluid"></div>
  </section>
  <!--End Screens Section-->

  <!--Faq Section-->
  <section class="section pt-50" id="faq" data-scroll-index="faq">
    <div class="container">
      <div class="section-title text-center" data-aos="fade-up" data-aos-duration="400" data-aos-delay="400">
        <p class="sub">Frequently Asked Questions</p>
        <h2 class="title">Have Any Question?</h2>
      </div> <!-- end title -->
      <div class="row">
        <div class="col-md-6" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="question-box mb-45" *ngFor="let item of faqList | slice:0:2">
            <div class="media">
              <div class="avatar-md me-3">
                <div class="avatar-title bg-soft-primary text-primary m-0 shadow-icon rounded-circle">
                  Q
                </div>
              </div>
              <div class="media-body ms-2">
                <h6 class="mb-15">{{ item?.head }}</h6>
                <p>Lorem ipsum dolor sit amet, in mea
                  nonumes dissentias dissentiunt, pro te solet iriure. Cu sit consetetur moderatius
                  intellegam, ius decore accusamus Mutat convenirete.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6" data-aos="fade-left" data-aos-duration="400" data-aos-delay="500">
          <div class="question-box mb-45" *ngFor="let item of faqList | slice:3:5">
            <div class="media">
              <div class="avatar-md me-3">
                <div class="avatar-title bg-soft-primary text-primary m-0 shadow-icon rounded-circle">
                  Q
                </div>
              </div>
              <div class="media-body ms-2">
                <h6 class="mb-15">{{ item?.head }}</h6>
                <p>Lorem ipsum dolor sit amet, in mea
                  nonumes dissentias dissentiunt, pro te solet iriure. Cu sit consetetur moderatius
                  intellegam, ius decore accusamus Mutat convenirete.</p>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
  <!--End Faq Section-->
</div>
<app-footer></app-footer>