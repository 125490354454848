<!-- <app-header id="prueba" [navigations]="navigations" [classes]="'navbar-dark'"></app-header> -->

<div class="auth-pages bg-light">
	<!-- <ngb-alert *ngIf="showSuccessMsg" [type]="alertType" [innerHTML]="successMsg"></ngb-alert> -->

	<div class="container">
		<div class="row justify-content-center">
			<div class="col-xl-11">
				<div class="row justify-content-center">
					<div class="col-md-6 pe-md-0">
						<div class="auth-page-sidebar">
							<div class="bg-overlay opacity-6"></div>
							<div class="auth-user-testimonial">
								<owl-carousel-o [options]="customOptions">
									<ng-template carouselSlide>
										<div class="item">
											<!--<h3 class="text-white mb-1">I love this theme!</h3>-->
											<h5 class="text-white mb-3">¡Bienvenido(a)!</h5>
											<!--<p>- Admin User</p>-->
										</div>
									</ng-template>
									<ng-template carouselSlide>
										<div class="item">
											<!--<h3 class="text-white mb-1">I love this theme!</h3>-->
											<h5 class="text-white mb-3">Esperamos que disfrutes nuestros productos</h5>
											<!--<p>- Admin User</p>-->
										</div>
									</ng-template>
								</owl-carousel-o>
							</div>
						</div>
					</div>
					<div class="col-md-6 ps-md-0">
						<div class="card mb-0 p-2 p-md-3">
							<div class="card-body">
								<div class="clearfix">
									<a class="navbar-brand logo" routerLink="/">
										<span class="dot"></span>GREENLAKE
									</a>
								</div>
								<!--<h5 class="mt-30 mb-1 font-weight-600">Welcome back!</h5>-->


								<div [ngClass]="{'hide': formulario_seleccionado!='codigo_reseteo'}">

									<p class="text-muted">Ingresa el codigo de validacion y tu nuevo password.<br><br>
									</p>
									<form name="restablecer_password_codigo_form"
										[formGroup]="restablecer_password_codigo_form"
										(ngSubmit)="confirmar_nuevo_password_codigo()">

										<div class="form-group app-label mb-30"
											[ngClass]="{'invalid-field': submitted && form_recuperar_password_codigo.codigo.errors, 'is-valid': submitted && !form_recuperar_password_codigo.codigo.errors}">
											<input type="text" class="form-control" formControlName="codigo" id="codigo"
												floatingLabel />
											<label for="codigo">Codigo</label>
											<div *ngIf="submitted && form_recuperar_password_codigo.codigo?.errors"
												class="invalid-feedback">
												<p>Este campo es requerido</p>
											</div>
										</div>

										<div class="text-muted mb-15">
											Debe cumplir con las siguientes condiciones:
											<p class="text-muted text-08em"
												[ngClass]="{'red': form_recuperar_password_codigo.new_password_codigo.errors?.minlength}">
												*Mínimo 8 caracteres</p>
											<p class="text-muted text-08em"
												[ngClass]="{'red': form_recuperar_password_codigo.new_password_codigo.errors?.hasLowercase}">
												*Debe tener al menos una letra minúscula</p>
											<p class="text-muted text-08em"
												[ngClass]="{'red': form_recuperar_password_codigo.new_password_codigo.errors?.hasUppercase}">
												*Debe tener al menos una letra mayúscula</p>
											<p class="text-muted text-08em"
												[ngClass]="{'red': form_recuperar_password_codigo.new_password_codigo.errors?.hasNumber}">
												*Debe tener al menos un número</p>
											<p class="text-muted text-08em"
												[ngClass]="{'red': form_recuperar_password_codigo.new_password_codigo.errors?.hasSpecialCharacter}">
												*Debe tener al menos un carácter especial</p>
										</div>


										<div class="form-group app-label mb-30"
											[ngClass]="{'invalid-field': submitted && form_recuperar_password_codigo.password.errors, 'is-valid': submitted && !form_recuperar_password_codigo.password.errors}">
											<input type="password" class="form-control"
												formControlName="new_password_codigo" id="new_password_codigo"
												floatingLabel />
											<label for="new_password_codigo">Password</label>
											<div *ngIf="submitted && form_recuperar_password_codigo.new_password_codigo?.errors"
												class="invalid-feedback">
												<p>Este campo es requerido</p>
											</div>
										</div>

										<div class="form-group app-label mb-30"
											[ngClass]="{'invalid-field': (submitted && form_recuperar_password_codigo.password_repeat_codigo.errors), 'is-valid': submitted && !form_recuperar_password_codigo.password_repeat_codigo.errors}">
											<input type="password" class="form-control"
												formControlName="password_repeat_codigo" id="password_repeat_codigo"
												floatingLabel />
											<label for="password_repeat_codigo">Confirme Password</label>
											<div *ngIf="submitted && form_recuperar_password_codigo.password_repeat_codigo?.errors"
												class="invalid-feedback">
												<p>Este campo debe ser igual al anterior</p>
											</div>
										</div>


										<div class="d-grid mt-30 mb-15">
											<button class="btn btn-primary btn-block"
												[disabled]="restablecer_password_codigo_form.invalid" type="submit">
												<span *ngIf="loader"><i
														class="ri-loader-4-line spin h6 text-white"></i></span>
												<span *ngIf="!loader">Confirmar</span>
											</button>
										</div>

									</form>

								</div>

								<div [ngClass]="{'hide': formulario_seleccionado!='login'}">

									<p class="text-muted mb-15">Ingresa tu correo y password para acceder.<br><br></p>
									<form name="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
										<div class="form-group app-label mb-30"
											[ngClass]="{'invalid-field': submitted && form.email.errors, 'is-valid': submitted && !form.email.errors}">
											<input type="text" class="form-control" formControlName="email" 
												id="loginEmail" floatingLabel #loginEmail/>
											<label for="loginEmail">Usuario</label>
											<div *ngIf="submitted && form.email?.errors?.required"
												class="invalid-feedback">
												<p>Este campo es requerido</p>
											</div>
											<div *ngIf="submitted && form.email?.errors?.email"
												class="invalid-feedback">
												<p>Tu email debe ser una dirección valida</p>
											</div>
										</div>
										<div class="form-group app-label mb-30"
											[ngClass]="{'invalid-field': submitted && form.password.errors, 'is-valid': submitted && !form.password.errors}">
											<input type="password" class="form-control" formControlName="password"
												id="password" floatingLabel/>
											<label for="password">Password</label>
											<div *ngIf="submitted && form.password?.errors" class="invalid-feedback">
												<p>Este campo es requerido</p>
											</div>
										</div>


										<!-- <div class="form-group d-flex justify-content-between flex-wrap">
											<div class="form-check mb-0">
												<input type="checkbox" class="form-check-input" id="checkbox-signin"
													checked>
												<label class="form-check-label" for="checkbox-signin">Recuerdame</label>
											</div>
											<div class="text-end">
												<a (click)="solicitar_nuevo_password()" class="link"
													style="cursor: pointer;">Olvidaste tu password?</a>
											</div>
										</div> -->

										<div class="d-grid mt-30 mb-15">

											<div [ngClass]="{'hide': !error_on_login}" class="alert alert-danger" role="alert">
												Su usuario o password son incorrectos.
											  </div>

											<button class="btn btn-primary btn-block" [disabled]="form.invalid"
												type="submit">
												<span *ngIf="loader"><i
														class="ri-loader-4-line spin h6 text-white"></i></span>
												<span *ngIf="!loader">Ingresar</span>
											</button>

										</div>

									</form>

								</div>

								<div [ngClass]="{'hide': formulario_seleccionado!='restablecer_password'}">

									<p class="text-muted">Ingresa tu nuevo password.<br><br></p>
									<form name="restablecer_password_form" [formGroup]="restablecer_password_form"
										(ngSubmit)="confirmar_nuevo_password()">

										<div class="text-muted mb-15">
											Debe cumplir con las siguientes condiciones:
											<p class="text-muted text-08em"
												[ngClass]="{'red': form_recuperar_password.new_password.errors?.minlength}">
												*Mínimo 8 caracteres</p>
											<p class="text-muted text-08em"
												[ngClass]="{'red': form_recuperar_password.new_password.errors?.hasLowercase}">
												*Debe tener al menos una letra minúscula</p>
											<p class="text-muted text-08em"
												[ngClass]="{'red': form_recuperar_password.new_password.errors?.hasUppercase}">
												*Debe tener al menos una letra mayúscula</p>
											<p class="text-muted text-08em"
												[ngClass]="{'red': form_recuperar_password.new_password.errors?.hasNumber}">
												*Debe tener al menos un número</p>
											<p class="text-muted text-08em"
												[ngClass]="{'red': form_recuperar_password.new_password.errors?.hasSpecialCharacter}">
												*Debe tener al menos un carácter especial</p>
										</div>


										<div class="form-group app-label mb-30"
											[ngClass]="{'invalid-field': submitted && form_recuperar_password.new_password.errors, 'is-valid': submitted && !form_recuperar_password.new_password.errors}">
											<input type="password" class="form-control" formControlName="new_password"
												id="new_password" floatingLabel />
											<label for="new_password">Password</label>
											<div *ngIf="submitted && form_recuperar_password.new_password?.errors"
												class="invalid-feedback">
												<p>Este campo es requerido</p>
											</div>
										</div>

										<div class="form-group app-label mb-30"
											[ngClass]="{'invalid-field': (submitted && form_recuperar_password.password_repeat.errors), 'is-valid': submitted && !form_recuperar_password.password_repeat.errors}">
											<input type="password" class="form-control"
												formControlName="password_repeat" id="password_repeat" floatingLabel />
											<label for="password_repeat">Confirme Password</label>
											<div *ngIf="submitted && form_recuperar_password.password_repeat?.errors"
												class="invalid-feedback">
												<p>Este campo debe ser igual al anterior</p>
											</div>
										</div>


										<div class="d-grid mt-30 mb-15">
											<button class="btn btn-primary btn-block"
												[disabled]="restablecer_password_form.invalid" type="submit">
												<span *ngIf="loader"><i
														class="ri-loader-4-line spin h6 text-white"></i></span>
												<span *ngIf="!loader">Confirmar</span>
											</button>
										</div>

									</form>

								</div>


							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end row -->
	</div>
	<!-- end container -->
</div>