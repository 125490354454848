<section class="section bg-light" data-aos="fade" data-aos-duration="400" data-aos-delay="400" id="client" data-scroll-index="client">
  <div class="shape-bg-bottom-right" style="background-image: url(assets/images/shape-4.png)"></div>
  <div class="container">
    <div class="section-title text-center">
      <p class="sub">Testimonials</p>
      <h2 class="title">What Our Client Says</h2>
    </div> <!-- end title -->
    <owl-carousel-o [options]="testimonialOptions" class="testimonial-slider-2">
      <ng-template carouselSlide *ngFor="let item of testimonialList">
        <div class="item bg-white">
          <p>Repellat dignissimos libero. Qui sed at corrupti expedita voluptas odit. Nihil ea
            quia nesciunt. Repellat dignissimos libero.</p>
          <div class="mt-30 mb-15">
            <img src="assets/images/{{ item?.img }}" alt="testi-user" class="rounded-circle mx-auto d-block testi-img">
          </div>
          <h4 class="font-18 mb-1">{{ item?.author }}</h4>
          <h6>{{ item?.desig }}</h6>
        </div>
      </ng-template><!-- end slide -->
    </owl-carousel-o><!-- end carousel container -->
  </div><!-- end container -->
</section>