import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { NavlinkService } from '../navlink.service';
import {ElementRef} from '@angular/core';
import {Router} from "@angular/router";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public isCollapsed = true;
  currentSection$: Observable<string>;
  @Input() rightBar:boolean = false;
  @Input() navigations: Array<string> = [];
  @Input() classes: string = '';
  @Output() loginClick: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('navbar') el:ElementRef<HTMLElement>;
  constructor(private navlinkService: NavlinkService, private router: Router, private route: ActivatedRoute) { 
    this.currentSection$ = this.navlinkService.getCurrentSectionId();
  }

  ngOnInit(): void {
  }

  /**
   * Window scroll method
   */
  windowScroll() {
    const navbar: HTMLElement | null | undefined = document.getElementById('navbar');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
      navbar?.classList.add('sticky');
    } else {
      navbar?.classList.remove('sticky');
    }
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }
  /**
   * Scroll to section
   * @param index specify the current nav index
   */
  public triggerScrollTo(index) {
    this.navlinkService.setCurrentSectionId(this.navigations[index]);
    if(index!=0){

      console.log(this.route.snapshot.url)
      if(this.route.snapshot.url.toString() == 'login'){
        this.navlinkService.setCurrentSectionId(this.navigations[1]);
        this.router.navigate(['index-10'])
        return
      }
      const headerHeight = this.el.nativeElement.getBoundingClientRect().height;
      window.scrollTo({
        top: document.getElementById(this.navigations[index]).offsetTop - headerHeight+10,
        left: 0,
        behavior: 'smooth'
      });
    }else{
      this.router.navigate(['login'])
    }
  }

  openLoginModal(){
    //if(isLogginIn==0)
      this.loginClick.emit(true)  
  }
}
