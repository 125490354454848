import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, 
  ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { PagesRoutingModule } from './pages-routing.module';
import { SharedModule } from '../shared/shared.module';
import { Home2Component } from './home2/home2.component';
import { Home3Component } from './home3/home3.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { Home4Component } from './home4/home4.component';
import { Home5Component } from './home5/home5.component';
import { Home6Component } from './home6/home6.component';
import { Home7Component } from './home7/home7.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Home8Component } from './home8/home8.component';
import { Home9Component } from './home9/home9.component';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogsleftsidebarComponent } from './blogsleftsidebar/blogsleftsidebar.component';
import { BlogsrightsidebarComponent } from './blogsrightsidebar/blogsrightsidebar.component';
import { BlogsdetailsrightsidebarComponent } from './blogsdetailsrightsidebar/blogsdetailsrightsidebar.component';
import { BlogsdetailsleftsidebarComponent } from './blogsdetailsleftsidebar/blogsdetailsleftsidebar.component';
import { BlogsdetailsComponent } from './blogsdetails/blogsdetails.component';
import { TeamsComponent } from './teams/teams.component';
import { TeamSingleComponent } from './team-single/team-single.component';
import { WorksComponent } from './works/works.component';
import { WorkSingleComponent } from './work-single/work-single.component';
import { ContactusComponent } from './contactus/contactus.component';
import { Home10Component } from './home10/home10.component';
import { SwiperModule } from 'swiper/angular';
import { ComponentSharedModule } from '../sharedComponent/index.module';
import { DashboardComponent } from './works/dashboard/dashboard.component';
import { InsightsComponent } from './works/insights/insights.component';

@NgModule({
  declarations: [
    InsightsComponent,
    DashboardComponent, 
    Home10Component, 
    HomeComponent, 
    Home2Component, 
    Home3Component, 
    Home4Component, 
    Home5Component, 
    Home6Component, 
    Home7Component, 
    Home8Component, 
    Home9Component, 
    BlogsComponent, 
    BlogsleftsidebarComponent, 
    BlogsrightsidebarComponent, 
    BlogsdetailsrightsidebarComponent, 
    BlogsdetailsleftsidebarComponent, 
    BlogsdetailsComponent, 
    TeamsComponent, 
    TeamSingleComponent, 
    WorksComponent, 
    WorkSingleComponent, 
    ContactusComponent],
  imports: [
    CommonModule,
    PagesRoutingModule,
    SharedModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SwiperModule,
    HttpClientModule,
    ComponentSharedModule
  ]
})
export class PagesModule { }
