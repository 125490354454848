import { Component, OnInit, ViewContainerRef, Input } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MustMatch } from './mustMatch';

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupModalComponent implements OnInit {
  signupForm: UntypedFormGroup;
  loader: boolean = false;
  submitted: boolean = false;
  @Input() componentRef: ViewContainerRef;//Template Ref
  constructor(private modalService: NgbModal, private formBuilder: UntypedFormBuilder) { }

  /**
  * Get Form controls
  */
  get form() { return this.signupForm.controls; }

  ngOnInit(): void {
    //Initialize form
    this.signupForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmpwd: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmpwd'),
    });
  }

  /**
  * Open login form
  * @param content content
  */
  navigateLogin(content) {
    this.modalService.dismissAll();
    this.modalService.open(content, { centered: true, windowClass: 'auth-modal', backdrop: 'static' });
  }

  /**
    * Open All modals
    */
  closeModal() {
    this.modalService.dismissAll();
  }

  /**
   * @description Reset form
   * @param id form id
   */
  resetForm(id: string): void {
    this.loader = false;
    this.submitted = false;
    this.signupForm.reset();
    this.signupForm.markAsUntouched();
    this.signupForm.clearValidators();
    this.signupForm.clearAsyncValidators();
    const form = document.getElementById(id);
    const elems = form.querySelectorAll('.up');
    elems.forEach((item) => {
      item.classList.remove('up')
      item.classList.remove('invalid-field')
    })
  }

  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.signupForm.invalid) {
      return;
    }

    this.loader = true;
    const obj = {
      name: this.form.name.value,
      email: this.form.email.value,
      password: this.form.password.value,
      confirmPassword: this.form.confirmpwd.value
    }
    alert(JSON.stringify(obj));
    console.log("form values ===>", obj);
    this.resetForm('signup-form')
  }
}
