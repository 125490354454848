<!-- Begin Page -->
<div class="auth-pages bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-11">
        <div class="row justify-content-center">
          <div class="col-md-6 pe-md-0">
            <div class="auth-page-sidebar">
              <div class="bg-overlay opacity-6"></div>
              <div class="auth-user-testimonial">
								<owl-carousel-o [options]="customOptions">
									<ng-template carouselSlide>
										<div class="item">
											<h3 class="text-white mb-1">I love this theme!</h3>
											<h5 class="text-white mb-3">"I love it very much!"</h5>
											<p>- Admin User</p>
										</div>
									</ng-template>
									<ng-template carouselSlide>
										<div class="item">
											<h3 class="text-white mb-1">I love this theme!</h3>
											<h5 class="text-white mb-3">"I love it very much!"</h5>
											<p>- Admin User</p>
										</div>
									</ng-template>
									<ng-template carouselSlide>
										<div class="item">
											<h3 class="text-white mb-1">I love this theme!</h3>
											<h5 class="text-white mb-3">"I love it very much!"</h5>
											<p>- Admin User</p>
										</div>
									</ng-template>
								</owl-carousel-o>
              </div>
            </div>
          </div>
          <div class="col-md-6 ps-md-0">
            <div class="card mb-0 p-2 p-md-3">
              <div class="card-body">
                <div class="clearfix">
                  <a class="navbar-brand logo" routerLink="/">
                    <span class="dot"></span>Kiosk
                  </a>
                </div>
                <h5 class="mt-30 mb-1">Reset Password</h5>
                <p class="text-muted mb-15">Enter your email address and we'll send you an email with instructions to
                  reset your password.</p>
                <form name="resetForm" [formGroup]="resetForm" (ngSubmit)="onSubmit()"  novalidate>
                  <div class="form-group app-label"
                    [ngClass]="{'invalid-field': submitted && form.email.errors, 'is-valid': submitted && !form.email.errors}">
                    <input type="email" class="form-control" formControlName="email" id="forgotEmail" floatingLabel />
                    <label for="forgotEmail">Email Address</label>
                    <div *ngIf="submitted && form.email?.errors?.required" class="invalid-feedback">
                      <p>This field is required</p>
                    </div>
                    <div *ngIf="submitted && form.email?.errors?.email" class="invalid-feedback">
                      <p>Email must be a valid email address</p>
                    </div>
                  </div>
                  <div class="d-grid mt-30 mb-15">
                    <button class="btn btn-primary" type="submit">
                      <span *ngIf="loader"><i class="ri-loader-4-line spin h6 text-white"></i></span>
                      <span *ngIf="!loader">Submit</span>
                    </button>
                  </div>
                </form>
                <div class="text-center">
                  <p>Return to <a routerLink="/login" class="font-weight-bold link">Login</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>