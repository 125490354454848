  <!-- Begin Page -->
  <div class="auth-pages bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-6 col-md-8">
          <div class="card box-shadow">
            <div class="card-body p-4 text-center">
              <a class="navbar-brand logo justify-content-center" routerLink="/">
                <span class="dot"></span>Kiosk
              </a>
              <h5 class="mt-30 mb-15 font-weight-600">Confirm your email</h5>
              <p class="mb-15">Your account has been successfully registered. To
                complete the verification process, please check your email for a validation request.
              </p>
              <p>Return to <a routerLink="/login" class="link font-weight-bold">Login</a>
              </p>
            </div> <!-- end card-body -->
          </div>
          <!-- end card -->

        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </div>
  <!-- Page End -->