import { Component, OnInit } from '@angular/core';
import { testi } from 'src/app/data/testimonials.data';
import { Itestimonial } from 'src/app/interfaces/testimonials.interface';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {
  testimonialList: Itestimonial[] = testi;
  testimonialOptions: OwlOptions = {
    items: 3,
    loop: true,
    margin: 30,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 800,
    autoplayTimeout: 7000,
    autoplayHoverPause: true,
    responsive: {
      0: { items: 1 },
      767: { items: 2 },
      991: { items: 3 }
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
