import { Component, Input, OnInit } from '@angular/core';
import { NavlinkService } from 'src/app/shared/navlink.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';

@Component({
    selector: 'app-works',
    templateUrl: './works.component.html',
    styleUrls: ['../../../../node_modules/photoswipe/src/css/default-skin/default-skin.scss', '../../../../node_modules/photoswipe/src/css/main.scss', './works.component.scss'],
})

export class WorksComponent implements OnInit {
    
    LOGOUT_URL = 'https://qses043073453957.auth.us-west-2.amazoncognito.com' + '/login?client_id=' + environment.cognito.userPoolWebClientId + '&response_type=token&scope=openid+profile';
    navigations: Array<string> = ['Dashboard', 'Salir'];
    selectedNavigation: string;

    @Input() classes: string = '';
    public isCollapsed = true;
    currentSection$: Observable<string>;

    constructor(private navlinkService: NavlinkService, private router: Router) {
        this.currentSection$ = this.navlinkService.getCurrentSectionId();
        this.selectedNavigation = this.navigations[0]; // Asignar el primer valor por defecto
    }

    async ngOnInit(): Promise<void> {

        this.navlinkService.setCurrentSectionId('Dashboard')

    }


    windowScroll() {
        const navbar: HTMLElement | null | undefined = document.getElementById('navbar');
        if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
            navbar?.classList.add('sticky');
        } else {
            navbar?.classList.remove('sticky');
        }
    }

    toggle() {
        this.isCollapsed = !this.isCollapsed;
    }

    changeNavigation(navigation: string): void {
        try {

            if(navigation == 'Salir'){
                this.cerrar_sesion()
                return
            }

            console.log('Cambiando navegacion a ' + navigation)
            // Asignamos el valor de la navegación seleccionada a la variable selectedNavigation
            this.selectedNavigation = navigation;

            // Llamamos al método setCurrentSectionId de la clase navlinkService y le pasamos el valor de navigation
            this.navlinkService.setCurrentSectionId(navigation);
        } catch (error) {
            // En caso de error, imprimimos en consola el mensaje de error
            console.error(`Error en changeNavigation: ${error.message}`);
        }
    }

    cerrar_sesion(){
        try {

            let token = sessionStorage.getItem('AccessToken');

            fetch(this.LOGOUT_URL, {
                method: "GET"
            });

            console.log('Cerrando sesion y enviando al Index...')
            localStorage.clear();
            
            this.router.navigate(['/index-10']);
            
        } catch (error) {
            console.error(error);
        }
    }

}
