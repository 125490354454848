<app-pageheader [navigations]="navigations"></app-pageheader>
<!--Home Section-->
<app-page-banner title="Team Single"></app-page-banner>
<!--End Home Section-->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-md-4 mb-30">
        <div class="member-image">
          <img src="assets/images/ourteam-images/370-510_1.jpg" class="img-fluid border-2x" alt="Team Member">
        </div>
      </div>
      <div class="col-md-8 mb-30">
        <div class="member-intro">
          <h4 class="text-uppercase font-weight-bold">John Doe</h4>
          <span class="text-uppercase font-weight-bold mb-30 d-block">I AM A <em class="text-primary">Web
              Developer</em></span>
          <p class="mb-15">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s.</p>
          <p class="mb-30">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book.</p>
          <div class="row">
            <div class="col-md-6">
              <ul class="line-list list-unstyled">
                <li><strong>Name:</strong>James Ulrich</li>
                <li><strong>Date Of Birth:</strong>17/02/1985</li>
                <li><strong>Nationality:</strong>American</li>
                <li><strong>Gender:</strong>Male</li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul class="line-list list-unstyled">
                <li><strong>Freelancer:</strong>Available</li>
                <li><strong>Phone Number:</strong>123 4567 8910</li>
                <li><strong>Address:</strong>Street 50, South, North Str</li>
                <li><strong>E-mail Address:</strong>james@rosaleda.com</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="my-skills pt-15">
          <div class="row">
            <div class="col-md-6">
              <div class="skill-item pe-md-3">
                <i class="ri-device-line h2 mb-0"></i>
                <div class="progress-item">
                  <h5>Responsive Design</h5>
                  <ngb-progressbar [value]="90" [showValue]="true" height="6px"></ngb-progressbar>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="skill-item ps-md-3">
                <i class="ri-code-line h2 mb-0"></i>
                <div class="progress-item">
                  <h5>HTML5 &amp; CSS3</h5>
                  <ngb-progressbar [value]="95" [showValue]="true" height="6px"></ngb-progressbar>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="skill-item pe-md-3">
                <i class="ri-angularjs-line h2 mb-0"></i>
                <div class="progress-item">
                  <h5>Angular</h5>
                  <ngb-progressbar [value]="92" [showValue]="true" height="6px"></ngb-progressbar>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="skill-item ps-md-3">
                <i class="ri-npmjs-line h2 mb-0"></i>
                <div class="progress-item">
                  <h5>Node JS</h5>
                  <ngb-progressbar [value]="80" [showValue]="true" height="6px"></ngb-progressbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section bg-light">
  <div class="container">
    <div class="section-title text-center">
      <p class="sub">Completed</p>
      <h2>Projects</h2>
    </div>
    <div class="row">
      <div class="col-md-4 mb-30">
        <div class="work-grid-box-1">
          <a href="assets/images/blogs/02.jpg" class="grid-image">
            <img src="assets/images/blogs/02.jpg" alt="Project name">
          </a>
          <div class="grid-content">
            <h4>Dalmia</h4>
            <p>Web Design</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-30">
        <div class="work-grid-box-1">
          <a href="assets/images/blogs/03.jpg" class="grid-image">
            <img src="assets/images/blogs/03.jpg" alt="Project name">
          </a>
          <div class="grid-content">
            <h4>LAMP</h4>
            <p>Branding</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-30">
        <div class="work-grid-box-1">
          <a href="assets/images/blogs/04.jpg" class="grid-image">
            <img src="assets/images/blogs/04.jpg" alt="Project name">
          </a>
          <div class="grid-content">
            <h4>Hunger</h4>
            <p>Graphic Design</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>