import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { IUser, CognitoService } from '../../cognito.service';
import { Amplify, Auth } from 'aws-amplify';
import { environment } from '../../../environments/environment.prod';
import { CognitoUser } from 'amazon-cognito-identity-js';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

    sesion_usuario: CognitoUser
    navigations: Array<string> = ['login', 'home', 'productos', 'clientes', 'contactanos'];
    error_on_login = false;
    loginForm: UntypedFormGroup;
    restablecer_password_form: UntypedFormGroup;
    restablecer_password_codigo_form: UntypedFormGroup;
    submitted: boolean = false;
    loader: boolean = false;
    formularios: Array<string> = ['login', 'restablecer_password', 'codigo_reseteo'];
    formulario_seleccionado: string;
    user: IUser;
    customOptions: OwlOptions = {
        loop: true,
        margin: 0,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 4000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    }
    constructor(private formBuilder: UntypedFormBuilder, private router: Router,
        private cognitoService: CognitoService, private renderer: Renderer2) {

        Amplify.configure({
            Auth: environment.cognito,
        });
        this.user = {} as IUser;
    }

    get form() { return this.loginForm.controls; }
    get form_recuperar_password() { return this.restablecer_password_form.controls; }
    get form_recuperar_password_codigo() { return this.restablecer_password_codigo_form.controls; }


    ngOnInit(): void {
        this.formulario_seleccionado = this.formularios[0]; // formulario login por defecto
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', [Validators.required]],
        });
        this.restablecer_password_codigo_form = this.formBuilder.group({
            codigo: ['', [Validators.required]],
            new_password_codigo: ['', [
                Validators.required,
                Validators.minLength(8),
                this.hasLowercaseValidator,
                this.hasUppercaseValidator,
                this.hasNumberValidator,
                this.hasSpecialCharacterValidator
            ]],
            password_repeat_codigo: ['', [Validators.required, this.verificarPasswordIguales]],
        });
        this.restablecer_password_codigo_form.get("new_password_codigo").valueChanges.subscribe(() => {
            this.restablecer_password_codigo_form.get("password_repeat_codigo").updateValueAndValidity();
        });

        this.loginForm.get("password").valueChanges.subscribe(() => {
            this.error_on_login = false
        });

        this.restablecer_password_form = this.formBuilder.group({
            new_password: ['', [
                Validators.required,
                Validators.minLength(8),
                this.hasLowercaseValidator,
                this.hasUppercaseValidator,
                this.hasNumberValidator,
                this.hasSpecialCharacterValidator
            ]],
            password_repeat: ['', [Validators.required, this.verificarPasswordIguales]],
        });
        this.restablecer_password_form.get("new_password").valueChanges.subscribe(() => {
            this.restablecer_password_form.get("password_repeat").updateValueAndValidity();
        });

    }
    
    ngAfterViewInit(): void {
        console.log(1)
        // this.renderer.selectRootElement('#loginEmail').nativeElement.focus()
        // this.loginForm.get('email')this.emailInput.nativeElement.focus();
      }


    hasLowercaseValidator(control: FormControl) {

        if (control.value == "") {
            return null

        }

        const hasLowercase = /[a-z]/.test(control.value);
        return hasLowercase
            ? null
            : {
                hasLowercase: {
                    value: control.value
                }
            };
    }

    hasUppercaseValidator(control: FormControl) {

        if (control.value == "") {
            return null

        }

        const hasLowercase = /[A-Z]/.test(control.value);
        return hasLowercase
            ? null
            : {
                hasUppercase: {
                    value: control.value
                }
            };
    }

    hasNumberValidator(control: FormControl) {

        if (control.value == "") {
            return null

        }

        const hasNumber = /\d/.test(control.value);
        return hasNumber
            ? null
            : {
                hasNumber: {
                    value: control.value
                }
            };
    }

    hasSpecialCharacterValidator(control: FormControl) {

        if (control.value == "") {
            return null

        }

        const hasSpecialCharacter = /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/.test(control.value);
        return hasSpecialCharacter
            ? null
            : {
                hasSpecialCharacter: {
                    value: control.value
                }
            };
    }

    verificarPasswordIguales(control: FormControl) {


        if (control.value == "") {
            return null

        }

        if (!control.parent?.controls) {
            return null
        }

        if (!control.parent?.controls) {
            return null
        }

        const new_password = control.parent?.controls["new_password"]?.value || control.parent?.controls["new_password_codigo"]?.value
        const password_repeat = control.parent?.controls["password_repeat"]?.value || control.parent?.controls["password_repeat_codigo"]?.value

        const is_equal = new_password == password_repeat
        return is_equal
            ? null
            : {
                is_equal: {
                    value: control.value
                }
            };

    }

    async onSubmit(): Promise<void> {
        try {

            this.loader = true;
            this.user.email = this.form.email.value
            this.user.password = this.form.password.value

            let resultado_login = await this.cognitoService.signIn(this.user)
            this.sesion_usuario = resultado_login

            if (resultado_login.challengeName == 'NEW_PASSWORD_REQUIRED') {
                this.loader = false;
                this.formulario_seleccionado = this.formularios[1]; // formulario restablecer password
                return
            }
            let session = await resultado_login.getSession((err: any, session: any) => session)
            let AccessToken = session.getIdToken().getJwtToken();
            sessionStorage.setItem('AccessToken', AccessToken)
            this.router.navigate(['works']);

        } catch (error) {
            this.loader = false;

            if (error.name == 'PasswordResetRequiredException') {
                this.formulario_seleccionado = this.formularios[2];
                return
            }

            if(error.name = 'UserNotFoundException'){
                this.loginForm.get('password').reset();
                this.loginForm.get('password').setErrors({'incorrect': true});
                this.loginForm.get('email').setErrors({'incorrect': true});
                this.user.email = this.form.email.value
                this.user.password = this.form.password.value
                this.error_on_login = true
                return

            }

            console.log(error)

        }
    }

    async confirmar_nuevo_password(): Promise<void> {
        console.log("confirmar_nuevo_password...")
        Amplify.configure({
            Auth: environment.cognito,
        });
        this.loader = true;
        await Auth.completeNewPassword(this.sesion_usuario, this.restablecer_password_form.get('new_password').value)
        this.loader = false;
        this.router.navigate(['login'])

    }

    async confirmar_nuevo_password_codigo(): Promise<void> {
        try {
            console.log("confirmar_nuevo_password_codigo...")
            Amplify.configure({
                Auth: environment.cognito,
            });
            this.loader = true;
            await Auth.forgotPasswordSubmit(this.user.email, this.restablecer_password_codigo_form.get('codigo').value, this.restablecer_password_codigo_form.get('new_password_codigo').value)
            this.loader = false;
            window.location.reload()

        } catch (error) {

            console.log(error)
            this.loader = false;
            alert("codigo incorrecto")
        }

    }


    async solicitar_nuevo_password(): Promise<void> {
        try {

            this.user.email = this.loginForm.get('email').value
            console.log("solicitar_nuevo_password...")
            Amplify.configure({
                Auth: environment.cognito,
            });
            await Auth.forgotPassword(this.loginForm.get('email').value)
            alert("se a enviado un codigo a su correo")
            this.formulario_seleccionado = this.formularios[2];

        } catch (error) {

            console.log(error)
        }

    }

}
