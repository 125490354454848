import { Component, Input, OnInit } from '@angular/core';
import { awesomeFeatures } from 'src/app/data/features.data';
import { IFeature } from 'src/app/interfaces/features.interface';
import { IShape } from 'src/app/interfaces/pricings.interface';

@Component({
  selector: 'app-powerfulfeatures',
  templateUrl: './powerfulfeatures.component.html',
  styleUrls: ['./powerfulfeatures.component.scss']
})
export class PowerfulFeaturesComponent implements OnInit {

  @Input() className: string;
  @Input() shapeObj?: IShape;
  awesomeFeaturesList: IFeature[] = awesomeFeatures;
  constructor() { }

  ngOnInit(): void {
  }

}
