<app-header [navigations]="navigations" [classes]="'navbar-dark'"></app-header>
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">

  <!--Header-->
  <section class="home home-5 bg-soft-warning" id="home" data-scroll-index="home">
    <div class="container">
      <div class="row align-items-center justify-content-center justify-content-lg-start">
        <div class="col-lg-6 pe-xl-5 mb-100 mb-lg-0 text-center text-lg-start">
          <h1 class="my-30" data-aos="fade-down" data-aos-duration="400" data-aos-delay="400">We bring solutions to make life easier for our customers.</h1>
          <p class="font-18" data-aos="fade-down" data-aos-duration="500" data-aos-delay="600">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
            laudantium, totam rem aperiam eaque. error sit voluptatem accusantium doloremque laudantium, totam rem
            aperiam eaque</p>
          <div class="mt-30" data-aos="fade" data-aos-offset="0" data-aos-duration="600" data-aos-delay="800">
            <a class="btn btn-outline-dark text-start mt-1 me-sm-2 mb-3 mb-sm-0" href="javascript:void(0)">
              <div class="media align-items-center">
                <i class="ri-apple-fill fs-4 me-2 my-0"></i>
                <div class="media-body">
                  <strong class="d-block">Download App</strong> <span class="text-capitalize">from App Store</span>
                </div>
              </div>
            </a>
            <a class="btn btn-outline-dark text-start mt-1" href="javascript:void(0)">
              <div class="media align-items-center">
                <i class="ri-android-fill fs-4 me-2 my-0"></i>
                <div class="media-body">
                  <strong class="d-block">Download App</strong> <span class="text-capitalize">from play Store</span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <!-- end col -->
        <div class="col-sm-8 col-lg-6 col-xl-5 offset-xl-1 text-over-bg" data-aos="fade" data-aos-duration="800" data-aos-delay="800">
          <div class="dot-shape opacity-6"></div>
          <div class="img-bg-shape rounded bg-soft-warning"></div>
          <img src="assets/images/home/hero-5.jpg" class="img-fluid border-radius-2x"
            alt="" />
        </div>
        <!-- end col -->

      </div> <!-- end row -->
    </div> <!-- end container -->
  </section>

  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--End Header-->
  
  <!--Features Section-->
  <section class="pt-100" id="features" data-scroll-index="features">
    <div class="container">
      <div class="section-title text-center" data-aos="fade" data-aos-duration="400" data-aos-delay="400">
        <p class="sub">Our App Feature Lists</p>
        <h2 class="mt-0">Great Features</h2>
      </div>
      <div class="row">
        <div class="col-sm-6 col-lg-3 mb-30" *ngFor="let item of featuresList|slice:1:5;let i=index" data-aos="fade-right" [attr.data-aos-duration]="(i*100)+400" [attr.data-aos-delay]="(i*100)+400">
          <div class="features-box-2 text-center">
            <div class="avatar-lg mx-auto mb-30">
              <div class="avatar-title font-24 shadow-icon rounded-circle">
                <i class="{{ item?.icon }} bg-primary text-white"></i>
              </div>
            </div>
            <h5 class="mb-15">{{ item?.title }}</h5>
            <p class="w-100">{{ item?.desc }}</p>
            <div class="mt-15">
              <a routerLink="/index-5" class="link">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Features Section-->
  <!--Features Section-->
  <section class="section more-features" id="features" data-scroll-index="features">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 mb-30" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="mobile-mock text-center text-lg-start">
            <img src="assets/images/mobile-mock.png" class="img-fluid" alt="mobile mock">
          </div>
        </div>
        <div class="col-lg-6 mb-30 ps-lg-5" data-aos="fade-left" data-aos-duration="400" data-aos-delay="500">
          <div class="section-title mb-40">
            <p class="sub">Our App Feature Lists</p>
            <h2 class="mt-0">Powerful App for <br>Your Mobile</h2>
          </div>
          <div class="sub-feature mb-30">
            <div class="media flex-grow-1 mb-3 mb-sm-0">
              <div class="remix-icon">
                <i class="ri-device-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Responsive <br class="d-none d-lg-inline-block">Design</h5>
              </div>
            </div>
            <div class="media flex-grow-1">
              <div class="remix-icon">
                <i class="ri-global-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Online <br class="d-none d-lg-inline-block">Marketing</h5>
              </div>
            </div>
          </div>
          <p class="mb-15">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla
            pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <p class="mb-30">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla
            pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <a href="javascript:void(0)" class="btn btn-outline-dark">Discover More</a>
        </div>
      </div>

    </div>
  </section>
  <!--End Features Section-->

  <!--Features Section-->
  <section class="section more-features bg-light" data-scroll-index="features">
    <div class="shape-bg-top-left" style="background-image: url(assets/images/shape-3.png)"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 mb-30 pe-lg-5" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
          <div class="section-title mb-40">
            <p class="sub">Our App Feature Lists</p>
            <h2 class="mt-0">User Friendly App <br /> and Interface</h2>
          </div>
          <div class="sub-feature mb-30">
            <div class="media flex-grow-1 mb-3 mb-sm-0">
              <div class="remix-icon">
                <i class="ri-file-shield-2-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Data <br class="d-none d-lg-inline-block">Privacy</h5>
              </div>
            </div>
            <div class="media flex-grow-1">
              <div class="remix-icon">
                <i class="ri-shield-check-line"></i>
              </div>
              <div class="media-body align-self-center ms-15">
                <h5>Analytics <br class="d-none d-lg-inline-block">Security</h5>
              </div>
            </div>
          </div>
          <p class="mb-15">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eufugiat nulla
            pariatur. Lorem Ipsum is simply dummy text of the printing laborum perspiciatis unde.</p>
          <ul class="list-unstyled highlight-list mb-30">
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
            <li>Duis aute irure dolor in reprehenderit in voluptate</li>
          </ul>
          <a href="javascript:void(0)" class="btn btn-outline-dark">Discover More</a>
        </div>
        <div class="col-lg-6 mb-30" data-aos="fade-left" data-aos-duration="400" data-aos-delay="500">
          <div class="mobile-mock text-center text-lg-end">
            <img src="assets/images/mobile-mock-3.png" class="img-fluid" alt="mobile mock">
          </div>
        </div>
      </div>

    </div>
  </section>
  <!--EndFeatures Section-->

  <!--Extra Feature Section-->
  <app-powerfulfeatures className="section"></app-powerfulfeatures>
  <!--End Extra Feature Section-->

  <!--Video Section-->
  <section class="video-one section pt-0" data-scroll-index="features" data-aos="fade" data-aos-duration="400" data-aos-delay="400">
    <div class="container">
      <div class="video-bg position-relative bg-1">
        <div class="bg-overlay bg-overlay-gradient-reverse opacity-8"></div>
        <div class="video-content text-over-bg">
          <a href="https://www.youtube.com/watch?v=Kl5B6MBAntI" class="video-btn-circle circle-anim mb-50">
            <i class="ri-play-line font-48"></i>
          </a>
          <div class="video-title">
            <h2 class="h1 text-white">Watch Our Video</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Video Section-->

  <!--Pricing Section-->
  <app-pricing [className]="'pb-70 position-relative'" [shapeObj]="shapeImg"></app-pricing>
  <!--End Pricing Section-->

  <!--Client Section-->
  <section class="section bg-light" id="client" data-scroll-index="client" data-aos="fade" data-aos-duration="400" data-aos-delay="400">
    <div class="container">
      <div class="section-title text-center">
        <p class="sub">Testimonials</p>
        <h2 class="title">What Our Client Says</h2>
      </div> <!-- end title -->
      <!-- end row -->
      <owl-carousel-o [options]="testiOptions" class="pb-30 testi-carousel">
        <ng-template carouselSlide>
          <div class="item mb-30">
            <div class="bg-soft-primary rounded p-4">
              <p>" I have been using this template for all my company needs for the last 3 years and
                couldn’t be happier with their service and expertise. They’ve surpassed all of my expectations and
                customer service! "</p>
            </div>
            <div class="media pt-30 align-items-center">
              <div class="avatar-lg me-3">
                <img src="assets/images/user-1.jpg" alt="testi-user" class="rounded-circle img-fluid d-block me-3">
              </div>
              <div class="media-body">
                <h6 class="mb-0">Mark Wainright</h6>
                <p class="font-14">CEO & Founder of Another Great Company</p>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="item mb-30">
            <div class="bg-soft-primary rounded p-4">
              <p>" I have been using this template for all my company needs for the last 3 years and
                couldn’t be happier with their service and expertise. They’ve surpassed all of my expectations and
                customer service! "</p>
            </div>
            <div class="media pt-30 align-items-center">
              <div class="avatar-lg me-3">
                <div class="avatar-title bg-soft-primary text-primary rounded-circle">
                  K
                </div>
              </div>
              <div class="media-body">
                <h6 class="mb-0">Kenneth Bailey</h6>
                <p class="font-14">- KIOSK user</p>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="item mb-30">
            <div class="bg-soft-primary rounded p-4">
              <p>" I have been using this template for all my company needs for the last 3 years and
                couldn’t be happier with their service and expertise. They’ve surpassed all of my expectations and
                customer service! "</p>
            </div>
            <div class="media pt-30 align-items-center">
              <div class="avatar-lg me-3">
                <img src="assets/images/user-1.jpg" alt="testi-user" class="rounded-circle img-fluid    d-block me-3">
              </div>
              <div class="media-body">
                <h6 class="mb-0">Mark Wainright</h6>
                <p class="font-14">CEO & Founder of Another Great Company</p>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="item mb-30">
            <div class="bg-soft-primary rounded p-4">
              <p>" I have been using this template for all my company needs for the last 3 years and
                couldn’t be happier with their service and expertise. They’ve surpassed all of my expectations and
                customer service! "</p>
            </div>
            <div class="media pt-30 align-items-center">
              <div class="avatar-lg me-3">
                <img src="assets/images/user-1.jpg" alt="testi-user" class="rounded-circle img-fluid d-block me-3">
              </div>
              <div class="media-body">
                <h6 class="mb-0">Mark Wainright</h6>
                <p class="font-14">CEO & Founder of Another Great Company</p>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="item mb-30">
            <div class="bg-soft-primary rounded p-4">
              <p>" I have been using this template for all my company needs for the last 3 years and
                couldn’t be happier with their service and expertise. They’ve surpassed all of my expectations and
                customer service! "</p>
            </div>
            <div class="media pt-30 align-items-center">
              <div class="avatar-lg me-3">
                <div class="avatar-title bg-soft-primary text-primary rounded-circle">
                  K
                </div>
              </div>
              <div class="media-body">
                <h6 class="mb-0">Kenneth Bailey</h6>
                <p class="font-14">- KIOSK user</p>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
    <!-- end container -->
  </section>
  <!--End Client Section-->

  <!--Contact us Section-->
  <section class="section" id="contact us" data-scroll-index="contact us">
    <div class="container">
      <div class="contact-box p-0 shadow-none">
        <div class="row align-items-center">
          <div class="col-lg-6 col-xl-5 pe-lg-5 mb-30 mb-lg-0" data-aos="fade-right" data-aos-duration="400" data-aos-delay="400">
            <div class="contact-info-card bg-overlay-gradient border-radius-2x">
              <div class="section-title">
                <span class="sub-text text-white">Let's Talk</span>
                <h2 class="text-white">Contact Our Experts</h2>
              </div>
              <div class="address-card mb-30">
                <div class="icon">
                  <i class="ri-message-line fs-4"></i>
                </div>
                <div class="text">
                  <span class="label">Email:</span>
                  <a href="mailto:example@exampledesign.com" class="text-white">
                    example@exampledesign.com
                  </a>
                </div>
              </div>
              <div class="address-card mb-30">
                <div class="icon">
                  <i class="ri-phone-line fs-4"></i>
                </div>
                <div class="text">
                  <span class="label">Phone:</span>
                  <span> (+088)589-8745 </span>
                </div>
              </div>
              <div class="address-card mb-30">
                <div class="icon">
                  <i class="ri-map-pin-line fs-4"></i>
                </div>
                <div class="text">
                  <span class="label">Address:</span>
                  <span> 22 New Street, Delhi,<br>India, 110041</span>
                </div>
              </div>
              <hr class="my-30 text-white">
              <div class="social-icons">
                <a href="javascript:void(0)"><i class="ri-facebook-line"></i></a>
                <a href="javascript:void(0)"><i class="ri-twitter-line"></i></a>
                <a href="javascript:void(0)"><i class="ri-linkedin-line"></i></a>
                <a href="javascript:void(0)"><i class="ri-dribbble-line"></i></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-7" data-aos="fade-left" data-aos-duration="400" data-aos-delay="500">
            <div class="contact-form p-0 shadow-none">
              <div class="section-title">
                <p class="sub">Get In Touch</p>
                <h2>Fill the Form Below</h2>
              </div>
              <ngb-alert *ngIf="showSuccessMsg" [type]="alertType" [innerHTML]="successMsg"></ngb-alert>

              <form name="contactForm" [formGroup]="contactForm" class="rounded" (ngSubmit)="onSubmit('contactForm');" id="contactForm" novalidate>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group app-label mb-30"
                      [ngClass]="{'invalid-field': submitted && form.name.errors, 'is-valid': submitted && !form.name.errors}">
                      <input type="text" class="form-control bg-light border-0" formControlName="name" id="name" floatingLabel />
                      <label for="name">Name</label>
                      <div *ngIf="submitted && form.name?.errors" class="invalid-feedback">
                        <p>This field is required</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group app-label mb-30"
                      [ngClass]="{'invalid-field': submitted && form.email.errors, 'is-valid': submitted && !form.email.errors}">
                      <input type="email" class="form-control bg-light border-0" formControlName="email" id="signupEmail" floatingLabel />
                      <label for="signupEmail">Email Address</label>
                      <div *ngIf="submitted && form.email?.errors?.required" class="invalid-feedback">
                        <p>This field is required</p>
                      </div>
                      <div *ngIf="submitted && form.email?.errors?.email" class="invalid-feedback">
                        <p>Email must be a valid email address</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group app-label mb-30"
                      [ngClass]="{'invalid-field': submitted && form.phone.errors, 'is-valid': submitted && !form.phone.errors}">
                      <input type="text" class="form-control bg-light border-0" formControlName="phone" id="phone" floatingLabel />
                      <label for="phone">Phone</label>
                      <div *ngIf="submitted && form.phone?.errors?.required" class="invalid-feedback">
                        <p>This field is required</p>
                      </div>
                      <div *ngIf="submitted && form.phone?.errors?.pattern" class="invalid-feedback">
                        <p>Please enter number value</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group app-label mb-30"
                      [ngClass]="{'invalid-field': submitted && form.website.errors, 'is-valid': submitted && !form.website.errors}">
                      <input type="text" class="form-control bg-light border-0" formControlName="website" id="website" floatingLabel />
                      <label for="website">Website</label>
                      <div *ngIf="submitted && form.website?.errors?.required" class="invalid-feedback">
                        <p>This field is required</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">

                    <div class="form-group app-label mb-30"
                      [ngClass]="{'invalid-field': submitted && form.message.errors, 'is-valid': submitted && !form.message.errors}">
                      <textarea rows="3" class="form-control bg-light border-0" formControlName="message" id="message"
                        floatingLabel></textarea>
                      <label for="message">Message</label>
                      <div *ngIf="submitted && form.message?.errors" class="invalid-feedback">
                        <p>This field is required</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group text-center">
                  <button class="btn btn-primary" type="submit">
                    <span *ngIf="loader"><i class="ri-loader-4-line spin h6 text-white"></i></span>
                    <span *ngIf="!loader">Submit Now</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section><!--end Contact us Section-->
</div>
<app-footer></app-footer>