import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogsdetailsComponent } from './blogsdetails/blogsdetails.component';
import { BlogsdetailsleftsidebarComponent } from './blogsdetailsleftsidebar/blogsdetailsleftsidebar.component';
import { BlogsdetailsrightsidebarComponent } from './blogsdetailsrightsidebar/blogsdetailsrightsidebar.component';
import { BlogsleftsidebarComponent } from './blogsleftsidebar/blogsleftsidebar.component';
import { BlogsrightsidebarComponent } from './blogsrightsidebar/blogsrightsidebar.component';
import { ContactusComponent } from './contactus/contactus.component';
import { HomeComponent } from './home/home.component';
import { Home10Component } from './home10/home10.component';
import { Home2Component } from './home2/home2.component';
import { Home3Component } from './home3/home3.component';
import { Home4Component } from './home4/home4.component';
import { Home5Component } from './home5/home5.component';
import { Home6Component } from './home6/home6.component';
import { Home7Component } from './home7/home7.component';
import { Home8Component } from './home8/home8.component';
import { Home9Component } from './home9/home9.component';
import { TeamSingleComponent } from './team-single/team-single.component';
import { TeamsComponent } from './teams/teams.component';
import { WorkSingleComponent } from './work-single/work-single.component';
import { WorksComponent } from './works/works.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/index-10',
        pathMatch: 'full'
    },
    {
        path: 'index',
        component: HomeComponent
    },
    {
        path: 'index-2',
        component: Home2Component
    },
    {
        path: 'index-3',
        component: Home3Component
    },
    {
        path: 'index-4',
        component: Home4Component
    },
    {
        path: 'index-5',
        component: Home5Component
    },
    {
        path: 'index-6',
        component: Home6Component
    },
    {
        path: 'index-7',
        component: Home7Component
    },
    {
        path: 'index-8',
        component: Home8Component
    },
    {
        path: 'index-9',
        component: Home9Component
    },
    {
        path: 'index-10',
        component: Home10Component
    },
    {
        path: 'blogs',
        component: BlogsComponent
    },
    {
        path: 'blogs-leftsidebar',
        component: BlogsleftsidebarComponent
    },
    {
        path: 'blogs-rightsidebar',
        component: BlogsrightsidebarComponent
    },
    {
        path: 'blog-detail',
        component: BlogsdetailsComponent
    },
    {
        path: 'blog-detail-leftsidebar',
        component: BlogsdetailsleftsidebarComponent
    },
    {
        path: 'blog-detail-rightsidebar',
        component: BlogsdetailsrightsidebarComponent
    },
    {
        path: 'team',
        component: TeamsComponent
    },
    {
        path: 'team-single',
        component: TeamSingleComponent
    },
    {
        path: 'works',
        component: WorksComponent
    },
    {
        path: 'work-single',
        component: WorkSingleComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule {}
